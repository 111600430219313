import React, { ReactNode } from 'react';
import { useField, useFormikContext } from 'formik';

import { RoomPlanMode } from '../../../../lib/core/api/generated';

import { StyledPlanTypeCard, Icon, Content, Title, Description } from './PlanTypeCard.styled';

export interface IPlanTypeCard {
  id: RoomPlanMode;
  title: string;
  description: string;
  icon: ReactNode;
}

export interface IPlanTypeCardProps {
  card: IPlanTypeCard;
  autoSubmit?: boolean;
}

export const PlanTypeCard: React.FC<IPlanTypeCardProps> = props => {
  const { card, autoSubmit } = props;
  const [, , fieldHelpers] = useField('planMode');
  const formikContext = useFormikContext();

  const handleOnClick = React.useCallback(() => {
    fieldHelpers.setValue(card.id);
    fieldHelpers.setTouched(true);
    if (autoSubmit) {
      formikContext.submitForm();
    }
  }, [autoSubmit, card.id, fieldHelpers, formikContext]);

  return (
    <StyledPlanTypeCard onClick={handleOnClick} data-hook-button={card.title}>
      <Icon>{card.icon}</Icon>
      <Content>
        <Title dangerouslySetInnerHTML={{ __html: card.title }} />
        <Description>{card.description}</Description>
      </Content>
    </StyledPlanTypeCard>
  );
};

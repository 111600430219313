import React from 'react';
import * as Yup from 'yup';

import { useUpdateAccount, useWatchCurrentAccount } from '../../../../lib/core/repositories/user-repository';

import { MutationUpdateAccountArgs } from '../../../../lib/core/api/generated';
import { On } from '../../../../lib/core/hooks/on';
import { CError } from '../../../../lib/components/Error';

import {
  Form,
  FormInput,
  FormLayout,
  FormFooter,
  FormButton,
  PageSection,
  FormFieldRow,
  Modal,
  Button,
} from '../../../../lib/components';

import { AccountImage, isFile } from './AccountImage';

import { ModalContent, Title } from './SettingsPage.styled';
import { requiredString } from '../../../../lib/utils/yup-reductions';
import { Loader } from '../../../../lib/components/Loader';
import { useSnackBar } from '../../../../lib/components/SnackBar';

export const SettingsPage: React.FC = () => {
  const useWatchCurrentAccountResult = useWatchCurrentAccount();
  const [currentAccount] = useWatchCurrentAccountResult;
  const [updateAccount] = useUpdateAccount();
  const { showSuccess } = useSnackBar();

  const onSubmitAccount = React.useCallback(
    (values: MutationUpdateAccountArgs) => {
      return updateAccount({
        id: currentAccount ? currentAccount.id : '',
        name: values.name,
        companyImage: isFile(values.companyImage) || values.companyImage === null ? values.companyImage : undefined,
      }).then(() => showSuccess('Die Änderungen wurden gespeichert'));
    },
    [currentAccount, updateAccount],
  );

  return On(
    account => (
      <PageSection>
        <Title>Einstellungen</Title>
        <Form
          onSubmit={onSubmitAccount}
          initialValues={{
            name: account.name || '',
            companyImage: account.companyImageUrl,
          }}
          validationSchema={validationSchema}
        >
          <FormLayout>
            <AccountImage name="companyImage" defaultImage="/img/accountPage/default-upload-img.svg" />
            <FormFieldRow>
              <FormInput type="text" name="name" label="Account Name" css={'width: 50%'} />
            </FormFieldRow>
            <FormFooter>
              <FormButton variant="contained" color="bronze" type="submit">
                Speichern
              </FormButton>
            </FormFooter>
          </FormLayout>
        </Form>
      </PageSection>
    ),
    () => <Loader />,
    errorState => <CError error={errorState} />,
    useWatchCurrentAccountResult,
  );
};

const validationSchema = Yup.object().shape<Partial<MutationUpdateAccountArgs>>({
  name: requiredString('Bitte benennen Sie Ihr Konto'),
});

import React from 'react';
import { observer } from 'mobx-react-lite';
import { Skeleton } from '../../Skeleton';

interface IGeneralCardSkeletonProps {
  isShowed?: boolean;
}

export const GeneralCardSkeleton: React.FC<IGeneralCardSkeletonProps> = observer(({ isShowed }) => {
  return (
    <Skeleton isShowed={isShowed} width={255} height={355}>
      <rect x="0" y="0" width="150" height="35" />
      <rect x="0" y="80" width="255" height="255" />
    </Skeleton>
  );
});

// TODO refactor this component
// Use FormRadioGroup

import React, { ChangeEvent } from 'react';
import { useField, useFormikContext } from 'formik';

import { Fab } from '../../../../../lib/components/Fab';
import { Radio } from '../../../../../lib/components/Radio';

// TODO get rid of these dependencies
import { CheckboxWrapper, ParameterColumn, ParameterTitle } from '../RoomParamsPage/RoomParamsPage.styled';

export interface IRadioGroupItem {
  value: string;
  label: string;
}

interface IRadioGroupFieldProps {
  title: string;
  name: string;
  items: IRadioGroupItem[];
  disabled?: boolean;
  hasFab?: boolean;
  smartTipContent?: React.ReactNode;
  onInfoButtonClick?: (refEl: React.RefObject<any>, smartTipContent: React.ReactNode) => void;
}

export const RadioGroupField: React.FC<IRadioGroupFieldProps> = ({
  disabled = false,
  title,
  hasFab,
  items,
  name,
  smartTipContent,
  onInfoButtonClick,
}) => {
  const [field] = useField<string>(name);
  const { setFieldValue } = useFormikContext();
  const ref = React.useRef<HTMLButtonElement | null>(null);

  const handleChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(name, event.target.value);
    },
    [name, setFieldValue],
  );

  const handleClickInfo = () => {
    onInfoButtonClick && onInfoButtonClick(ref, smartTipContent);
  };

  return (
    <ParameterColumn isDisabled={disabled}>
      <ParameterTitle>
        {title} {smartTipContent && <Fab variant="info" onClick={handleClickInfo} refEl={ref} />}
      </ParameterTitle>
      {items.map(({ value, label }) => (
        <CheckboxWrapper key={value}>
          <Radio name={name} value={value} checked={value === field.value} onChange={handleChange}>
            {label}
          </Radio>
        </CheckboxWrapper>
      ))}
    </ParameterColumn>
  );
};

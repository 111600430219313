import React from 'react';
import { useField } from 'formik';

import { Input } from '../../Input';

interface IFormInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  label?: string;
  autoFocusOnEmpty?: boolean;
}

export const FormInput: React.FC<IFormInputProps> = props => {
  const [formikProps, meta] = useField(props.name);
  const joinedProps = { ...props, ...formikProps };
  const isError = Boolean(meta.error) && meta.touched;
  const { autoFocusOnEmpty, value } = props;

  const inputRef = React.useRef<HTMLInputElement | null>(null);
  React.useEffect(() => {
    if (autoFocusOnEmpty && inputRef.current && !value) {
      inputRef.current.focus();
    }
  }, [autoFocusOnEmpty, value]);

  return <Input {...joinedProps} error={isError ? meta.error : ''} ref={inputRef} />;
};

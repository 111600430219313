import React from 'react';
import { observer } from 'mobx-react';

import { device } from '../../../../lib/misc/device';
import { HomeMobileLazy } from './HomeMobileLazy';
import { HomeDesktopLazy } from './HomeDesktopLazy';

export const HomePage = observer(() => {
  const { isTabletSmall } = device;
  return isTabletSmall ? <HomeMobileLazy /> : <HomeDesktopLazy />;
});

import React from 'react';
import config from 'config';

import { leadingZeros } from '../../../../lib/utils/helpers';
import { BlockTitle } from '../../../../lib/components/Block/BlockTitle';
import { Block } from '../../../../lib/components/Block/Block';

import { Slider } from '../../../../lib/components';

const { staticServer } = config;

import { Content, ContentInner, Number, Info, InfoTitle, InfoDescription, Image } from './ForMembersOnlySlider.styled';

export interface IMember {
  urn: string;
  title: string;
  cover: string;
  description: string;
  url: string;
}

export const ForMembersOnlySlider: React.FC = () => {
  const swiperMember: React.RefObject<any> = React.createRef();

  const renderSlideMember = (member: IMember, index: number) => (
    <>
      <Content>
        <ContentInner>
          <Number>{slideNumber(index + 1)}</Number>
          <Info>
            <InfoTitle>{member.title}</InfoTitle>
            <InfoDescription>{member.description}</InfoDescription>
          </Info>
        </ContentInner>
      </Content>
      <Image src={`${member.cover}?size=1160x590`} alt={member.title} />
    </>
  );

  if (!members.length) {
    return null;
  }

  return (
    <Block>
      <BlockTitle>Design nur für Mitglieder</BlockTitle>
      <Slider
        ref={swiperMember}
        loop={true}
        autoplay={{
          delay: 10000,
        }}
        slidesPerView={1}
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
        }}
        css={`
          height: 400px;
        `}
      >
        {members.map(renderSlideMember)}
      </Slider>
    </Block>
  );
};

const slideNumber = leadingZeros(2);

export const members: IMember[] = [
  {
    urn: '01',
    cover: `${staticServer}/cms/site/assets/files/2306/member-slide_1.jpg`,
    title: 'Warum Mitglied werden?',
    description: `Unsere Mitglieder bekommen exklusiven Zugang zu ausgewählten Einrichtungsmarken der Extraklasse – zu dennoch erschwinglichen Preisen. Unsere erfahrenen DesignerInnen der nod studios GmbH prüfen jeden Stil vor Aufnahme ins Sortiment – das wichtigste Kriterium: no ordinary designs.`,
    url: '',
  },
  {
    urn: '02',
    cover: `${staticServer}/cms/site/assets/files/2306/member-slide_2.jpg`,
    title: 'Zeit sparen.',
    description: `Furnished übernimmt alle logistischen Vorgänge, liefert Ihre Büroeinrichtung, baut auf und montiert – als Einmal-Lieferung.`,
    url: '',
  },
  {
    urn: '03',
    cover: `${staticServer}/cms/site/assets/files/2306/member-slide_3.jpg`,
    title: 'Immer alles im Blick',
    description: `Das Online-Portal hält Sie auf dem Laufenden und unterstützt bei der internen Abstimmung, Koordination und Budgetierung: Im Portal kann durch digitale Freigabeprozesse als Team gearbeitet werden.`,
    url: '',
  },
];

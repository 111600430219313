import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { Form } from '../../../../../lib/components';
import { MutationUpdateRoomArgs } from '../../../../../lib/core/api/generated';
import { AllRoomSteps } from '../../../typesUtils';
import { useUpdateRoom } from '../../../../../lib/core/repositories/room-repository';
import { IConfiguratorParamsPagesProps } from '../ConfiguratorParamsPagesProps';
import { RoomDesignScreen } from './RoomDesignScreen';
import { RoomTypeScreen } from './RoomTypeScreen';
import { requiredString } from '../../../../../lib/utils/yup-reductions';

/**
 * This component is rendered inside a <Form>
 * So all form components and form useField() are available here
 * @constructor
 */
export const RoomStylePage: React.FC<IConfiguratorParamsPagesProps> = ({ room, step, changeStep }) => {
  const [screen, setScreen] = useState(1);

  const nextScreen = useCallback(() => {
    setScreen(screen + 1);
  }, [screen]);

  const prevScreen = useCallback(() => {
    setScreen(screen - 1);
  }, [screen]);

  const [updateRoom] = useUpdateRoom();
  const initialValues: MutationUpdateRoomArgs = {
    id: room.id,
    roomTypeId: room.roomTypeId,
    roomDesignId: room.roomDesignId,
  };

  // Next step is always params
  const afterSubmit = React.useCallback(() => {
    changeStep(AllRoomSteps.RoomParams.step);
  }, [changeStep]);

  return (
    <Form
      onSubmit={updateRoom}
      initialValues={initialValues}
      afterSubmit={afterSubmit}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {screen === 1 ? <RoomTypeScreen onChange={nextScreen} /> : <RoomDesignScreen onBack={prevScreen} />}
    </Form>
  );
};

const validationSchema = Yup.object().shape<MutationUpdateRoomArgs>({
  id: requiredString(''),
  roomTypeId: requiredString('Dieses Feld darf nicht leer sein.'),
  roomDesignId: requiredString('Dieses Feld darf nicht leer sein.'),
});

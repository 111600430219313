import { TUrl } from '../types';
import config from 'config';
import { RoomType, FloorType, ColorScheme } from '../lib/core/api/generated';

const { staticServer } = config;

export interface IRoomDesignTip {
  title: string;
  description: string;
  images: TUrl[];
}

enum IRoomTypeUrn {
  working = 'working',
  meeting = 'meeting',
  community = 'community',
  lounge = 'lounge',
}

export type IChooseStyleCover = {
  [key in IRoomTypeUrn]: string;
};

export interface IRoomDesign {
  id: string;
  urn: string;
  cover: TUrl;
  chooseStyleCover: IChooseStyleCover;
  title: string;
  images: TUrl[];
  tags: string[];
  tip: IRoomDesignTip;
  description: {
    short: string;
    full: string;
  };
}

interface IRoomTypeOwn {
  cover: TUrl;
  isComingSoon?: boolean;
  urn: string;
}

type TRoomTypes = RoomType & IRoomTypeOwn;

interface IFloorTypeOwn {
  cover: TUrl;
  urn: string;
}

type TFloorTypes = FloorType & IFloorTypeOwn;

interface IColorSchemeOwn {
  cover: IChooseStyleCover;
  colors: string[];
}

type TColorSchemes = ColorScheme & IColorSchemeOwn;

export const floorTypes: TFloorTypes[] = [
  { id: '6183', name: 'Holz / Laminat', cover: `${staticServer}/cms/site/assets/files/1885/wood.png`, urn: 'holz' },
  { id: '6184', name: 'Teppich', cover: `${staticServer}/cms/site/assets/files/1885/carpet.png`, urn: 'teppish' },
  { id: '6182', name: 'Kautschuk / PVC', cover: `${staticServer}/cms/site/assets/files/1885/pvc.png`, urn: 'pvc' },
];

export const roomTypes: TRoomTypes[] = [
  { id: '6180', name: 'Working', cover: `${staticServer}/cms/site/assets/files/2305/room_working.jpg`, urn: 'working' },
  { id: '6132', name: 'Meeting', cover: `${staticServer}/cms/site/assets/files/2305/room_meeting.jpg`, urn: 'meeting' },
  {
    id: '6133',
    name: 'Community',
    cover: `${staticServer}/cms/site/assets/files/2305/room_community.jpg`,
    isComingSoon: true,
    urn: 'community',
  },
  {
    id: '6134',
    name: 'Lounge',
    cover: `${staticServer}/cms/site/assets/files/2305/room_lounge.jpg`,
    isComingSoon: true,
    urn: 'lounge',
  },
];

export const roomDesigns: IRoomDesign[] = [
  {
    id: '5937',
    urn: 'minimal-modern',
    cover: `${staticServer}/cms/site/assets/files/1886/minimal_modern_0.jpg`,
    chooseStyleCover: {
      working: `${staticServer}/cms/site/assets/files/1886/work_room-min_modern.jpg`,
      meeting: `${staticServer}/cms/site/assets/files/1886/meet_room-min_modern.jpg`,
      community: `${staticServer}/cms/site/assets/files/1886/meet_room-min_modern.jpg`,
      lounge: `${staticServer}/cms/site/assets/files/1886/meet_room-min_modern.jpg`,
    },
    title: 'Minimal Modern',
    images: [
      `${staticServer}/cms/site/assets/files/1886/minimal_modern_1.jpg`,
      `${staticServer}/cms/site/assets/files/1886/minimal_modern_2.jpg`,
      `${staticServer}/cms/site/assets/files/1886/minimal_modern_3.jpg`,
    ],
    tags: ['hochwertig', 'zeitlos', 'grafisch', 'puristisch', 'zurückhaltend'],
    tip: {
      title: 'Minimal Modern',
      description: `<div>
      <p>Dieser Stil gibt neutrale und klare Farben vor, damit das eigene Unternehmen mit seiner CI-Farbwelt besonders gut zur Geltung kommen kann. Akzente werden hier lediglich in zeitlosem Chrom gesetzt, um viel Raum für lebendige Gestaltung zu geben. Die Designer haben in diesem Stil die Kunst des Weglassens perfektioniert – keine Muster und keine grellen Farben, die das Auge irritieren könnten. Dafür aber eine reduzierte Formsprache, klare Linien mit Ecken und Kanten, die die Umgebung gleichzeitig harmonisch und hochwertig erscheinen lassen.</p>
      <br />
      <p>In diesem Look wird klassisch schwarz-weiß gearbeitet, die kühle Umgebung wird durch Blautöne akzentuiert. Abgerundet wird dieser Stil durch deckende, warme Cremetöne wie Beige, aber auch Gelbtöne – Orange oder Ocker – damit auch hier eine wohlige Atmosphäre entsteht.
      </p>
      </div>`,
      images: [
        `${staticServer}/cms/site/assets/files/1886/minimal_modern_1.jpg`,
        `${staticServer}/cms/site/assets/files/1886/minimal_modern_2.jpg`,
        `${staticServer}/cms/site/assets/files/1886/minimal_modern_3.jpg`,
      ],
    },
    description: {
      short:
        'Ein zeitloser Stil: Dezente, zurückhaltende und oft monochrome Farbschema in einer minimalistischen Umgebung.',
      full:
        'Dieser Stil gibt neutrale und klare Farben vor, damit das eigene Unternehmen mit seiner CI-Farbwelt besonders gut zur Geltung kommen kann. Akzente werden hier lediglich in zeitlosem Chrom gesetzt, um viel Raum für lebendige Gestaltung zu geben. Die Designer haben in diesem Stil die Kunst des Weglassens perfektioniert – keine Muster und keine grellen Farben, die das Auge irritieren könnten. Dafür aber eine reduzierte Formsprache, klare Linien mit Ecken und Kanten, die die Umgebung gleichzeitig harmonisch und hochwertig erscheinen lassen.',
    },
  },
  {
    id: '5939',
    urn: 'modern-glam',
    cover: `${staticServer}/cms/site/assets/files/1886/modern_glam_0.jpg`,
    chooseStyleCover: {
      working: `${staticServer}/cms/site/assets/files/1886/work_room-modern_glam.jpg`,
      meeting: `${staticServer}/cms/site/assets/files/1886/meet_room-modern_glam.jpg`,
      community: `${staticServer}/cms/site/assets/files/1886/meet_room-min_modern.jpg`,
      lounge: `${staticServer}/cms/site/assets/files/1886/meet_room-min_modern.jpg`,
    },
    title: 'Modern Glam',
    tip: {
      title: 'Modern Glam',
      description: `<div>
      <p>Modern Glam verbindet elegante Formen und hochwertiges Material – dunkle Farbtöne erzeugen einen eleganten Chic, sogenannte Statement-Pieces unterstreichen beim Betrachter den Eindruck einer besonders edlen Umgebung. Dieser Stil ist perfekt für Menschen und Unternehmen, die Trends definieren und Stile prägen.</p>
      <br />
      <p>Dieser selbstbewusste Stil kombiniert Messing-Elemente mit hochwertigen Oberflächen aus dunklem Wallnussholz oder Marmor. Sanft schimmernde Polstermöbel aus Samt komplettieren den Eindruck besonderer Eleganz und Hochwertigkeit. Durch geschickte Stilbrüche in Form von poppigen Neon-Signs oder zeitgemäße Terrazzo-Elemente wird die Umgebung modern interpretiert.</p>
      <br />
      <p>Die eingesetzten Farben sind rauchig und gedeckt, in der neutralen Ausführung sind das vornehmlich Grau-, Petrol- und Grüntöne. Die warme Umgebung setzt sich vor allem aus Rosé-, Purpur- und Auberginetönen zusammen, die kühle Farbwelt aus Grau- und dunklen Blautönen.
      </p>
      </div>`,
      images: [
        `${staticServer}/cms/site/assets/files/1886/modern_glam_1.jpg`,
        `${staticServer}/cms/site/assets/files/1886/modern_glam_2.jpg`,
        `${staticServer}/cms/site/assets/files/1886/modern_glam_3.jpg`,
      ],
    },
    images: [
      //
      `${staticServer}/cms/site/assets/files/1886/modern_glam_1.jpg`,
      `${staticServer}/cms/site/assets/files/1886/modern_glam_2.jpg`,
      `${staticServer}/cms/site/assets/files/1886/modern_glam_3.jpg`,
    ],
    tags: ['elegant', 'stylish', 'glamourös', 'edel', 'gemütlich'],
    description: {
      short:
        'Modern Glamour orientiert sich am Stil der “Goldenen Zwanziger“: Hier werden dekorative Metall-Elemente in eine schlanke Umgebung gebracht.',
      full:
        'Modern Glam verbindet elegante Formen und hochwertiges Material – dunkle Farbtöne erzeugen einen eleganten Chic, sogenannte Statement-Pieces unterstreichen beim Betrachter den Eindruck einer besonders edlen Umgebung. Dieser Stil ist perfekt für Menschen und Unternehmen, die Trends definieren und Stile prägen.',
    },
  },
  {
    id: '5938',
    urn: 'urban-cool',
    cover: `${staticServer}/cms/site/assets/files/1886/urban_cool_0.jpg`,
    chooseStyleCover: {
      working: `${staticServer}/cms/site/assets/files/1886/work_room-urban_cool.jpg`,
      meeting: `${staticServer}/cms/site/assets/files/1886/meet_room-urban_cool.jpg`,
      community: `${staticServer}/cms/site/assets/files/1886/meet_room-min_modern.jpg`,
      lounge: `${staticServer}/cms/site/assets/files/1886/meet_room-min_modern.jpg`,
    },
    title: 'Urban Cool',
    images: [
      //
      `${staticServer}/cms/site/assets/files/1886/urban_cool_1.jpg`,
      `${staticServer}/cms/site/assets/files/1886/urban_cool_2.jpg`,
      `${staticServer}/cms/site/assets/files/1886/urban_cool_3.jpg`,
    ],
    tags: ['unkonventionell', 'urban', 'energetisch', 'jung'],
    tip: {
      title: 'Urban Cool',
      description: `<div>
      <p>Besonders junge Unternehmen strapazieren mit ihren Ideen und ihrer kreativen Energie das Althergebrachte: Urban Cool ist der Stil für diese Generation von Denkern, denn hier ist alles erlaubt. Die urige Atmosphäre von Holzelementen wird mit knalligen Farben aufgebrochen, Wärme und Wohnliches werden durch Messing- und Kupferelemente beigesteuert. Die Basis bildet Beton, unbehandeltes Holz und Rohstahl, rohe Materialien, die bewusst nicht vor dem Betrachter verborgen werden – so entsteht ein moderner, urbaner und vor allem unkonventioneller Look.</p>
      <br />
      <p>Die gedeckten Grundfarben erzeugen auch in dieser rustikalen Umgebung Ruhe – auf ihnen kommen die für den Stil typischen Wandgestaltungen besonders deutlich zur Geltung. Kombiniert man den zeitlosen Minimal Modern-Stil mit diesem expressiven Innendesign, erschafft man eine unkonventionelle und dennoch erwachsene Arbeitsatmosphäre.
      </p>
      </div>`,
      images: [
        `${staticServer}/cms/site/assets/files/1886/urban_cool_1.jpg`,
        `${staticServer}/cms/site/assets/files/1886/urban_cool_2.jpg`,
        `${staticServer}/cms/site/assets/files/1886/urban_cool_3.jpg`,
      ],
    },
    description: {
      short:
        'Urban Cool ist ein junger und mutiger Einrichtungsstil: Hier werden unverputzte Wände, Holzelemente und industriell anmutende Neonschilder bewusst eingesetzt, um eine rauhe und charmante, städtische Atmosphäre zu imitieren.',
      full:
        'Besonders junge Unternehmen strapazieren mit ihren Ideen und ihrer kreativen Energie das Althergebrachte: Urban Cool ist der Stil für diese Generation von Denkern, denn hier ist alles erlaubt. Die urige Atmosphäre von Holzelementen wird mit knalligen Farben aufgebrochen, Wärme und Wohnliches werden durch Messing- und Kupferelemente beigesteuert. Die Basis bildet Beton, unbehandeltes Holz und Rohstahl, rohe Materialien, die bewusst nicht vor dem Betrachter verborgen werden – so entsteht ein moderner, urbaner und vor allem unkonventioneller Look.',
    },
  },
  {
    id: '5940',
    urn: 'homely-chic',
    cover: `${staticServer}/cms/site/assets/files/1886/homely_chich_0.jpg`,
    chooseStyleCover: {
      working: `${staticServer}/cms/site/assets/files/1886/work_room-homely_chic.jpg`,
      meeting: `${staticServer}/cms/site/assets/files/1886/meet_room-homely_chic.jpg`,
      community: `${staticServer}/cms/site/assets/files/1886/meet_room-min_modern.jpg`,
      lounge: `${staticServer}/cms/site/assets/files/1886/meet_room-min_modern.jpg`,
    },
    title: 'Homely Chic',
    images: [
      //
      `${staticServer}/cms/site/assets/files/1886/homely_chich_1.jpg`,
      `${staticServer}/cms/site/assets/files/1886/homely_chich_2.jpg`,
      `${staticServer}/cms/site/assets/files/1886/homely_chich_3.jpg`,
    ],
    tags: ['klassisch', 'wohnlich', 'frisch', 'hell', 'bodenständig'],
    tip: {
      title: 'Homely Chic',
      description: `<div>
      <p>Homely chic ist bekannt und bewährt: Naturmaterialien, leichte und helle Farben erzeugen eine Atmosphäre zum Wohlfühlen für Menschen, die nach Ruhe suchen und sie in modernen skandinavischen Einrichtungsstilen finden. In diese Umgebung gehören Grünflächen – unser Farbangebot reicht von zarten Grüntönen bis zum kräftigen Ocker, damit die restliche Umgebung perfekt ergänzt werden kann.</p>
      </div>`,
      images: [
        `${staticServer}/cms/site/assets/files/1886/homely_chich_1.jpg`,
        `${staticServer}/cms/site/assets/files/1886/homely_chich_2.jpg`,
        `${staticServer}/cms/site/assets/files/1886/homely_chich_3.jpg`,
      ],
    },
    description: {
      short:
        'Schlichte und klare Formen, meist aus hellem Eichenholz, kombiniert mit Pastellfarben: so entsteht eine leichte und gefällige Umgebung für Menschen, die es bodenständig mögen.',
      full:
        'Homely chic ist bekannt und bewährt: Naturmaterialien, leichte und helle Farben erzeugen eine Atmosphäre zum Wohlfühlen für Menschen, die nach Ruhe suchen und sie in modernen skandinavischen Einrichtungsstilen finden. In diese Umgebung gehören Grünflächen – unser Farbangebot reicht von zarten Grüntönen bis zum kräftigen Ocker, damit die restliche Umgebung perfekt ergänzt werden kann.',
    },
  },
];

export const colorSchemes: TColorSchemes[] = [
  {
    id: '6129',
    name: 'Warm',
    cover: {
      working: `${staticServer}/cms/site/assets/files/1887/work_temp1_warm.jpg`,
      meeting: `${staticServer}/cms/site/assets/files/1887/meet_temp1_warm.jpg`,
      community: `${staticServer}/cms/site/assets/files/1887/meet_temp1_warm.jpg`,
      lounge: `${staticServer}/cms/site/assets/files/1887/meet_temp1_warm.jpg`,
    },
    colors: ['#FFF6C2', '#FFE39A', '#FFA466', '#EE7F4B', '#534F41', '#B6DEB9'],
  },
  {
    id: '6128',
    name: 'Kalt',
    cover: {
      working: `${staticServer}/cms/site/assets/files/1887/work_temp2_cold.jpg`,
      meeting: `${staticServer}/cms/site/assets/files/1887/meet_temp2_cold.jpg`,
      community: `${staticServer}/cms/site/assets/files/1887/meet_temp2_cold.jpg`,
      lounge: `${staticServer}/cms/site/assets/files/1887/meet_temp2_cold.jpg`,
    },
    colors: ['#CDEDFA', '#B8E1EA', '#82BAC4', '#5EA3AA', '#9FA29D', '#C2CBCA'],
  },
  {
    id: '6127',
    name: 'Neutral',
    cover: {
      working: `${staticServer}/cms/site/assets/files/1887/work_temp3_neutral.jpg`,
      meeting: `${staticServer}/cms/site/assets/files/1887/meet_temp3_neutral.jpg`,
      community: `${staticServer}/cms/site/assets/files/1887/meet_temp3_neutral.jpg`,
      lounge: `${staticServer}/cms/site/assets/files/1887/meet_temp3_neutral.jpg`,
    },
    colors: ['#E9E8E8', '#E3E1E1', '#CFC9CC', '#BDB6BC', '#595963', '#C7C5CF'],
  },
];

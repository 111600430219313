import React from 'react';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';

import { useCompletePasswordReset } from '../../../lib/core/repositories/register-repository';
import { MutationCompletePasswordResetArgs } from '../../../lib/core/api/generated';
import { GqlErrors } from '../../../lib/core/errors/gql-error';
import { Modal, Form, FormButton, FormFooter, FormInput, FormLayout } from '../../../lib/components';
import { requiredString } from '../../../lib/utils/yup-reductions';

interface ICompletePasswordResetForm {
  password: string;
}

export const CompletePasswordResetPage: React.FC = () => {
  const history = useHistory();
  const { token } = useParams<MutationCompletePasswordResetArgs>();
  const [submitCompletePasswordReset] = useCompletePasswordReset();

  const closeModal = React.useCallback(() => {
    history.push('/');
  }, [history]);

  const onSubmit = React.useCallback(
    (data: ICompletePasswordResetForm) => {
      submitCompletePasswordReset({ token, password: data.password }).then(closeModal);
    },
    [closeModal, submitCompletePasswordReset, token],
  );

  return (
    <Modal title="Neues passwort" isOpened={true} closable={false}>
      <Form
        onSubmit={onSubmit}
        afterSubmit={closeModal}
        initialValues={{ password: '' }}
        validationSchema={validationSchema}
        errorsHR={[
          [GqlErrors.MAIL_TOKEN_EXPIRED, 'The reset password link is expired'],
          [GqlErrors.MAIL_TOKEN_NOT_FOUND, 'The reset password link is incorrect'],
        ]}
      >
        <FormLayout>
          <FormInput type="password" name="password" label="Passwort" />
          <FormFooter>
            <FormButton color="bronze" isIgnoreValidation={true} onClick={closeModal}>
              Abbrechen
            </FormButton>
            <FormButton type="submit" variant="contained" color="bronze">
              Passwort zurücksetzen
            </FormButton>
          </FormFooter>
        </FormLayout>
      </Form>
    </Modal>
  );
};

const validationSchema = Yup.object().shape<ICompletePasswordResetForm>({
  password: requiredString('Dieses Feld darf nicht leer sein.').min(
    8,
    'Das Passwort sollte aus 8 oder mehr Symbolen bestehen',
  ),
});

import { colors, styled } from '../../themes';

interface IImageCardCoverProps {
  src: string;
}

export const ImageCardCover = styled.div<IImageCardCoverProps>`
  display: block;
  margin: auto;
  width: 100%;
  height: 100%;
  background-image: url("${({ src }) => src}");
  background-position: center;
  background-size: cover;
  transition: transform 0.2s ease-in;
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: linear-gradient(180deg, rgba(16, 16, 16, 0.76) 0%, rgba(16, 16, 16, 0) 74.48%);
    opacity: 0;
    transition: opacity 0.2s ease-in;
  }
`;

export const ImageCardStyled = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  &:hover {
    z-index: 1;
    ${ImageCardCover} {
      transform: scale(1.1);
      &:before {
        opacity: 1;
      }
    }
  }
`;

export const ImageCardContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 70px;
  width: 100%;
  height: 100%;
  min-height: 478px;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: ${colors.white};

  & > * {
    transition: opacity 0.2s ease-in;
  }

  &:hover {
    & > * {
      opacity: 1;
    }
  }
`;

import React, { useCallback } from 'react';
import * as Yup from 'yup';

import { Container } from '../../../../../lib/components/Container';
import { Delivery } from './Delivery';
import { ProductTotal } from '../../../components/ProductTotal';
import { Address, Room, RoomStatus, User } from '../../../../../lib/core/api/generated';
import { AllRoomSteps, RoomItemsStep, RoomParamsStep } from '../../../typesUtils';
import { formatCurrency } from '../../../../../lib/utils/format-number';
import { ConfiguratorPageFooter } from '../../../components/ConfiguratorPageFooter';
import { Form, FormError } from '../../../../../lib/components/Form';
import { toSummaryForm } from './to-summary-form';
import { fromSummaryForm } from './from-summary-form';
import { ErrorContainer } from './SummaryPage.styled';
import { requiredString } from '../../../../../lib/utils/yup-reductions';
import { On } from '../../../../../lib/core/hooks/on';
import { Loader } from '../../../../../lib/components/Loader';
import { CError } from '../../../../../lib/components/Error';
import { useMe, useUpdateAddress } from '../../../../../lib/core/repositories/user-repository';

import { useSaveRoom } from '../../../../../lib/core/repositories/room-repository';

import { ContentItem, ProductOrCost } from '../../../components/ConfiguratorPageFooter/ConfiguratorPageFooter.styled';
import { useIsInvitedUser } from '../../../../../lib/core/hooks/useIsInvitedUser';

interface IDeliveryScreenProps {
  room: Room;
  changeStep: (newStep: RoomParamsStep | RoomItemsStep) => unknown;
  changeScreen: (screen: number) => unknown;
  getSaveForm?: (value: boolean) => void;
}

export interface ISummaryForm {
  fastShipping: boolean;
  isTheSameAddress: boolean;
  firstName: string;
  lastName: string;
  street: string;
  city: string;
  postcode: string;
  countryId: string;
  telephone: string;
  company: string;
  floor: string;
  deliveryZoneTypeSelect: string;
  isRestrictedParking: boolean;
  restrictedParkingComment: string;
  isTruckParkingAvailable: boolean;
  elevatorSelect: string;
  elevatorMeasurements: string;
  additionalComments: string;
  subscribeToNewsletter: boolean;
  contactMe: boolean;
  acceptTermsAndConditions: boolean;
}

export const DeliveryScreen: React.FC<IDeliveryScreenProps> = ({ room, changeStep, changeScreen, getSaveForm }) => {
  const roomAmount = room.items.filter(({ added }) => added).reduce((acc, item) => acc + item.amount, 0);
  const roomTotal = room.items
    .filter(({ added }) => added)
    .reduce((acc, item) => acc + item.amount * (item.productConfiguration.price || 0), 0);

  const [submitUpdateAddress] = useUpdateAddress();
  const [submitSaveRoom] = useSaveRoom();
  const isInvitedUser = useIsInvitedUser();
  const runSubmitSaveRoom = React.useCallback(
    (values, billingAddress) =>
      submitSaveRoom(fromSummaryForm(room.id, values, billingAddress))
        .then(() => {
          getSaveForm && getSaveForm(true);
        })
        .catch(error => {
          window.scrollTo({ top: 0, left: 0 });
          throw error;
        }),
    [getSaveForm, room.id, submitSaveRoom],
  );

  const handleSubmitSave = React.useCallback(
    (billingAddress: Address | null) => (values: ISummaryForm) => {
      // Set an address if the user hasn't set it yet
      let request: Promise<any> = Promise.resolve();

      if (!billingAddress) {
        const { firstName, lastName, street, city, postcode, countryId, telephone, company } = values;

        request = request.then(() =>
          submitUpdateAddress({
            firstName,
            lastName,
            street,
            city,
            postcode,
            countryId,
            telephone,
            company,
          }),
        );
      }

      request = request
        .then(() => runSubmitSaveRoom(values, billingAddress))
        .then(() => {
          changeScreen(2);
        });

      return request;
    },
    [runSubmitSaveRoom, submitUpdateAddress, changeScreen],
  );

  const handleChangeStep = useCallback(
    newStep => {
      if (isInvitedUser) {
        if (newStep === AllRoomSteps.RoomSummary.step) {
          changeScreen(2);
        } else {
          changeStep(newStep);
        }
      } else {
        changeStep(newStep);
      }
    },
    [isInvitedUser, changeStep, changeScreen],
  );

  return On(
    (user: User) => {
      const billingAddress: Address | null = user.addresses.length ? user.addresses[user.addresses.length - 1] : null;

      return (
        <Form
          onSubmit={handleSubmitSave(billingAddress)}
          initialValues={toSummaryForm(room, billingAddress)}
          validationSchema={summaryValidationSchema}
          // TODO: use the appropriate error and its translation
          errorsHR={[]}
        >
          <ErrorContainer>
            <FormError />
          </ErrorContainer>

          <Container
            css={`
              padding-top: 63px;
              padding-bottom: 100px;
            `}
          >
            <Delivery deliveries={room.deliveries} billingAddress={billingAddress} />
            <ProductTotal />
          </Container>
          <ConfiguratorPageFooter
            step={RoomItemsStep.RoomSummary}
            changeStep={handleChangeStep}
            prevStep={AllRoomSteps.RoomItemsViewer}
            nextStep={isInvitedUser ? AllRoomSteps.RoomSummary : undefined}
            submitTitle={isInvitedUser ? undefined : room.roomStatus !== RoomStatus.Ordered ? 'Speichern' : undefined}
          >
            <ContentItem>
              <ProductOrCost>{roomAmount} Produkte</ProductOrCost>
              <ProductOrCost>{formatCurrency(roomTotal)}</ProductOrCost>
            </ContentItem>
          </ConfiguratorPageFooter>
        </Form>
      );
    },
    () => <Loader />,
    errorState => <CError error={errorState} />,
    useMe(),
  );
};

const summaryValidationSchema = Yup.object().shape<ISummaryForm>({
  fastShipping: Yup.boolean(),
  isTheSameAddress: Yup.boolean(),
  firstName: requiredString('Bitte geben Sie Ihren Vornamen ein.'),
  lastName: requiredString('Bitte geben Sie Ihren Nachnamen ein.'),
  street: requiredString('Dieses Feld darf nicht leer sein.'),
  city: requiredString('Dieses Feld darf nicht leer sein.'),
  postcode: requiredString('Dieses Feld darf nicht leer sein.'),
  countryId: Yup.string().oneOf(['DE']),
  telephone: requiredString('Dieses Feld darf nicht leer sein.'),
  company: requiredString('Dieses Feld darf nicht leer sein.'),
  floor: Yup.string(),
  deliveryZoneTypeSelect: Yup.string().oneOf(['5576', '5577']),
  isRestrictedParking: Yup.boolean(),
  restrictedParkingComment: Yup.string(),
  isTruckParkingAvailable: Yup.boolean(),
  elevatorSelect: Yup.string().oneOf(['5582', '5583']),
  elevatorMeasurements: Yup.string(),
  additionalComments: Yup.string(),
  subscribeToNewsletter: Yup.boolean(),
  contactMe: Yup.boolean(),
  acceptTermsAndConditions: Yup.boolean()
    .required()
    .oneOf([true]),
});

import React from 'react';
import { Field, Label, InputStyled, Error } from './Input.styled';

export type IInputProps = {
  id?: string;
  type?: string;
  name: string;
  value: string;
  label?: string;
  placeholder?: string;
  error?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => unknown;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => unknown;
};

export const Input: React.FC<IInputProps & React.RefAttributes<HTMLInputElement>> = React.forwardRef((props, ref) => {
  const { error, label, id, name, ...inputProps }: IInputProps = props;
  const { type = 'text' } = inputProps;

  const isValid = !Boolean(error);

  return (
    <Field>
      <InputStyled
        {...inputProps}
        data-hook-value={inputProps.value}
        id={id || name}
        type={type}
        isValid={isValid}
        data-hook-input={label}
        ref={ref}
        {...(!label && { className: 'default-placeholder' })}
      />
      {label && <Label htmlFor={id || name}>{label}</Label>}
      {!isValid && <Error>{error}</Error>}
    </Field>
  );
});

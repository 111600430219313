import React from 'react';
import { FormikHelpers } from 'formik';
import * as Yup from 'yup';

import {
  useUpdateAddress,
  useUpdateEmail,
  useMe,
  useUpdatePassword,
  useWatchCurrentAccount,
  useUpdateUser,
} from '../../../../lib/core/repositories/user-repository';

import {
  MutationUpdateAddressArgs,
  MutationUpdateEmailArgs,
  MutationUpdatePasswordArgs,
  MutationUpdateUserArgs,
} from '../../../../lib/core/api/generated';
import { On } from '../../../../lib/core/hooks/on';
import { CError } from '../../../../lib/components/Error';
import { GqlBadCredentialsError } from '../../../../lib/core/errors/gql-error';
import { requiredString } from '../../../../lib/utils/yup-reductions';
import { FieldSet, Legend } from '../../../../lib/components/Form/FieldSet';

import {
  Form,
  FormInput,
  FormFieldRow,
  FormLayout,
  FormFooter,
  FormButton,
  PageSection,
  Modal,
  Button,
} from '../../../../lib/components';

import { Loader } from '../../../../lib/components/Loader';
import { Title, ModalContent } from './ProfilePage.styled';
import lock from '../../../../static/img/mocks/profile/lock.png';
import { useSnackBar } from '../../../../lib/components/SnackBar';

type MutationProp = MutationUpdateAddressArgs & MutationUpdateUserArgs & MutationUpdateEmailArgs;

export const ProfilePage: React.FC = () => {
  const [submitUpdatePassword] = useUpdatePassword();
  const [updateAddress] = useUpdateAddress();
  const [updateUser] = useUpdateUser();
  const [updateEmail] = useUpdateEmail();
  const { showSuccess } = useSnackBar();

  const onSubmitPasswordForm = React.useCallback(
    (values: MutationUpdatePasswordArgs, formHelpers: FormikHelpers<MutationUpdatePasswordArgs>) => {
      return submitUpdatePassword(values)
        .then(() => {
          formHelpers.setFieldValue('pass', '', false);
          formHelpers.setFieldValue('oldPass', '', false);
        })
        .catch(error => {
          formHelpers.setFieldValue('oldPass', '', false);
          throw error;
        });
    },
    [submitUpdatePassword],
  );

  const onSubmit = React.useCallback(
    (values: MutationProp) => {
      return Promise.all([
        updateAddress(values),
        updateUser({ vatId: values.vatId, position: values.position }),
        updateEmail({ email: values.email }),
      ]).then(() => showSuccess('Die Änderungen wurden gespeichert'));
    },
    [updateAddress, updateUser, updateEmail],
  );

  const handleShowSuccess = React.useCallback(() => {
    showSuccess('Passwort geändert');
  }, []);

  return On(
    ({ firstName, lastName, addresses, email, vatId, position }) => (
      <PageSection>
        <Title data-hook-name="Profil">Profil</Title>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            firstName,
            lastName,
            email,
            company: addresses && addresses[addresses?.length - 1]?.company,
            telephone: addresses && addresses[addresses?.length - 1]?.telephone,
            street: addresses && addresses[addresses?.length - 1]?.street,
            city: addresses && addresses[addresses?.length - 1]?.city,
            postcode: addresses && addresses[addresses?.length - 1]?.postcode,
            countryId: 'DE',
            vatId,
            position,
          }}
          validationSchema={userValidationSchema}
        >
          <FormLayout>
            <FieldSet>
              <Legend>Allgemeine Informationen</Legend>

              <FormFieldRow>
                <FormInput name="lastName" label="Name" />
                <FormInput name="firstName" label="Vorname" />
              </FormFieldRow>
              <FormFieldRow>
                <FormInput type="email" name="email" label="E-Mail" />
                <FormInput type="tel" name="telephone" label="Telefonnummer" />
              </FormFieldRow>
              <FormFieldRow>
                <FormInput type="text" name="position" label="Position (Optional)" />
                <div style={{ width: '100%' }} />
              </FormFieldRow>
            </FieldSet>

            <FieldSet>
              <Legend>Rechnungsadresse</Legend>
              <FormFieldRow>
                <FormInput name="company" label="Firma" />
                <FormInput type="text" name="vatId" label="Umsatzsteuer-ID (Optional)" />
              </FormFieldRow>
              <FormInput name="street" label="Straße und Hausnummer" />
              <FormFieldRow>
                <FormInput name="postcode" label="PLZ" />
                <FormInput name="city" label="Stadt" />
              </FormFieldRow>
            </FieldSet>

            <FormButton variant="contained" color="bronze" type="submit">
              Speichern
            </FormButton>
          </FormLayout>
        </Form>

        <Form
          onSubmit={onSubmitPasswordForm}
          initialValues={{
            oldPass: '',
            pass: '',
          }}
          validationSchema={updatePasswordValidationSchema}
          afterSubmit={handleShowSuccess}
          errorsHR={[[GqlBadCredentialsError, 'Falsches Passwort eingegeben. Bitte versuchen Sie es erneut.']]}
          css={{ marginTop: '100px' }}
        >
          <FormLayout>
            <FieldSet>
              <Legend>Passwort ändern</Legend>

              <FormFieldRow>
                <FormInput type="password" name="oldPass" label="Altes Passwort" />
                <FormInput type="password" name="pass" label="Neues Passwort" />
              </FormFieldRow>
              <FormFooter>
                <FormButton type="submit" variant="contained" color="bronze">
                  Passwort ändern
                </FormButton>
              </FormFooter>
            </FieldSet>
          </FormLayout>
        </Form>
      </PageSection>
    ),
    () => <Loader />,
    errorState => <CError error={errorState} />,
    useMe(),
    useWatchCurrentAccount(),
  );
};

const userValidationSchema = Yup.object().shape<
  MutationUpdateAddressArgs | MutationUpdateUserArgs | MutationUpdateEmailArgs
>({
  firstName: requiredString('Bitte geben Sie Ihren Vornamen ein.'),
  lastName: requiredString('Bitte geben Sie Ihren Nachnamen ein.'),
  company: requiredString('Bitte benennen Sie Ihr Firma'),
  email: requiredString('Dieses Feld darf nicht leer sein.').email('E-Mail-Adresse falsch'),
  telephone: requiredString('Dieses Feld darf nicht leer sein.'),
  street: requiredString('Dieses Feld darf nicht leer sein.'),
  postcode: requiredString('Dieses Feld darf nicht leer sein.'),
  city: requiredString('Dieses Feld darf nicht leer sein.'),
  countryId: requiredString('Dieses Feld darf nicht leer sein.'),
});

const updatePasswordValidationSchema = Yup.object().shape<MutationUpdatePasswordArgs>({
  pass: requiredString('Dieses Feld darf nicht leer sein.')
    .min(8, 'Das Passwort sollte aus 8 oder mehr Symbolen bestehen')
    .max(32, 'Das Passwort sollte aus 32 oder weniger Symbolen bestehen'),
  oldPass: requiredString('Dieses Feld darf nicht leer sein.'),
});

import gql from 'graphql-tag';

export const gqlDocsCategory = {
  categoriesList: gql`
    query categories {
      categories {
        id
        name
        parentId
        children {
          id
          name
          parentId
        }
      }
    }
  `,
};

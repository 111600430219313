import gql from 'graphql-tag';

const productConfigurationSimple = `{
  id
  sku
  name
  configurationName,
  price
  imageUrls
  description
  hint
  configurableProduct {
    sku
  }
  width
  depth
  height
  heightAdjustable
  heightMin
  heightMax
  assembly
  hasCableFunnel
  roomDesignId
  categoryIds
  floorTypeIds
  colorSchemeIds
  roomTypeIds
  ceilingTypeIds
  manufacturerId
  color1Id
  color2Id
  material1Id
  material2Id
  productFamilyId
  chairRollId
  acousticPanelId
  furnitureTypeId
  workUnitId
  workSettingId
}`;

export const productConfiguration = `{
  id
  sku
  name
  configurationName
  price
  imageUrls
  description
  hint
  configurableProduct {
    sku
    productConfigurations ${productConfigurationSimple}
  }
  width
  depth
  height
  heightAdjustable
  heightMin
  heightMax
  assembly
  hasCableFunnel
  roomDesignId
  categoryIds
  floorTypeIds
  colorSchemeIds
  roomTypeIds
  ceilingTypeIds
  manufacturerId
  color1Id
  color2Id
  material1Id
  material2Id
  productFamilyId
  chairRollId
  acousticPanelId
  furnitureTypeId
  workUnitId
  workSettingId
}`;

const roomItem = `{
  id
  productConfiguration ${productConfiguration}
  productSetId
  amount
  added
}`;

const shippingAddress = `{
  firstName
  lastName
  street
  city
  postcode
  countryId
  telephone
  company
  deliveryZoneTypeSelect {
   value
  }
  restrictedParkingSelect {
   value
  }
  truckParkingAvailableSelect {
   value
  }
  restrictedParkingComment
  elevatorSelect {
   value
  }
  elevatorMeasurements
  additionalComments
  floor
}`;

const roomData = `{
  id
  name
  projectId
  roomTypeId
  roomType {
    name
  }
  roomDesignId
  roomDesign {
    name
  }
  floorTypeId
  colorSchemeId
  size
  staticWorkstations
  flexibleWorkstations
  loungeRequired
  spaceRequired
  storageRequired
  soundproofingRequired
  furnitureTypeId
  workUnitId
  workSettingId
  items ${roomItem}
  deliveries {
    deliveryTimeDays
    price
    deliveryTypeId
    productCount
    deliveryType {
      id
      name
    }
    manufacturer {
      id
      name
    }
  }
  total {
    originalTotalPrice
    originalTotalPriceInclTax
    discountAmount
    taxAmount
    totalPrice
    totalPriceInclTax
    totalItems
  }
  roomStatus
  planMode
  shippingAddress ${shippingAddress}
  contactMe
  acceptTermsAndConditions
  fastShipping
  moodboard {
    images {
      url,
      size,
      descriptions {
        coordinates
        contentType
        contentHtml
        contentText
        style
      }
    }
  }
  approvals {
    userId,
    approvalStatus
  }
}`;

export const gqlDocsRoom = {
  roomTypeList: gql`
    query roomTypes {
      roomTypes {
        id
        name
      }
    }
  `,

  roomDesignList: gql`
    query roomDesigns {
      roomDesigns {
        id
        name
      }
    }
  `,

  floorTypeList: gql`
    query floorTypes {
      floorTypes {
        id
        name
      }
    }
  `,
  colorSchemeList: gql`
    query colorSchemes {
      colorSchemes {
        id
        name
      }
    }
  `,

  workUnits: gql`
    query workUnits {
      workUnits {
        id
        name
      }
    }
  `,

  furnitureTypes: gql`
    query furnitureTypes {
      furnitureTypes {
        id
        name
      }
    }
  `,

  workSettings: gql`
    query workSettings {
      workSettings {
        id
        name
      }
    }
  `,

  createRoom: gql`
    mutation createRoom($name: String!, $projectId: String!) {
      createRoom(name: $name, projectId: $projectId) ${roomData}
    }
  `,

  deleteRoom: gql`
    mutation deleteRoom($roomId: String!) {
      deleteRoom(roomId: $roomId)
    }
  `,

  getRoom: gql`
    query room($id: String!) {
      room(id: $id) ${roomData}
    }
  `,

  updateRoom: gql`
    mutation updateRoom(
      $id: String!
      $roomTypeId: String
      $roomDesignId: String
      $size: Int
      $staticWorkstations: Int
      $flexibleWorkstations: Int
      $spaceRequired: Boolean
      $storageRequired: Boolean
      $soundproofingRequired: Boolean
      $loungeRequired: Boolean
      $workUnitId: String
      $furnitureTypeId: String
      $workSettingId: String
      $floorTypeId: String
      $colorSchemeId: String
      $planMode: RoomPlanMode
    ) {
      updateRoom(
        id: $id
        roomTypeId: $roomTypeId
        roomDesignId: $roomDesignId
        size: $size
        staticWorkstations: $staticWorkstations
        flexibleWorkstations: $flexibleWorkstations
        spaceRequired: $spaceRequired
        storageRequired: $storageRequired
        soundproofingRequired: $soundproofingRequired
        loungeRequired: $loungeRequired
        workUnitId: $workUnitId
        furnitureTypeId: $furnitureTypeId
        workSettingId: $workSettingId
        floorTypeId: $floorTypeId
        colorSchemeId: $colorSchemeId
        planMode: $planMode
      ) ${roomData}
    }
  `,

  planForMe: gql`
    mutation planForMe($roomId: String!) {
      planForMe(roomId: $roomId)
    }
  `,

  letMePlan: gql`
    mutation letMePlan($roomId: String!) {
      letMePlan(roomId: $roomId)
    }
  `,

  generateRoomPDF: gql`
    mutation generateRoomPDF($roomId: String!) {
      generateRoomPDF(roomId: $roomId) {
        uri
      }
    }
  `,

  addAllRoomItems: gql`
    mutation addAllRoomItems($roomId: String!) {
      addAllRoomItems(roomId: $roomId) ${roomItem}
    }
  `,

  saveRoom: gql`
    mutation saveRoom(
      $roomId: String!
      $subscribeToNewsletter: Boolean
      $contactMe: Boolean
      $acceptTermsAndConditions: Boolean
      $fastShipping: Boolean
      $shippingAddress: RoomShippingAddressInput
      $couponCode: String
    ) {
      saveRoom(
        roomId: $roomId
        subscribeToNewsletter: $subscribeToNewsletter
        contactMe: $contactMe
        acceptTermsAndConditions: $acceptTermsAndConditions
        fastShipping: $fastShipping
        shippingAddress: $shippingAddress
        couponCode: $couponCode
      ) ${roomData}
    }
  `,

  orderRoom: gql`
    mutation orderRoom($id: String!) {
      orderRoom(id: $id)
    }
  `,

  approveRoom: gql`
    mutation approveRoom($roomId: String!) {
      approveRoom(roomId: $roomId) ${roomData}
    }
  `,

  addRoomItem: gql`
    mutation addRoomItem($roomItemId: String!) {
      addRoomItem(roomItemId: $roomItemId) {
        id
        added
      }
    }
  `,

  dropRoomItem: gql`
    mutation dropRoomItem($roomItemId: String!) {
      dropRoomItem(roomItemId: $roomItemId) {
        id
        added
      }
    }
  `,

  updateRoomItem: gql`
    mutation updateRoomItem($roomItemId: String!, $productConfigurationSku: String!, $amount: Int!) {
      updateRoomItem(roomItemId: $roomItemId, productConfigurationSku: $productConfigurationSku, amount: $amount) ${roomItem}
    }
  `,

  createRoomItem: gql`
    mutation createRoomItem($roomId: String!, $productConfigurationSku: String!, $amount: Int!, $productSetId: String) {
      createRoomItem(roomId: $roomId, productConfigurationSku: $productConfigurationSku, amount: $amount, productSetId: $productSetId) ${roomItem}
    }
  `,

  removeRoomItem: gql`
    mutation removeRoomItem($roomItemId: String!) {
      removeRoomItem(roomItemId: $roomItemId)
    }
  `,

  getProductSet: gql`
    query getProductSet($id: String!) {
      productSet(id: $id) {
        productConfigurations ${productConfiguration}
      }
    }
  `,

  inviteExecutiveForRoom: gql`
    mutation inviteExecutiveForRoom($email: String!, $roomId: String!, $locale: String) {
      inviteExecutiveForRoom(email: $email, roomId: $roomId, locale: $locale)
    }
  `,
};

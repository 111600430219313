import React, { ReactElement } from 'react';

import { IToggleButtonProps } from '../ToggleButton';
import { StyledToggleButtonGroup } from './ToggleButtonGroup.styled';

import { isValueSelected } from './isValueSelected';
import { isValidElement } from '../../utils/isValidElement';

type ToggleButtonElement = ReactElement<IToggleButtonProps>;

export interface IToggleButtonGroupProps {
  children: ToggleButtonElement[];
  value: string | string[];
  onChange: (selected: string[]) => unknown;
}

const defaultProps: IToggleButtonGroupProps = {
  children: [],
  value: '',
  onChange: () => {},
};

export const ToggleButtonGroup: React.FC<IToggleButtonGroupProps> = ({ children, ...rest }) => {
  const { value, onChange }: IToggleButtonGroupProps = { ...defaultProps, ...rest };

  const [selected, setSelected] = React.useState<string[]>([]);

  const handleButtonClick = (name: string) => {
    const index = selected.findIndex(x => x === name);

    if (index !== -1) {
      setSelected([...selected.slice(0, index), ...selected.slice(index + 1)]);
    } else {
      setSelected([...selected, name]);
    }
  };

  React.useEffect(() => {
    onChange(selected);
  }, [onChange, selected]);

  return (
    <StyledToggleButtonGroup>
      {React.Children.map(children, child => {
        if (!isValidElement(child)) {
          return null;
        }

        const buttonElement: ReactElement<IToggleButtonProps> = child;

        const { value: buttonValue } = buttonElement.props;
        const isSelected = isValueSelected(buttonValue, value);

        return React.cloneElement(buttonElement, { isSelected, onClick: handleButtonClick });
      })}
    </StyledToggleButtonGroup>
  );
};

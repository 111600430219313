import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';

import { ConfiguratorPageHeader } from '../../../components';
import { Form } from '../../../../../lib/components';
import { workUnits } from '../containers/WorkUnitsField';
import { workSettings } from '../containers/WorkSettingField';
import { furnitureTypes } from '../containers/FurnitureTypesField';
import { IConfiguratorParamsPagesProps } from '../ConfiguratorParamsPagesProps';
import { MutationUpdateRoomArgs, Room, RoomPlanMode, RoomStatus } from '../../../../../lib/core/api/generated';
import {
  useLetMePlan,
  useNonWatchGetRoom,
  usePlanForMe,
  useUpdateRoom,
} from '../../../../../lib/core/repositories/room-repository';
import { roomDesigns, roomTypes, floorTypes, colorSchemes } from '../../../../fixtures';
import { AllRoomSteps } from '../../../typesUtils';
import { CreatingSolutionPage } from '../../CreatingSolutionPage';
import { pause } from '../../../../../lib/utils/pause';
import { FloorTypesScreen } from './FloorTypesScreen';
import { ColorSchemesScreen } from './ColorSchemesScreen';
import { OtherParamsScreen } from './OtherParamsScreen';
import { requiredString } from '../../../../../lib/utils/yup-reductions';
import { useConfirmationModal } from '../../../../../lib/components/ConfirmationModal/useConfirmationModal';
import { useSnackBar } from '../../../../../lib/components/SnackBar';
import { ConfirmModalContent } from './components/ConfirmModalContent';
import { text } from '@storybook/addon-knobs';

/**
 * This component is rendered inside a <Form>
 * So all form components and form useFormikContext() are available here
 * @constructor
 */
export const RoomParamsPage: React.FC<IConfiguratorParamsPagesProps> = ({ room, step, changeStep }) => {
  const [screen, setScreen] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const showConfirm = useConfirmationModal();
  const nextScreen = useCallback(() => {
    setScreen(screen + 1);
  }, [screen]);

  const prevScreen = useCallback(() => {
    setScreen(screen - 1);
  }, [screen]);

  const [, , refetchRoom] = useNonWatchGetRoom({
    id: room.id,
  });

  const { showAlert } = useSnackBar();

  const [updateRoom] = useUpdateRoom();
  const [runPlanForMe] = usePlanForMe();
  const [runLetMePlan] = useLetMePlan();

  const ref = React.useRef<any>();

  React.useEffect(() => {
    return function cleanup() {
      clearTimeout(ref.current);
    };
  }, []);

  const initialValues = React.useMemo(() => getInitialValues(room), [room]);

  /**
   * Start polling room until it changes status to Planned
   */
  const pollRoom = useCallback((): Promise<unknown> => {
    return refetchRoom({
      id: room.id,
    }).then(result => {
      if (result.roomStatus == RoomStatus.Isplanning) {
        return new Promise(resolve => {
          ref.current = setTimeout(() => resolve(pollRoom()), 1000);
        });
      }
      return result;
    });
  }, [refetchRoom, room.id]);

  const planning = React.useCallback(
    (values: MutationUpdateRoomArgs) => {
      // backend does not handle this fields but check there value [LFG-591]
      updateRoom({ ...values, workUnitId: undefined, workSettingId: undefined })
        .then(() => {
          // Because submit loader is beautiful and we spent lots of time on it
          // Let user enjoy it at least for 4 sec :)
          const polling = () => Promise.all([pollRoom(), pause(4000)]).then(([result]) => result);
          if (room.planMode === RoomPlanMode.PlanForMe) {
            return runPlanForMe(values.id).then(polling);
          }
          return runLetMePlan(values.id).then(polling);
        })
        .then((room: Room) => {
          changeStep(
            room.planMode === RoomPlanMode.LetMePlan
              ? AllRoomSteps.RoomItemsEditor.step
              : AllRoomSteps.RoomItemsViewer.step,
          );
        })
        .catch(() => showAlert('Es ist ein Fehler mit dem Server aufgetreten. Bitte versuchen Sie es erneut.'))
        .finally(() => setIsSubmitting(false));
    },
    [updateRoom, room.planMode, runLetMePlan, pollRoom, runPlanForMe, changeStep, showAlert],
  );

  const startPlanning = useCallback(
    (values: MutationUpdateRoomArgs) => {
      if (values.size! < (values.staticWorkstations! + values.flexibleWorkstations!) * 2) {
        showConfirm({
          size: {
            width: '1038px',
          },
          title: '',
          component: ConfirmModalContent,
          onAgree: () => {
            planning(values);
            setIsSubmitting(true);
          },
        });
      } else {
        planning(values);
        setIsSubmitting(true);
      }

      return false;
    },
    [showConfirm, planning],
  );

  const backStep = useCallback(() => {
    changeStep(AllRoomSteps.RoomStyle.step);
  }, [changeStep]);

  const renderScreen = useCallback(() => {
    switch (screen) {
      default:
      case 1:
        return <FloorTypesScreen onChange={nextScreen} onBack={backStep} />;
      case 2:
        return <ColorSchemesScreen onChange={nextScreen} onBack={prevScreen} />;
      case 3:
        return <OtherParamsScreen step={step} />;
    }
  }, [backStep, nextScreen, prevScreen, screen, step]);

  return (
    <>
      <ConfiguratorPageHeader room={room} step={step} changeStep={changeStep} />
      <Form
        onSubmit={startPlanning}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
      >
        {isSubmitting ? <CreatingSolutionPage /> : renderScreen()}
      </Form>
    </>
  );
};

function getInitialValues(room: Room): MutationUpdateRoomArgs {
  const {
    id,
    roomTypeId,
    roomDesignId,

    size,
    staticWorkstations,
    flexibleWorkstations,

    spaceRequired,
    storageRequired,
    soundproofingRequired,
    loungeRequired,

    workUnitId,
    workSettingId,
    furnitureTypeId,

    floorTypeId,
    colorSchemeId,
  } = room;

  return {
    id,
    roomTypeId: roomTypeId || roomTypes[0].id,
    roomDesignId: roomDesignId || roomDesigns[0].id,

    size: size || 40,
    staticWorkstations: staticWorkstations || 3,
    flexibleWorkstations: flexibleWorkstations || 3,

    spaceRequired: spaceRequired === null || true,
    storageRequired: storageRequired === null || true,
    soundproofingRequired: soundproofingRequired === null || true,
    loungeRequired: loungeRequired === null || true,

    workUnitId: workUnitId || workUnits[0].value,
    workSettingId: workSettingId || workSettings[2].value,
    furnitureTypeId: furnitureTypeId || furnitureTypes[2].value,

    floorTypeId: floorTypeId || floorTypes[0].id,
    colorSchemeId: colorSchemeId || colorSchemes[0].id,
  };
}

const validationSchema = Yup.object().shape<MutationUpdateRoomArgs>({
  id: requiredString(),
  size: Yup.number().required(),
  staticWorkstations: Yup.number().required(),
  flexibleWorkstations: Yup.number().required(),
  workUnitId: requiredString(),
  workSettingId: requiredString(),
  furnitureTypeId: requiredString(),
  floorTypeId: requiredString(),
  colorSchemeId: requiredString(),
  roomTypeId: requiredString('Dieses Feld darf nicht leer sein.'),
  roomDesignId: requiredString('Dieses Feld darf nicht leer sein.'),
});

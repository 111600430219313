import React from 'react';

import { BlockTitle } from '../../../../lib/components/Block/BlockTitle';

import { CardRoomType, RoomTitle, ImageWrapper, Image, List, StatusPanel } from './RoomsType.styled';

interface ICardRoomType {
  title: string;
  cover: string;
  isAvailable: boolean;
}

export const roomsType: ICardRoomType[] = [
  {
    title: 'Arbeitsbereich',
    cover: 'img/roomsType/room1.png',
    isAvailable: true,
  },
  {
    title: 'Meeting',
    cover: 'img/roomsType/room3.png',
    isAvailable: true,
  },
  {
    title: 'Community',
    cover: 'img/roomsType/room4.png',
    isAvailable: false,
  },
  {
    title: 'Lounge',
    cover: 'img/roomsType/room5.png',
    isAvailable: false,
  },
];

export const RoomsType: React.FC = () => {
  const renderCardRoomType = ({ title, cover, isAvailable }: ICardRoomType, index: number) => (
    <CardRoomType key={index} isAvailable={isAvailable}>
      <RoomTitle>{title}</RoomTitle>
      <ImageWrapper>
        <Image src={cover} alt={title} />
      </ImageWrapper>
      {!isAvailable && <StatusPanel>Coming soon</StatusPanel>}
    </CardRoomType>
  );

  return (
    <>
      <BlockTitle>Die Räume</BlockTitle>
      <List>{roomsType.map(renderCardRoomType)}</List>
    </>
  );
};

import React from 'react';
import * as Yup from 'yup';

import { Form, FormButton, FormFooter, FormInput, FormLayout } from '../../../lib/components/Form';

import { Success, Image, Description } from './SubscriptionNews.styled';

import newsletterJpg from './assets/newsletter.jpg';
import { requiredString } from '../../utils/yup-reductions';

export interface ISubscriptionNews {
  name: string;
  email: string;
}

export const SubscriptionNews: React.FC = () => {
  const [isSuccess, setFormSuccess] = React.useState(false);

  const onSubmit = () => {
    // TODO API integration
    return Promise.resolve();
  };

  const afterSuccess = () => {
    setFormSuccess(true);
  };

  const renderFormSubscription = () => (
    <Form
      onSubmit={onSubmit}
      afterSubmit={afterSuccess}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Description>Bleiben Sie auf dem Laufenden und melden Sie sich für unseren exklusiven Newsletter an.</Description>
      <FormLayout>
        <FormInput type="text" name="name" label="Name" autoFocusOnEmpty={true} />
        <FormInput type="email" name="email" label="E-Mail" />
        <FormFooter>
          <FormButton type="submit" variant="contained" color="bronze">
            Anmelden
          </FormButton>
        </FormFooter>
      </FormLayout>
    </Form>
  );

  const renderFormSuccess = () => (
    <Success>
      <Image src={newsletterJpg} />
      <Description center={true}>
        Vielen Dank für Ihre Anmeldung für unserem Newsletter. Sie erhalten eine Anmeldebestätigung per E-Mail.
      </Description>
    </Success>
  );

  return isSuccess ? renderFormSuccess() : renderFormSubscription();
};

const initialValues = {
  name: '',
  email: '',
};

const validationSchema = Yup.object().shape<ISubscriptionNews>({
  name: requiredString('Bitte geben Sie Ihren Vornamen ein.'),
  email: requiredString('Dieses Feld darf nicht leer sein.').email('E-Mail-Adresse falsch'),
});

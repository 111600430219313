import { colors, h4, styled } from '../../../../lib/themes';

export const Root = styled.div`
  display: flex;
`;

export const List = styled.ul`
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  list-style-type: none;
`;

export const Benefit = styled.li`
  width: calc(50% - 38px);
  margin-left: 38px;
  display: flex;
  align-items: center;

  &:not(:nth-last-child(-n + 2)) {
    margin-bottom: 38px;
  }
`;

export const Subtitle = styled.h3`
  ${h4};
  margin-right: 100px;
  color: ${colors.black};
`;

export const Item = styled.div`
  position: relative;
  padding-left: 35px;
  display: flex;
  align-items: center;

  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 2px;
    height: 45px;
    content: '';
    background-color: ${colors.gray};
    transform: translateY(-50%);
  }
`;

export const Icon = styled.div`
  width: 36px;
  height: 36px;
  flex: 0 0 auto;
`;

export const Text = styled.p`
  margin-left: 40px;
  font-size: 20px;
  line-height: 30px;
  color: ${colors.black};
`;

// Temp image style, because we don't svg icon
export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

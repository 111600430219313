import React, { Component, ReactNode } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import {
  Swiper,
  SwiperOptions,
  Autoplay,
  Keyboard,
  Pagination,
  Navigation,
  EffectFade,
} from 'swiper/dist/js/swiper.esm';

import { PrevButton, NextButton } from '../../icons';

import 'swiper/dist/css/swiper.css';

import { ISliderStyledProps, SliderStyled } from './Slider.styled';

Swiper.use([Autoplay, Keyboard, Pagination, Navigation, EffectFade]);

export interface ISlider extends SwiperOptions, Partial<ISliderStyledProps> {
  children?: ReactNode;
  forwardedRef?: any;
  className?: any;
  thumbs?: any;
  pagination?: any;
  isNavigation?: boolean;
}

class SliderInner extends Component<ISlider> {
  private swiper: Swiper;

  componentDidMount() {
    window.requestAnimationFrame(this.init);
  }

  componentWillUnmount() {
    this.destroy();
  }

  private init = () => {
    const { children, forwardedRef, className, thumbs, isNavigation, ...rest } = this.props;

    const navigation = isNavigation
      ? {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }
      : {};

    const newThumbs = thumbs ? { swiper: thumbs.current.swiper } : {};
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore due to outdated swiper.esm.d.ts
    this.swiper = new Swiper(forwardedRef.current, {
      ...rest,
      navigation,
      thumbs: newThumbs,
    });
  };

  private destroy = () => {
    this.swiper?.destroy(true, true);
  };

  renderSlide = (element, id) => {
    return (
      <div className="swiper-slide" key={id} data-swiper-autoplay={element.props['data-swiper-autoplay']}>
        {element}
      </div>
    );
  };

  render() {
    const {
      forwardedRef,
      children,
      bulletsColor = 'bronze',
      className = '',
      isNavigation,
      navigationColor = 'white',
    } = this.props;

    return (
      <SliderStyled bulletsColor={bulletsColor} navigationColor={navigationColor} className={className}>
        <div ref={forwardedRef} className={'swiper-container'}>
          <div className="swiper-wrapper">{React.Children.map(children, this.renderSlide)}</div>
          {isNavigation && (
            <div className="swiper-button-prev">
              <PrevButton />
            </div>
          )}
          {isNavigation && (
            <div className="swiper-button-next">
              <NextButton />
            </div>
          )}
          <div className="swiper-pagination" />
        </div>
      </SliderStyled>
    );
  }
}

export const Slider = React.forwardRef<any, ISlider>((props, ref) => {
  return <SliderInner {...props} forwardedRef={ref} />;
});

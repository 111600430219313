import * as numeral from 'numeral';
import 'numeral/locales/de';

numeral.locale('de');

export function formatNumber(value: string | number): string {
  return numeral(value).format('0.0');
}

export function formatCurrency(value: string | number): string {
  return numeral(value).format('0,0.00 $');
}

export function formatCurrencyWithoutZero(value: string | number): string {
  return numeral(value).format('0,0 $');
}

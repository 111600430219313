import React, { useCallback } from 'react';
import { useField } from 'formik';

import { ImageCardsScreen } from '../containers/ImageCardScreen';
import { ImageCardWithColors } from '../containers/ImageCardScreen/components/ImageCardWithColors';
import { colorSchemes, roomTypes } from '../../../../fixtures';

interface ColorSchemesScreenProps {
  onChange: () => void;
  onBack: () => void;
}

export const ColorSchemesScreen: React.FC<ColorSchemesScreenProps> = ({ onChange, onBack }) => {
  const [roomTypeField] = useField<string>('roomTypeId');

  const imageCardsGenerator = useCallback(
    () =>
      colorSchemes.map((card, index) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const roomTypeUrn = roomTypes.find(({ id }) => roomTypeField.value === id)!.urn;

        const { id, cover, name, colors } = card;

        return {
          value: id,
          // eslint-disable-next-line react/display-name
          component: ({ onClick, isActive }) => (
            <ImageCardWithColors
              name={name}
              cover={cover[roomTypeUrn]}
              isActive={isActive}
              colors={colors}
              onClick={onClick}
            />
          ),
        };
      }),
    [roomTypeField.value],
  );

  return (
    <ImageCardsScreen
      title="2.2 In welchen Farben fühlen Sie sich wohl?"
      fieldName="colorSchemeId"
      imageCardsGenerator={imageCardsGenerator}
      onChange={onChange}
      onBack={onBack}
    />
  );
};

import React from 'react';
import { render } from 'react-dom';
import { configure } from 'mobx';
import config from 'config';
import { App } from './modules/core/Application';
import { initializeMainStore } from './app/initialize-stores';
import { createMainContext } from './lib/core/hooks/main-context';

// kick off the polyfills!
import { loadPolyfills } from './lib/utils/polyfills';

const lang = window.location.pathname.split('/')[1];
const { languages, apiServer } = config;
const defaultLanguage = languages[0];
const currentLocale = languages.includes(lang) ? lang : defaultLanguage;

configure({
  enforceActions: 'observed',
});

// We don't need context provider for this default value
createMainContext(initializeMainStore(`${apiServer}/graphql`));

const startApp = () => {
  render(<App />, document.getElementById('app'));
};

loadPolyfills().then(startApp);

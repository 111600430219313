import React from 'react';
import { planTypeCards } from '../../../fixtures';
import { device } from '../../../../../lib/misc/device';
import { Container, Form, FormError, PageSection } from '../../../../../lib/components';
import { Typography } from '../../../../../lib/themes/typography/Typography';
import { MutationUpdateRoomArgs } from '../../../../../lib/core/api/generated';
import { AllRoomSteps } from '../../../typesUtils';
import { useUpdateRoom } from '../../../../../lib/core/repositories/room-repository';
import { PlanTypeCard } from '../../../components/PlanTypeCard';
import { Cards } from './PlanTypePage.styled';
import { IConfiguratorParamsPagesProps } from '../ConfiguratorParamsPagesProps';

export const PlanTypePage: React.FC<IConfiguratorParamsPagesProps> = ({ room, step, changeStep }) => {
  const { isTablet } = device;

  const [updateRoom] = useUpdateRoom();
  const initialValues: MutationUpdateRoomArgs = {
    id: room.id,
    planMode: room.planMode,
  };

  // Next step is always style
  const afterSubmit = React.useCallback(() => {
    changeStep(AllRoomSteps.RoomStyle.step);
  }, [changeStep]);

  return (
    <Form onSubmit={updateRoom} initialValues={initialValues} afterSubmit={afterSubmit}>
      <PageSection>
        <Container size={isTablet ? 'tablet' : 'desktop'}>
          <Typography
            data-hook-text={'PlanType'}
            tag="h1"
            variant="h2"
            css={`
              margin-bottom: 60px;
            `}
          >
            Wie dürfen wir Sie unterstützen?
          </Typography>

          <Typography
            tag="h2"
            variant="h3"
            css={`
              margin-bottom: 50px;
            `}
          >
            Wählen Sie Ihre Planungsart
          </Typography>
          <Cards>
            {planTypeCards.map(card => (
              <PlanTypeCard card={card} key={card.id} autoSubmit={true} />
            ))}
          </Cards>
          <FormError />
        </Container>
      </PageSection>
    </Form>
  );
};

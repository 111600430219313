import { colors, styled, h3, css } from '../../../../lib/themes';

export const List = styled.ul`
  list-style-type: none;
  display: flex;
  margin-left: -30px;
  padding-bottom: 40px;
  overflow-x: auto;
`;

export const ImageWrapper = styled.div``;

export const Image = styled.img`
  object-fit: cover;
  display: block;
  width: 255px;
  height: 255px;
  border-radius: 4px;
  overflow: hidden;
`;

export const CardRoomType = styled.li<{ isAvailable: boolean }>`
  margin-left: 30px;
  width: 255px;

  ${({ isAvailable }) =>
    !isAvailable &&
    css`
      ${ImageWrapper} {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    `}
`;

export const RoomTitle = styled.h3`
  ${h3};
  color: ${colors.black};
  margin-bottom: 56px;
`;

export const StatusPanel = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -30px auto 0;
  width: 220px;
  height: 60px;
  border-radius: 2px;
  font-size: 18px;
  color: ${colors.grayDark};
  background-color: ${colors.white};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
`;

import React from 'react';
import { useHistory, useParams } from 'react-router';

import { Summary } from '../../../../components/Summary';
import { ProductsTable } from '../../../../components/ProductsTable';
import { Room, RoomPlanMode, RoomStatus, ApprovalStatus } from '../../../../../../lib/core/api/generated';
import { Container } from '../../../../../../lib/components/Container';
import { FlexContainer, Form, InvitationWindow } from '../../../../../../lib/components';
import { TotalPanel } from '../../../../components/TotalPanel';
import { ShareIcon } from '../../../../../../lib/icons';
import {
  useApproveRoom,
  useGenerateRoomPDF,
  useOrderRoom,
} from '../../../../../../lib/core/repositories/room-repository';
import { useMe } from '../../../../../../lib/core/repositories/user-repository';
import { RoomItemsStep } from '../../../../typesUtils';
import { formatCurrency } from '../../../../../../lib/utils/format-number';
import { ConfiguratorPageFooter } from '../../../../components/ConfiguratorPageFooter';
import { noop } from '../../../../../../lib/utils/placeholders';

import { useIsInvitedUser } from '../../../../../../lib/core/hooks/useIsInvitedUser';
import { useSnackBar } from '../../../../../../lib/components/SnackBar';

import {
  ContentItem,
  ProductOrCost,
} from '../../../../components/ConfiguratorPageFooter/ConfiguratorPageFooter.styled';
import { GqlErrors } from '../../../../../../lib/core/errors/gql-error';

import { DownloadButton, DownloadIconStyled, ShareButton, Wrap, Root } from './SummaryScreen.styled';

interface SummaryScreenProps {
  room: Room;
  changeScreen: (screen: number) => unknown;
}

export const SummaryScreen: React.FC<SummaryScreenProps> = ({ room }) => {
  const [isModalOpen, setOpenModal] = React.useState<boolean>(false);
  const [isDownloadLoading, setDownloadLoading] = React.useState<boolean>(false);
  const isInvitedUser = useIsInvitedUser();
  const [generateRoomPDF] = useGenerateRoomPDF();
  const [submitOrderRoom] = useOrderRoom();
  const [approveRoom] = useApproveRoom();
  const [user] = useMe();
  const history = useHistory();
  const { showAlert, showSuccess } = useSnackBar();
  const { accountId } = useParams<{ accountId: string }>();
  const isRoomOrdered = room.roomStatus === RoomStatus.Ordered;

  const openModal = React.useCallback(() => setOpenModal(true), []);
  const closeModal = React.useCallback(() => setOpenModal(false), []);
  const statusApprovalRoom =
    room.approvals && room.approvals.find(userRoom => user?.id === userRoom?.userId)?.approvalStatus;

  const isApprovedRoom = statusApprovalRoom && statusApprovalRoom === ApprovalStatus.Approved;

  const handleSubmit = React.useCallback(() => {
    if (isInvitedUser) {
      // TODO add handler from success approveRoom
      return approveRoom({ roomId: room.id }).then(() => showSuccess('Freigeben gesendet'));
    }

    return submitOrderRoom(room.id)
      .then(() => history.push(`/success-order/${accountId}/${room.projectId}`))
      .catch(error => {
        window.scrollTo({ top: 0, left: 0 });
        throw error;
      });
  }, [isInvitedUser, submitOrderRoom, room.id, room.projectId, approveRoom, showSuccess, history, accountId]);

  const handleDownloadButtonClick = React.useCallback(() => {
    setDownloadLoading(true);
    const errorMessage = 'Es ist leider ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal';
    generateRoomPDF({ roomId: room.id })
      .then(response => {
        if (response.uri) {
          window.open(response.uri, '_blank');
        } else {
          showAlert(errorMessage);
        }
      })
      .catch(() => {
        showAlert(errorMessage);
      })
      .finally(() => {
        setDownloadLoading(false);
      });
  }, [generateRoomPDF, room.id, showAlert]);

  const roomAmount = room.items.reduce((acc, item) => acc + item.amount, 0);
  const roomTotal = room.items.reduce((acc, item) => acc + item.amount * (item.productConfiguration.price || 0), 0);

  const isEditable: boolean = isInvitedUser
    ? false
    : (true || room.planMode === RoomPlanMode.LetMePlan) && room.roomStatus !== RoomStatus.Ordered;

  const handleOnError = React.useCallback(
    hr => {
      showAlert(hr);
    },
    [showAlert],
  );

  return (
    <Root>
      <Container>
        <FlexContainer
          css={`
            padding-top: 46px;
          `}
        >
          <FlexContainer
            direction="column"
            css={`
              margin-right: 58px;
            `}
          >
            <Summary room={room} />
            {room.items.length > 0 && (
              <ProductsTable items={room.items} isEditable={isEditable} isAmountEditable={isEditable} />
            )}
          </FlexContainer>
          <FlexContainer
            direction={'column'}
            css={`
              width: auto;
            `}
          >
            <Wrap>
              <TotalPanel room={room} isOrdered={isRoomOrdered} />
              <FlexContainer
                direction={'column'}
                css={`
                  width: 254px;
                  margin: 39px;
                  & > * {
                    margin-bottom: 22px;
                  }
                `}
              >
                {!isInvitedUser && (
                  <ShareButton onClick={openModal}>
                    <ShareIcon /> Freigabe
                  </ShareButton>
                )}
                <DownloadButton
                  variant="contained"
                  size="medium"
                  color="bronze"
                  onClick={handleDownloadButtonClick}
                  isLoading={isDownloadLoading}
                >
                  <DownloadIconStyled />
                  Download
                </DownloadButton>
              </FlexContainer>
            </Wrap>
          </FlexContainer>
        </FlexContainer>
      </Container>

      <InvitationWindow isOpened={isModalOpen} roomId={room.id} onClose={closeModal} />

      <Form
        onSubmit={handleSubmit}
        initialValues={{}}
        onError={handleOnError}
        errorsHR={[[GqlErrors.NO_APPROVAL, 'No Approval room']]}
      >
        <ConfiguratorPageFooter
          step={RoomItemsStep.RoomSummary}
          changeStep={noop}
          // prevStep={AllRoomSteps.RoomSummary}
          submitTitle={isRoomOrdered || isApprovedRoom ? undefined : isInvitedUser ? 'Freigeben' : 'Bestellen'}
        >
          <ContentItem>
            <ProductOrCost>{roomAmount} Produkte</ProductOrCost>
            <ProductOrCost>{formatCurrency(roomTotal)}</ProductOrCost>
          </ContentItem>
        </ConfiguratorPageFooter>
      </Form>
    </Root>
  );
};

import React from 'react';

import { TUrl } from '../../../types';
import { ImageCardStyled, ImageCardCover, ImageCardContent } from './ImageCard.styled';

interface IImageCardProps {
  cover: TUrl;
  onClick?: () => void;
  className?: string;
}

export const ImageCard: React.FC<IImageCardProps> = ({ cover, children, onClick, className }) => (
  <ImageCardStyled onClick={onClick} data-hook-type={'ImageCard'} className={className}>
    <ImageCardCover src={cover} aria-label={'picture'} />
    <ImageCardContent>{children}</ImageCardContent>
  </ImageCardStyled>
);

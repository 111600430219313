import { styled, colors, h4, body } from '../../../../lib/themes';
import { AccountIcon } from '../../../../lib/icons';

export const BecomeMemberStyled = styled.div`
  padding-left: 70px;
  padding-right: 95px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 218px;
  background-color: ${colors.grayLight};
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AccountIconStyled = styled(AccountIcon)`
  margin-right: 56px;
  width: 96px;
  height: 96px;
`;

export const Info = styled.div``;

export const Title = styled.h3`
  ${h4};
  margin-bottom: 17px;
`;

export const Description = styled.p`
  ${body};
`;

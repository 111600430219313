import { colors, applyCssIf, css, styled, h5, helper } from '../../themes';

const transitionStyles = css`
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
`;

const topOffset = '25px';

export const Field = styled.div`
  position: relative;
  margin-top: ${topOffset};
  width: 100%;
  cursor: text;
`;

export const Label = styled.label`
  position: absolute;
  top: -${topOffset};
  left: 0;
  font-size: 15px;
  line-height: 1.07;
  color: ${colors.bronze};
  transition-property: top, color, font-size, line-height;
  ${transitionStyles};
`;

export const Error = styled.span`
  ${helper};
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -2px;
  transform: translateY(100%);
  color: ${colors.red};
  transition-property: opacity;
  ${transitionStyles};
`;

export const InputStyled = styled.input<{ isValid: boolean }>`
  ${h5};
  padding: 0 0 6px;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  border-bottom: 1px solid ${colors.grayLighter};
  transition-property: border-color, color;
  ${transitionStyles};

  &::placeholder {
    visibility: hidden;
  }

  &[value=''] {
    & + ${Label} {
      top: 0;
      font-size: 20px;
      line-height: 1.5;
      color: ${colors.grayMedium};
    }
  }

  &:focus {
    border-bottom-color: ${colors.bronze};

    & + ${Label} {
      top: -${topOffset};
      font-size: 15px;
      line-height: 1.07;
      color: ${colors.bronze};
    }
  }

  ${({ isValid }) =>
    applyCssIf(!isValid)(css`
      border-color: ${colors.red};

      & + ${Label} {
        color: ${colors.red};
      }

      &:focus {
        & ~ ${Error} {
          opacity: 0;
        }
      }
    `)};

  &.default-placeholder {
    &::placeholder {
      visibility: visible;
      opacity: 1;
      color: ${colors.gray};
    }
  }
`;

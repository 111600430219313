import { MainStore } from '../lib/core/main-store';
import { RequireAllStores, UserStoredSettings } from '../lib/core/available-stores';
import { ApolloStore } from '../lib/core/other-stores/apollo-store';
import { AuthRepository } from '../lib/core/repositories/auth-repository';
import { RegisterRepository } from '../lib/core/repositories/register-repository';
import { UserStorageStore } from '../lib/core/other-stores/user-storage-store';
import { CommonStorageStore } from '../lib/core/other-stores/common-storage-store';
import { RoomRepository } from '../lib/core/repositories/room-repository';
import { Log } from '../lib/core/other-stores/log';
import { CommonStoredData } from '../lib/core/common-store-settings';
import { UserRepository } from '../lib/core/repositories/user-repository';
import { ProjectRepository } from '../lib/core/repositories/project-repository';
import { AccountRepository } from '../lib/core/repositories/account-repository';
import { RoomTypeRepository } from '../lib/core/repositories/room-type-repository';
import { RoomPackagesRepository } from '../lib/core/repositories/room-packages-repository';
import { RoomDesignRepository } from '../lib/core/repositories/room-design-repository';
import { FloorTypeRepository } from '../lib/core/repositories/floor-type-repository';
import { ColorSchemeRepository } from '../lib/core/repositories/color-scheme-repository';
import { WorkUnitsRepository } from '../lib/core/repositories/work-units-repository';
import { WorkSettingRepository } from '../lib/core/repositories/work-setting-repository';
import { FurnitureTypeRepository } from '../lib/core/repositories/furniture-type-repository';
import { CategoryRepository } from '../lib/core/repositories/category-repository';
import { SearchProductsRepository } from '../lib/core/repositories/search-products-repository';

/**
 * Helper to initialize DI
 * @param {string} uri
 * @return {MainStore<RequireAllStores>}
 */
export function initializeMainStore(uri: string) {
  // TODO remove ts-ignore
  // @ts-ignore
  const mainStore = new MainStore<RequireAllStores>();
  // Storage stores MUST be first, because other stores depends on them
  new UserStorageStore<UserStoredSettings>(mainStore);
  new CommonStorageStore<CommonStoredData>(mainStore);

  new ApolloStore(mainStore, uri);
  new AuthRepository(mainStore);
  new RegisterRepository(mainStore);
  new RoomRepository(mainStore);
  new ProjectRepository(mainStore);
  new UserRepository(mainStore);
  new AccountRepository(mainStore);
  new RoomTypeRepository(mainStore);
  new RoomDesignRepository(mainStore);
  new FloorTypeRepository(mainStore);
  new ColorSchemeRepository(mainStore);
  new WorkUnitsRepository(mainStore);
  new WorkSettingRepository(mainStore);
  new FurnitureTypeRepository(mainStore);
  new RoomPackagesRepository(mainStore);
  new CategoryRepository(mainStore);
  new SearchProductsRepository(mainStore);

  new Log(mainStore, false); // TODO: Replace with NODE_ENV variable from webpack
  return mainStore;
}

import { action, computed, observable } from 'mobx';
import detectIt from 'detect-it';

export class Device {
  mediaQuerySmall = window.matchMedia('(max-width: 767px)');
  mediaQueryTabletSmall = window.matchMedia('(max-width: 1023px)');
  mediaQueryTablet = window.matchMedia('(max-width: 1199px)');
  mediaQueryDesktop = window.matchMedia('(max-width: 1399px)');

  @observable
  isSmallScreen: boolean;

  @observable
  isTableScreen: boolean;

  @observable
  isTableSmallScreen: boolean;

  @observable
  isDesktopScreen: boolean;

  constructor() {
    this.isSmallScreen = this.mediaQuerySmall.matches;
    this.isTableSmallScreen = this.mediaQueryTabletSmall.matches;
    this.isTableScreen = this.mediaQueryTablet.matches;
    this.isDesktopScreen = this.mediaQueryDesktop.matches;

    this.mediaQuerySmall.addListener(
      action(() => {
        this.isSmallScreen = this.mediaQuerySmall.matches;
      }),
    );

    this.mediaQueryTablet.addListener(
      action(() => {
        this.isTableScreen = this.mediaQueryTablet.matches;
      }),
    );

    this.mediaQueryTabletSmall.addListener(
      action(() => {
        this.isTableSmallScreen = this.mediaQueryTabletSmall.matches;
      }),
    );
    this.mediaQueryDesktop.addListener(
      action(() => {
        this.isDesktopScreen = this.mediaQueryDesktop.matches;
      }),
    );
  }

  public get isSynthetic(): boolean {
    return Boolean((window as any).callPhantom) || Boolean(window.navigator.webdriver);
  }

  public get isTouch(): boolean {
    return !this.isSynthetic && (this.isSmallScreen || detectIt.primaryInput === 'touch');
  }

  @computed
  public get isMobile(): boolean {
    return this.isTouch || this.isSmallScreen;
  }

  @computed
  public get isPhone(): boolean {
    return this.isTouch && this.isSmallScreen;
  }

  @computed
  public get isTablet(): boolean {
    return this.isTableScreen;
  }

  @computed
  public get isTabletSmall(): boolean {
    return this.isTableSmallScreen;
  }

  @computed
  public get isDesktop(): boolean {
    return this.isDesktopScreen;
  }
}

export const device: Device = new Device();

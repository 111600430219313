import { styled } from '../../../../../../lib/themes';
import { Button, IButtonProps } from '../../../../../../lib/components/Button';
import { IStyledButtonProps } from '../../../../../../lib/components/Button/Button.styled';
import { DownloadIcon } from '../../../../../../lib/icons';

export const Root = styled.div``;

export const ShareButton = styled(Button)<IButtonProps>``;

export const DownloadButton = styled(Button)<IStyledButtonProps>`
  width: 255px;
`;

export const DownloadIconStyled = styled(DownloadIcon)`
  margin-right: 16px;
`;

export const Wrap = styled.div`
  position: sticky;
  top: 174px;
`;

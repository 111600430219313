import React from 'react';
import { IPlanTypeCard } from './components';
import { ComputerIcon, HouseIcon } from '../../lib/icons';
import { RoomPlanMode } from '../../lib/core/api/generated';

export const planTypeCards: IPlanTypeCard[] = [
  {
    id: RoomPlanMode.PlanForMe,
    title: 'Designplanung<br/>“plan for me”',
    description:
      'Lassen Sie uns die Arbeit für Sie machen und lehnen Sie sich zurück. Wir stellen anhand Ihrer Vorgaben und Anforderungen das passende Interieur im ausgewählten Stil für Sie zusammen.',
    icon: <ComputerIcon />,
  },
  {
    id: RoomPlanMode.LetMePlan,
    title: 'Eigenständige Planung<br/> “let me plan”',
    description:
      'Sie wissen ganz genau, was Sie wollen? Machen Sie ihre eigene Planung und stellen Sie Ihre Produkte anhand Ihrer Bedürfnisse zusammen. Wir zeigen Ihnen dazu alle passenden Produkte im gewählten Stil.',
    icon: <HouseIcon />,
  },
];

import React from 'react';

import { PageHeaderNormal, Button } from '../../lib/components';

import { NotFoundStyled, Content, Title, Description, Buttons } from './NotFound.styled';
import { useStore } from '../../lib/core/hooks/use-store';
import { RequireAuthRepository } from '../../lib/core/available-stores';
import { AuthFormWindow } from '../../modules/auth/containers/AuthFormWindow';

export const NotFound: React.FC = () => {
  const authRepository = useStore<RequireAuthRepository>('AuthRepository');
  const [showAuthFormWindow, setShowAuthFormWindow] = React.useState(false);

  const handleShowAuthFormWindow = React.useCallback(() => {
    setShowAuthFormWindow(true);
  }, [setShowAuthFormWindow]);

  const handleCloseAuthFormWindow = React.useCallback(() => {
    setShowAuthFormWindow(false);
  }, [setShowAuthFormWindow]);

  return (
    <NotFoundStyled>
      <PageHeaderNormal />
      <Content>
        <Title>
          Entschuldigung, etwas
          <br />
          ist schief gelaufen
        </Title>
        <Description>
          Die Seite wurde leider <br />
          nicht gefunden
        </Description>

        <Buttons>
          <Button variant="outlined" size="large" color="bronze" href="/">
            Zur Startseite
          </Button>
          {!authRepository.hasAuth && (
            <Button variant="contained" size="large" color="bronze" onClick={handleShowAuthFormWindow}>
              Zum Login
            </Button>
          )}
        </Buttons>
      </Content>
      {showAuthFormWindow && <AuthFormWindow onClose={handleCloseAuthFormWindow} />}
    </NotFoundStyled>
  );
};

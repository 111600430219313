import React, { HTMLAttributes } from 'react';

import * as typographyVariants from './typography.styled';
import { colors, styled, css } from '../index';
import { Color } from '../colors';

export interface ITypographyProps {
  tag: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'small' | 'strong';
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'body' | 'button' | 'helper';
  color?: Color;
  className?: string;
}

const StyledTypography = styled.span<{ variant: string; color?: Color }>`
  ${({ variant }) => typographyVariants[variant]};
  ${({ color }) =>
    color &&
    css`
      color: ${colors[color]};
    `}
`;

export const Typography: React.FC<ITypographyProps & HTMLAttributes<HTMLSpanElement>> = ({
  tag = 'h1',
  variant = 'h1',
  className,
  color,
  children,
  ...rest
}) => {
  return (
    <StyledTypography as={tag} variant={variant} color={color} className={className} {...rest}>
      {children}
    </StyledTypography>
  );
};

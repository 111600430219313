import { styled, colors, h5 } from '../../../themes';

export const StyledFormError = styled.div`
  ${h5}
  color: ${colors.red};
  
  margin-bottom: 14px;
  padding: 24px 34px;
  border: 1px solid ${colors.red};
`;

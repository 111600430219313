export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  roomLimit: Scalars['Int'];
  projects: Array<Project>;
  companyImageUrl?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['String']>;
};

export type AcousticPanel = {
  __typename?: 'AcousticPanel';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  street: Scalars['String'];
  city: Scalars['String'];
  postcode: Scalars['String'];
  telephone: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  isDefaultShipping?: Maybe<Scalars['Boolean']>;
  isDefaultBilling?: Maybe<Scalars['Boolean']>;
  deliveryZoneTypeSelect: AddressSelectField;
  restrictedParkingSelect: AddressSelectField;
  truckParkingAvailableSelect: AddressSelectField;
  restrictedParkingComment: Scalars['String'];
  elevatorSelect: AddressSelectField;
  elevatorMeasurements: Scalars['String'];
  additionalComments: Scalars['String'];
  floor: Scalars['String'];
};

export enum AddressAttributeCode {
  DeliveryZoneTypeSelect = 'deliveryZoneTypeSelect',
  RestrictedParkingSelect = 'restrictedParkingSelect',
  TruckParkingAvailableSelect = 'truckParkingAvailableSelect',
  ElevatorSelect = 'elevatorSelect',
}

export type AddressSelectField = {
  __typename?: 'AddressSelectField';
  value: Scalars['String'];
  options: Array<AddressSelectFieldOption>;
};

export type AddressSelectFieldOption = {
  __typename?: 'AddressSelectFieldOption';
  value: Scalars['String'];
  label: Scalars['String'];
};

export type Approval = {
  __typename?: 'Approval';
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
  invitationMail?: Maybe<Scalars['String']>;
  approvalStatus?: Maybe<ApprovalStatus>;
};

export enum ApprovalStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
}

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
}

export type Category = {
  __typename?: 'Category';
  id: Scalars['String'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  parent?: Maybe<Category>;
  childrenIds: Array<Scalars['String']>;
  children: Array<Category>;
};

export type CeilingType = {
  __typename?: 'CeilingType';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ChairRoll = {
  __typename?: 'ChairRoll';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Color = {
  __typename?: 'Color';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ColorScheme = {
  __typename?: 'ColorScheme';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ConfigurableProduct = {
  __typename?: 'ConfigurableProduct';
  sku: Scalars['String'];
  name: Scalars['String'];
  productConfigurationSkus: Array<Scalars['String']>;
  productConfigurations: Array<ProductConfiguration>;
};

export type ConfirmResponse = {
  __typename?: 'ConfirmResponse';
  status: ConfirmStatus;
};

export enum ConfirmStatus {
  Ok = 'OK',
  Unconfirmed = 'UNCONFIRMED',
}

export type DeliveryType = {
  __typename?: 'DeliveryType';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type File = {
  __typename?: 'File';
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
};

export type FileResponse = {
  __typename?: 'FileResponse';
  uri?: Maybe<Scalars['String']>;
};

export type FloorType = {
  __typename?: 'FloorType';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type FurnitureType = {
  __typename?: 'FurnitureType';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type Grant = {
  __typename?: 'Grant';
  accessToken: Scalars['String'];
  idToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type InitialRoomItem = {
  __typename?: 'InitialRoomItem';
  productConfigurationSku: Scalars['String'];
  productConfiguration: ProductConfiguration;
  productSetId?: Maybe<Scalars['String']>;
  productSet?: Maybe<ProductSet>;
  amount: Scalars['Int'];
};

export type Manufacturer = {
  __typename?: 'Manufacturer';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Material = {
  __typename?: 'Material';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Membership = {
  __typename?: 'Membership';
  account: Account;
  role: Scalars['String'];
};

export type Moodboard = {
  __typename?: 'Moodboard';
  sku: Scalars['String'];
  images: Array<MoodboardImage>;
};

export type MoodboardImage = {
  __typename?: 'MoodboardImage';
  url: Scalars['String'];
  size: Array<Scalars['Int']>;
  descriptions: Array<MoodboardImageDescription>;
};

export type MoodboardImageDescription = {
  __typename?: 'MoodboardImageDescription';
  coordinates: Array<Scalars['Int']>;
  contentType: Scalars['String'];
  contentHtml: Scalars['String'];
  contentText: Scalars['String'];
  style: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  register: User;
  confirmMail: ConfirmResponse;
  login: Grant;
  refresh: Grant;
  logout: Scalars['Boolean'];
  createProject: Project;
  createRoom: Room;
  deleteRoom: Scalars['Boolean'];
  deleteProject: Scalars['Boolean'];
  updateRoom: Room;
  orderRoom: Scalars['Boolean'];
  orderProject: Scalars['Boolean'];
  updateProject: Project;
  planForMe: Scalars['Boolean'];
  letMePlan: Scalars['Boolean'];
  planInitialRoomItems: Scalars['Boolean'];
  useInitialRoomItems: Array<RoomItem>;
  createRoomItem: RoomItem;
  removeRoomItem: Scalars['Boolean'];
  updateRoomItem: RoomItem;
  addRoomItem: RoomItem;
  dropRoomItem: RoomItem;
  addAllRoomItems: Array<RoomItem>;
  generateRoomPDF: FileResponse;
  generateProjectPDF: FileResponse;
  purchaseRoomPackage: PurchaseResponse;
  completePaypalRoomPackagePurchase: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  completePasswordReset: Scalars['Boolean'];
  updateAccount: Account;
  updatePassword: Scalars['Boolean'];
  inviteExecutiveForRoom: Scalars['Boolean'];
  approveRoom: Room;
  updateEmail: User;
  updateSubscribeToNewsletter: Scalars['Boolean'];
  updateAddress: User;
  updateUser: User;
  updateUserAsAdmin: User;
  saveRoom: Room;
  importEvent: Scalars['Boolean'];
};

export type MutationRegisterArgs = {
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  inviteToken?: Maybe<Scalars['String']>;
};

export type MutationConfirmMailArgs = {
  token: Scalars['String'];
};

export type MutationLoginArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type MutationRefreshArgs = {
  refreshToken: Scalars['String'];
  accessToken?: Maybe<Scalars['String']>;
};

export type MutationLogoutArgs = {
  refreshToken: Scalars['String'];
};

export type MutationCreateProjectArgs = {
  name: Scalars['String'];
  accountId: Scalars['String'];
};

export type MutationCreateRoomArgs = {
  name: Scalars['String'];
  projectId: Scalars['String'];
};

export type MutationDeleteRoomArgs = {
  roomId: Scalars['String'];
};

export type MutationDeleteProjectArgs = {
  projectId: Scalars['String'];
};

export type MutationUpdateRoomArgs = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  roomTypeId?: Maybe<Scalars['String']>;
  roomDesignId?: Maybe<Scalars['String']>;
  floorTypeId?: Maybe<Scalars['String']>;
  colorSchemeId?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  staticWorkstations?: Maybe<Scalars['Int']>;
  flexibleWorkstations?: Maybe<Scalars['Int']>;
  spaceRequired?: Maybe<Scalars['Boolean']>;
  storageRequired?: Maybe<Scalars['Boolean']>;
  soundproofingRequired?: Maybe<Scalars['Boolean']>;
  loungeRequired?: Maybe<Scalars['Boolean']>;
  furnitureTypeId?: Maybe<Scalars['String']>;
  workUnitId?: Maybe<Scalars['String']>;
  workSettingId?: Maybe<Scalars['String']>;
  planMode?: Maybe<RoomPlanMode>;
};

export type MutationOrderRoomArgs = {
  id: Scalars['String'];
};

export type MutationOrderProjectArgs = {
  projectId: Scalars['String'];
};

export type MutationUpdateProjectArgs = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type MutationPlanForMeArgs = {
  roomId: Scalars['String'];
};

export type MutationLetMePlanArgs = {
  roomId: Scalars['String'];
};

export type MutationPlanInitialRoomItemsArgs = {
  roomId: Scalars['String'];
};

export type MutationUseInitialRoomItemsArgs = {
  roomId: Scalars['String'];
};

export type MutationCreateRoomItemArgs = {
  roomId: Scalars['String'];
  productConfigurationSku: Scalars['String'];
  amount: Scalars['Int'];
  productSetId?: Maybe<Scalars['String']>;
};

export type MutationRemoveRoomItemArgs = {
  roomItemId: Scalars['String'];
};

export type MutationUpdateRoomItemArgs = {
  roomItemId: Scalars['String'];
  productConfigurationSku?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
};

export type MutationAddRoomItemArgs = {
  roomItemId: Scalars['String'];
};

export type MutationDropRoomItemArgs = {
  roomItemId: Scalars['String'];
};

export type MutationAddAllRoomItemsArgs = {
  roomId: Scalars['String'];
};

export type MutationGenerateRoomPdfArgs = {
  roomId: Scalars['String'];
};

export type MutationGenerateProjectPdfArgs = {
  projectId: Scalars['String'];
};

export type MutationPurchaseRoomPackageArgs = {
  accountId: Scalars['String'];
  sku: Scalars['String'];
  method: PaymentMethod;
  couponCode?: Maybe<Scalars['String']>;
};

export type MutationCompletePaypalRoomPackagePurchaseArgs = {
  accountId: Scalars['String'];
  paypalToken: Scalars['String'];
  paypalPayerId: Scalars['String'];
};

export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};

export type MutationCompletePasswordResetArgs = {
  token: Scalars['String'];
  password: Scalars['String'];
};

export type MutationUpdateAccountArgs = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  companyImage?: Maybe<Scalars['Upload']>;
};

export type MutationUpdatePasswordArgs = {
  pass: Scalars['String'];
  oldPass: Scalars['String'];
};

export type MutationInviteExecutiveForRoomArgs = {
  email: Scalars['String'];
  roomId: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
};

export type MutationApproveRoomArgs = {
  roomId: Scalars['String'];
};

export type MutationUpdateEmailArgs = {
  email: Scalars['String'];
};

export type MutationUpdateSubscribeToNewsletterArgs = {
  subscribeToNewsletter: Scalars['Boolean'];
};

export type MutationUpdateAddressArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  street: Scalars['String'];
  city: Scalars['String'];
  postcode: Scalars['String'];
  countryId: Scalars['String'];
  telephone: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  deliveryZoneTypeSelect?: Maybe<Scalars['String']>;
  restrictedParkingSelect?: Maybe<Scalars['String']>;
  truckParkingAvailableSelect?: Maybe<Scalars['String']>;
  restrictedParkingComment?: Maybe<Scalars['String']>;
  elevatorSelect?: Maybe<Scalars['String']>;
  elevatorMeasurements?: Maybe<Scalars['String']>;
  additionalComments?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
};

export type MutationUpdateUserArgs = {
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  vatId?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  subscribeToNewsletter?: Maybe<Scalars['Boolean']>;
  address?: Maybe<RoomShippingAddressInput>;
};

export type MutationUpdateUserAsAdminArgs = {
  id?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  vatId?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  subscribeToNewsletter?: Maybe<Scalars['Boolean']>;
  address?: Maybe<RoomShippingAddressInput>;
};

export type MutationSaveRoomArgs = {
  roomId: Scalars['String'];
  subscribeToNewsletter?: Maybe<Scalars['Boolean']>;
  contactMe?: Maybe<Scalars['Boolean']>;
  acceptTermsAndConditions?: Maybe<Scalars['Boolean']>;
  fastShipping?: Maybe<Scalars['Boolean']>;
  shippingAddress?: Maybe<RoomShippingAddressInput>;
  couponCode?: Maybe<Scalars['String']>;
};

export type MutationImportEventArgs = {
  eventName: Scalars['String'];
};

export enum PaymentMethod {
  Paypal = 'PAYPAL',
}

export type PaypalCheckoutData = {
  __typename?: 'PaypalCheckoutData';
  token: Scalars['String'];
  startUrl: Scalars['String'];
  editUrl: Scalars['String'];
};

export type Pong = {
  __typename?: 'Pong';
  time: Scalars['String'];
};

export type ProductConfiguration = {
  __typename?: 'ProductConfiguration';
  id?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  name: Scalars['String'];
  configurationName: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  imageUrls: Array<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  hint?: Maybe<Scalars['String']>;
  configurableProduct: ConfigurableProduct;
  width?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  heightAdjustable?: Maybe<Scalars['Boolean']>;
  heightMin?: Maybe<Scalars['Int']>;
  heightMax?: Maybe<Scalars['Int']>;
  assembly?: Maybe<Scalars['Boolean']>;
  hasCableFunnel?: Maybe<Scalars['Boolean']>;
  roomDesignId?: Maybe<Scalars['String']>;
  roomDesign?: Maybe<RoomDesign>;
  categoryIds: Array<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Category>>>;
  floorTypeIds: Array<Scalars['String']>;
  floorTypes?: Maybe<Array<Maybe<FloorType>>>;
  colorSchemeIds: Array<Scalars['String']>;
  colorSchemes?: Maybe<Array<Maybe<ColorScheme>>>;
  roomTypeIds: Array<Scalars['String']>;
  roomTypes?: Maybe<Array<Maybe<RoomType>>>;
  ceilingTypeIds: Array<Scalars['String']>;
  ceilingTypes?: Maybe<Array<Maybe<CeilingType>>>;
  manufacturerId?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Manufacturer>;
  color1Id?: Maybe<Scalars['String']>;
  color1?: Maybe<Color>;
  color2Id?: Maybe<Scalars['String']>;
  color2?: Maybe<Color>;
  material1Id?: Maybe<Scalars['String']>;
  material1?: Maybe<Material>;
  material2Id?: Maybe<Scalars['String']>;
  material2?: Maybe<Material>;
  productFamilyId?: Maybe<Scalars['String']>;
  productFamily?: Maybe<ProductFamily>;
  chairRollId?: Maybe<Scalars['String']>;
  chairRoll?: Maybe<ChairRoll>;
  acousticPanelId?: Maybe<Scalars['String']>;
  acousticPanel?: Maybe<AcousticPanel>;
  furnitureTypeId?: Maybe<Scalars['String']>;
  furnitureType?: Maybe<FurnitureType>;
  workUnitId?: Maybe<Scalars['String']>;
  workUnit?: Maybe<WorkUnit>;
  workSettingId?: Maybe<Scalars['String']>;
  workSetting?: Maybe<WorkSetting>;
};

export type ProductFamily = {
  __typename?: 'ProductFamily';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ProductPage = {
  __typename?: 'ProductPage';
  result: Array<ProductConfiguration>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
  count: Scalars['Int'];
  hasNext: Scalars['Boolean'];
};

export type ProductSet = {
  __typename?: 'ProductSet';
  id: Scalars['String'];
  productConfigurationSkus: Array<Scalars['String']>;
  productConfigurations: Array<ProductConfiguration>;
  categoryId?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
};

export type Project = {
  __typename?: 'Project';
  id: Scalars['String'];
  name: Scalars['String'];
  accountId: Scalars['String'];
  rooms: Array<Room>;
};

export type PurchaseResponse = {
  __typename?: 'PurchaseResponse';
  paypal?: Maybe<PaypalCheckoutData>;
};

export type Query = {
  __typename?: 'Query';
  ping: Pong;
  me: User;
  roomTypes: Array<RoomType>;
  ceilingTypes: Array<CeilingType>;
  roomDesigns: Array<RoomDesign>;
  floorTypes: Array<FloorType>;
  workSettings: Array<WorkSetting>;
  workUnits: Array<WorkUnit>;
  furnitureTypes: Array<FurnitureType>;
  colorSchemes: Array<ColorScheme>;
  project: Project;
  room: Room;
  initialRoomItems: Array<InitialRoomItem>;
  roomPackages: Array<RoomPackage>;
  account: Account;
  searchProducts?: Maybe<ProductPage>;
  categories: Array<Category>;
  productSet: ProductSet;
  addressSelectFieldOptions: Array<AddressSelectFieldOption>;
  moodboard: Moodboard;
};

export type QueryProjectArgs = {
  id: Scalars['String'];
};

export type QueryRoomArgs = {
  id: Scalars['String'];
};

export type QueryInitialRoomItemsArgs = {
  roomId: Scalars['String'];
};

export type QueryAccountArgs = {
  id: Scalars['String'];
};

export type QuerySearchProductsArgs = {
  input?: Maybe<SearchInput>;
};

export type QueryProductSetArgs = {
  id: Scalars['String'];
};

export type QueryAddressSelectFieldOptionsArgs = {
  attributeCode: AddressAttributeCode;
};

export type QueryMoodboardArgs = {
  id: Scalars['String'];
};

export type Room = {
  __typename?: 'Room';
  id: Scalars['String'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  roomTypeId?: Maybe<Scalars['String']>;
  roomType?: Maybe<RoomType>;
  roomDesignId?: Maybe<Scalars['String']>;
  roomDesign?: Maybe<RoomDesign>;
  floorTypeId?: Maybe<Scalars['String']>;
  floorType?: Maybe<FloorType>;
  colorSchemeId?: Maybe<Scalars['String']>;
  colorScheme?: Maybe<ColorScheme>;
  size?: Maybe<Scalars['Int']>;
  staticWorkstations?: Maybe<Scalars['Int']>;
  flexibleWorkstations?: Maybe<Scalars['Int']>;
  loungeRequired?: Maybe<Scalars['Boolean']>;
  spaceRequired?: Maybe<Scalars['Boolean']>;
  storageRequired?: Maybe<Scalars['Boolean']>;
  soundproofingRequired?: Maybe<Scalars['Boolean']>;
  furnitureTypeId?: Maybe<Scalars['String']>;
  furnitureType?: Maybe<FurnitureType>;
  workUnitId?: Maybe<Scalars['String']>;
  workUnit?: Maybe<WorkUnit>;
  workSettingId?: Maybe<Scalars['String']>;
  workSetting?: Maybe<WorkSetting>;
  items: Array<RoomItem>;
  deliveries: Array<RoomDelivery>;
  total?: Maybe<Total>;
  roomStatus: RoomStatus;
  planMode?: Maybe<RoomPlanMode>;
  moodboard?: Maybe<Moodboard>;
  approvals?: Maybe<Array<Maybe<Approval>>>;
  shippingAddress?: Maybe<RoomShippingAddress>;
  contactMe?: Maybe<Scalars['Boolean']>;
  acceptTermsAndConditions?: Maybe<Scalars['Boolean']>;
  fastShipping?: Maybe<Scalars['Boolean']>;
  fullDelivery?: Maybe<RoomFullDelivery>;
};

export type RoomDelivery = {
  __typename?: 'RoomDelivery';
  productCount?: Maybe<Scalars['Int']>;
  deliveryTimeDays: Scalars['Int'];
  deliveryTimeWeeks: Scalars['Int'];
  price: Scalars['Float'];
  deliveryTypeId: Scalars['String'];
  deliveryType: DeliveryType;
  manufacturer: Manufacturer;
};

export type RoomDesign = {
  __typename?: 'RoomDesign';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type RoomFullDelivery = {
  __typename?: 'RoomFullDelivery';
  productCount: Scalars['Int'];
  deliveryTimeDays: Scalars['Int'];
  deliveryTimeWeeks: Scalars['Int'];
  price: Scalars['Float'];
};

export type RoomItem = {
  __typename?: 'RoomItem';
  id: Scalars['String'];
  productConfigurationSku: Scalars['String'];
  productConfiguration: ProductConfiguration;
  productSetId?: Maybe<Scalars['String']>;
  productSet?: Maybe<ProductSet>;
  amount: Scalars['Int'];
  added: Scalars['Boolean'];
};

export type RoomPackage = {
  __typename?: 'RoomPackage';
  sku: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
  roomCount: Scalars['Int'];
};

export enum RoomPlanMode {
  OnlyInitial = 'ONLY_INITIAL',
  LetMePlan = 'LET_ME_PLAN',
  PlanForMe = 'PLAN_FOR_ME',
}

export type RoomShippingAddress = {
  __typename?: 'RoomShippingAddress';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  street: Scalars['String'];
  city: Scalars['String'];
  postcode: Scalars['String'];
  telephone: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  deliveryZoneTypeSelect: AddressSelectField;
  restrictedParkingSelect: AddressSelectField;
  truckParkingAvailableSelect: AddressSelectField;
  restrictedParkingComment: Scalars['String'];
  elevatorSelect: AddressSelectField;
  elevatorMeasurements: Scalars['String'];
  additionalComments: Scalars['String'];
  floor: Scalars['String'];
};

export type RoomShippingAddressInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  deliveryZoneTypeSelect?: Maybe<Scalars['String']>;
  restrictedParkingSelect?: Maybe<Scalars['String']>;
  truckParkingAvailableSelect?: Maybe<Scalars['String']>;
  restrictedParkingComment?: Maybe<Scalars['String']>;
  elevatorSelect?: Maybe<Scalars['String']>;
  elevatorMeasurements?: Maybe<Scalars['String']>;
  additionalComments?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
};

export enum RoomStatus {
  New = 'NEW',
  Isplanning = 'ISPLANNING',
  Planned = 'PLANNED',
  Ordered = 'ORDERED',
}

export type RoomType = {
  __typename?: 'RoomType';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SearchCriteria = {
  attributeName: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

export type SearchInput = {
  criteria?: Maybe<Array<Maybe<SearchCriteria>>>;
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type Total = {
  __typename?: 'Total';
  originalTotalPrice: Scalars['Float'];
  originalTotalPriceInclTax: Scalars['Float'];
  discountAmount: Scalars['Float'];
  taxAmount: Scalars['Float'];
  totalPrice: Scalars['Float'];
  totalPriceInclTax: Scalars['Float'];
  totalItems: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  username?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  id: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
  addresses: Array<Address>;
  emailConfirmStatus: ConfirmStatus;
  memberships: Array<Membership>;
  subscribeToNewsletter: Scalars['Boolean'];
  vatId?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};

export type WorkSetting = {
  __typename?: 'WorkSetting';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type WorkUnit = {
  __typename?: 'WorkUnit';
  id: Scalars['String'];
  name: Scalars['String'];
};

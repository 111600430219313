import { styled, colors, body, h1, h3 } from '../../../../lib/themes';
import { PeopleIcon } from '../../../../lib/icons';

export const StyledSubscriptionBlock = styled.div``;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TrialAccountInfo = styled.div`
  flex: 1;
`;

export const TrialAccountTitle = styled.h2`
  ${h1}
  margin-bottom: 25px;
`;

export const TrialAccountDescription = styled.p`
  ${body}
`;

export const ButtonWrapper = styled.div`
  margin-top: 30px;
`;

export const Subscription = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 60px 100px;
  margin-top: 100px;

  background-color: ${colors.grayLight};
`;

export const SubscriptionIcon = styled(PeopleIcon)`
  /* width: 100px;
  height: 100px; */
`;

export const SubscriptionContent = styled.div`
  width: 415px;
`;

export const SubscriptionTitle = styled.h3`
  ${h3}
  margin-bottom: 25px;
`;

export const SubscriptionDescription = styled.p`
  ${body}
`;

export const SubscriptionButtonWrapper = styled.div`
  margin-top: 20px;
`;

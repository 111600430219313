import React from 'react';
import { observer } from 'mobx-react-lite';

import { Fab } from '../../../../../lib/components/Fab';
import { FormCheckbox } from '../../../../../lib/components/Form/FormFields/FormCheckbox';
import { ParameterWrapper } from '../RoomParamsPage/RoomParamsPage.styled';

interface ICheckboxFieldProps {
  isDisabled?: boolean;
  name: string;
  label: string;
  smartTipContent?: React.ReactNode;
  onInfoButtonClick?: (refEl: React.RefObject<any>, smartTipContent: React.ReactNode) => void;
}

export const CheckboxField: React.FC<ICheckboxFieldProps> = observer(
  ({ isDisabled, label, name, smartTipContent, onInfoButtonClick }) => {
    const ref = React.useRef<HTMLButtonElement | null>(null);

    const handleInfoButtonClick = () => {
      onInfoButtonClick && onInfoButtonClick(ref, smartTipContent);
    };

    return (
      <ParameterWrapper isDisabled={Boolean(isDisabled)}>
        <FormCheckbox name={name}>{label}</FormCheckbox>
        <Fab variant="info" onClick={handleInfoButtonClick} refEl={ref} />
      </ParameterWrapper>
    );
  },
);

import React, { useCallback, useEffect, useState } from 'react';
import { SnackBarProp, SnackBarMessageProp, SnackBarType } from './types';
import { IconWrapper, Message, Root, SnackbarStack } from './SnackBar.styled';
import { IconButton } from '../IconButton';
import { AlertIcon, SuccessIcon, WarningIcon } from '../../icons';

const dismissTime = options => options.autoDismissTime || 3000;

const SnackBarMessage: React.FC<SnackBarMessageProp> = ({ toast, id, options, dismiss }) => {
  const [isShow, setIsShow] = useState(true);

  useEffect(() => {
    if (options.autoDismiss) {
      let timer = setTimeout(() => {
        setIsShow(false);
        clearTimeout(timer);
        timer = setTimeout(() => {
          dismiss(id);
        }, 400);
      }, dismissTime(options));

      return () => {
        clearTimeout(timer);
      };
    }
    return;
  }, []);

  const handleDismiss = useCallback(() => {
    dismiss(id);
  }, [dismiss, id]);

  const renderIcon = useCallback(() => {
    switch (options.type) {
      case SnackBarType.alert:
        return (
          <IconWrapper>
            <AlertIcon />
          </IconWrapper>
        );
      case SnackBarType.success:
        return (
          <IconWrapper>
            <SuccessIcon />
          </IconWrapper>
        );
      case SnackBarType.warning:
        return (
          <IconWrapper>
            <WarningIcon />
          </IconWrapper>
        );
      default:
        return null;
    }
  }, [options.type]);

  return (
    <Root
      show={isShow}
      className={options.className}
      type={options.type}
      autoDismissTime={options.autoDismissTime}
      autoDismiss={options.autoDismiss}
    >
      {options.component ? (
        options.component
      ) : (
        <>
          {renderIcon()}
          <Message>{toast.message}</Message>
          {options.closeButton && (
            <IconButton
              icon="Cross"
              onClick={handleDismiss}
              css={`
                width: 32px;
                height: 32px;
                margin-right: 19px;
                color: currentColor;
                &:hover,
                &:focus {
                  color: currentColor;
                }
              `}
            />
          )}
        </>
      )}
    </Root>
  );
};

export const SnackBar: React.FC<SnackBarProp> = ({ toasts, dismiss }) => {
  return (
    <SnackbarStack>
      {toasts.map(toast => (
        <SnackBarMessage key={toast.id} toast={toast} dismiss={dismiss} id={toast.id} options={toast.options} />
      ))}
    </SnackbarStack>
  );
};

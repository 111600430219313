import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation } from 'react-router';

import { useStore } from '../../core/hooks/use-store';
import { RequireAuthRepository } from '../../core/available-stores';
import { AuthFormWindow } from '../../../modules/auth/containers/AuthFormWindow';
import { MenuItem } from '../MenuItem';
import { LoginIcon } from '../../icons';
import { Menu } from '../Menu';
import { AuthorizedUserMenu } from './AuthorizedUserMenu';

import { IconButtonStyled } from './Header.styled';

export const HeaderUserMenu: React.FC = observer(() => {
  const authRepository = useStore<RequireAuthRepository>('AuthRepository');
  const location = useLocation();
  const history = useHistory();

  const [showAuthFormWindow, setShowAuthFormWindow] = React.useState(false);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('showLogin') === 'true') {
      setShowAuthFormWindow(true);
      // remove `showLogin` search parameter from address string
      history.replace(location.pathname);
    }
  }, [history, location]);

  const handleShowAuthFormWindow = React.useCallback(() => {
    setShowAuthFormWindow(true);
  }, [setShowAuthFormWindow]);

  const handleCloseAuthFormWindow = React.useCallback(() => {
    setShowAuthFormWindow(false);
  }, [setShowAuthFormWindow]);

  return authRepository.hasAuth ? (
    <AuthorizedUserMenu />
  ) : (
    <>
      <Menu anchorElement={<IconButtonStyled icon="User" isShow={true} />}>
        <MenuItem onClick={handleShowAuthFormWindow}>
          Einloggen <LoginIcon />
        </MenuItem>
      </Menu>
      {showAuthFormWindow && <AuthFormWindow onClose={handleCloseAuthFormWindow} />}
    </>
  );
});

import React, { useCallback } from 'react';
import * as Yup from 'yup';

import { Modal } from '../../../../../../../lib/components/Modal';
import { FormFieldRow } from '../../../../../../../lib/components/Form/FormFieldRow';
import { FormInput } from '../../../../../../../lib/components/Form/FormFields';
import { Form, FormButton, FormLayout } from '../../../../../../../lib/components/Form';
import { Button } from '../../../../../../../lib/components/Button';
import { useMe, useUpdateAddress, useUpdateUser } from '../../../../../../../lib/core/repositories/user-repository';
import { Loader } from '../../../../../../../lib/components/Loader';
import { CError } from '../../../../../../../lib/components/Error';
import { On } from '../../../../../../../lib/core/hooks/on';
import { requiredString } from '../../../../../../../lib/utils/yup-reductions';

interface PaymentAddressFormModalProps {
  isOpened: boolean;
  postSubmit: (id: string) => unknown;
  onChancel: () => unknown;
}

export const PaymentAddressFormModal: React.FC<PaymentAddressFormModalProps> = ({
  isOpened,
  postSubmit,
  onChancel,
}) => {
  const [submitUpdateAddress] = useUpdateAddress();
  const [updateUser] = useUpdateUser();

  const handleSubmit = useCallback(
    values => {
      const { firstName, lastName, street, city, postcode, countryId, telephone, company } = values;
      return Promise.all([
        submitUpdateAddress({
          firstName,
          lastName,
          street,
          city,
          postcode,
          countryId,
          telephone,
          company,
        }),
        updateUser({ vatId: values.vatId }),
      ]);
    },
    [submitUpdateAddress, updateUser],
  );

  const afterSubmit = useCallback(([{ memberships }]) => postSubmit(memberships[0].account.id), [postSubmit]);

  return On(
    user => (
      <Modal
        isOpened={isOpened}
        title={'Bitte vervollständigen Sie Ihre Zahlungsinformationen'}
        closable={false}
        css={`
          width: 1210px;
          h3 {
            text-align: left;
          }
        `}
      >
        <Form
          initialValues={{
            firstName: user.firstName || '',
            lastName: user.firstName || '',
            company: user.addresses[user.addresses.length - 1]?.company || '',
            street: user.addresses[user.addresses.length - 1]?.street || '',
            postcode: user.addresses[user.addresses.length - 1]?.postcode || '',
            city: user.addresses[user.addresses.length - 1]?.city || '',
            telephone: user.addresses[user.addresses.length - 1]?.telephone || '',
            countryId: 'DE',
            vatId: user.vatId || '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          afterSubmit={afterSubmit}
        >
          <FormLayout>
            <FormFieldRow>
              <FormInput name="lastName" label="Name" />
              <FormInput name="company" label="Firma" />
            </FormFieldRow>
            <FormFieldRow>
              <FormInput name="firstName" label="Vorname" />
              <FormFieldRow
                css={`
                  width: 100%;
                `}
              >
                <FormInput type="tel" name="telephone" label="Telefonnummer" />
                <FormInput name="vatId" label="Umastzsteuer-ID (Optional)" />
              </FormFieldRow>
            </FormFieldRow>
            <FormFieldRow>
              <FormInput name="street" label="Straße und Hausnummer" />
              <FormFieldRow
                css={`
                  width: 100%;
                `}
              >
                <FormInput name="city" label="Stadt" />
                <FormInput name="postcode" label="PLZ" />
              </FormFieldRow>
            </FormFieldRow>

            <div
              css={`
                display: flex;
                justify-content: flex-end;
                margin-top: 90px;
              `}
            >
              <Button
                variant={'outlined'}
                color={'bronze'}
                css={`
                  margin-right: 50px;
                `}
                onClick={onChancel}
              >
                Abbrechen
              </Button>
              <FormButton type="submit" variant="contained" color="bronze">
                Speichern
              </FormButton>
            </div>
          </FormLayout>
        </Form>
      </Modal>
    ),

    () => <Loader />,
    errorState => <CError error={errorState} />,
    useMe(),
  );
};

export interface IPaymentAddressForm {
  firstName: string;
  lastName: string;
  street: string;
  city: string;
  postcode: string;
  countryId: string;
  telephone: string;
  company: string;
}

const validationSchema = Yup.object().shape<IPaymentAddressForm>({
  firstName: requiredString('Bitte geben Sie Ihren Vornamen ein.'),
  lastName: requiredString('Bitte geben Sie Ihren Nachnamen ein.'),
  telephone: requiredString('Dieses Feld darf nicht leer sein.'),
  street: requiredString('Dieses Feld darf nicht leer sein.'),
  city: requiredString('Dieses Feld darf nicht leer sein.'),
  postcode: requiredString('Dieses Feld darf nicht leer sein.'),
  countryId: Yup.string().oneOf(['DE']),
  company: requiredString('Dieses Feld darf nicht leer sein.'),
});

import React from 'react';

import { Button, NavigationButton } from '../../../../lib/components';
import { AuthFormWindow, AuthStatus } from '../../../auth/containers/AuthFormWindow';
import {
  ButtonWrapper,
  StyledSubscriptionBlock,
  Subscription,
  SubscriptionButtonWrapper,
  SubscriptionContent,
  SubscriptionDescription,
  SubscriptionIcon,
  SubscriptionTitle,
  TrialAccountDescription,
  TrialAccountInfo,
  TrialAccountTitle,
  Wrapper,
} from './SubscriptionBlock.styled';

export const SubscriptionBlock: React.FC = () => {
  const [showAuthFormWindow, setShowAuthFormWindow] = React.useState<boolean>(false);

  const handleShowAuthFormWindow = React.useCallback(() => {
    setShowAuthFormWindow(true);
  }, [setShowAuthFormWindow]);

  const handleCloseAuthFormWindow = React.useCallback(() => {
    setShowAuthFormWindow(false);
  }, [setShowAuthFormWindow]);

  return (
    <StyledSubscriptionBlock>
      {/*<Wrapper>*/}
      {/*  <TrialAccountInfo>*/}
      {/*    <TrialAccountTitle>Jetzt unsere Designrichtungen erkunden.</TrialAccountTitle>*/}
      {/*    <TrialAccountDescription>*/}
      {/*      Erleben Sie unsere Designwelten für unterschiedliche Bereiche und lassen Sie sich von dem digitalen*/}
      {/*      Designberater führen.*/}
      {/*    </TrialAccountDescription>*/}
      {/*  </TrialAccountInfo>*/}
      {/*  <ButtonWrapper>*/}
      {/*    <NavigationButton href="/styles" variant="outlined" color="bronze">*/}
      {/*      Zum Planungsbeispiel*/}
      {/*    </NavigationButton>*/}
      {/*  </ButtonWrapper>*/}
      {/*</Wrapper>*/}
      <Subscription>
        <SubscriptionIcon />
        <SubscriptionContent>
          <SubscriptionTitle>Let’s get furnished.</SubscriptionTitle>
          <SubscriptionDescription>
            Ihr Weg zum online Designberater, der Sie über einfache Schritte mit intuitiver Bedienung zur Ihrer
            Wunscheinrichtung führt.
          </SubscriptionDescription>
        </SubscriptionContent>
        <SubscriptionButtonWrapper>
          <Button variant="contained" color="bronze" onClick={handleShowAuthFormWindow}>
            Mitglied werden
          </Button>
        </SubscriptionButtonWrapper>
      </Subscription>
      {showAuthFormWindow && (
        <AuthFormWindow initialAuthStatus={AuthStatus.Registration} onClose={handleCloseAuthFormWindow} />
      )}
    </StyledSubscriptionBlock>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { observer, useObservable } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { formatCurrency } from '../../../../lib/utils/format-number';
import { Redirect } from '../../../../lib/router/redirect';
import { pause } from '../../../../lib/utils/pause';
import { Header } from '../../../../lib/components/Header';
import { Container } from '../../../../lib/components/Container';
import { roomPackagesStore } from '../../../../lib/core/other-stores/room-packages-store';
import {
  useCompletePaypalRoomPackagePurchase,
  usePurchaseRoomPackage,
} from '../../../../lib/core/repositories/room-packages-repository';

import { PaymentMethod, PurchaseResponse } from '../../../../lib/core/api/generated';
import { useBillingAddress, useCurrentAccount } from '../../../../lib/core/repositories/user-repository';

import { PaymentAnimation } from '../../components';

import { Form, FormButton, FormCheckbox } from '../../../../lib/components/Form';
import { Link } from '../../../../lib/components/Link';
import { Typography } from '../../../../lib/themes/typography/Typography';
import { Radio } from '../../../../lib/components/Radio';

import {
  StyledPaymentPage,
  TitleWrapper,
  PaymentHeader,
  Content,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  PayPalLogoIconStyled,
  PaymentFooter,
  PreviousPrice,
  FinalPrice,
  SubmitButtonWrapper,
  LinkText,
} from './PaymentPage.styled';
import { noop } from '../../../../lib/utils/placeholders';
import { PaymentAddressFormModal } from '../../../configurator/containers/ConfiguratorItemsRootStep/SummaryPage/components/PaymentAddressFormModal';

export const PaymentPage: React.FC = observer(() => {
  const history = useHistory();
  const [currentAccount] = useCurrentAccount();
  const [billingAddress] = useBillingAddress();
  const [purchaseRoomPackage] = usePurchaseRoomPackage();
  const [completePaypalRoomPackagePurchase] = useCompletePaypalRoomPackagePurchase();

  const { roomPackage } = useObservable(roomPackagesStore);
  const [token, setToken] = useState<string>();
  const [isOpenPaymentAddressForm, setIsOpenPaymentAddressForm] = useState(false);

  const [isPaymentAnimationOpened, setPaymentAnimationOpened] = React.useState<boolean>(false);

  const handleClosePaymentAddressForm = useCallback(() => {
    setIsOpenPaymentAddressForm(false);
  }, []);

  const onSubmit = React.useCallback(
    accountId => {
      setIsOpenPaymentAddressForm(false);
      return purchaseRoomPackage({
        accountId,
        sku: roomPackage?.sku || '',
        method: PaymentMethod.Paypal,
      }).then(({ paypal }: PurchaseResponse) => {
        if (paypal) {
          setToken(paypal.token);
          // for test
          // paypal.startUrl = `http://localhost:8080/payment/paypal/action/success?token=${paypal.token}&PayerID=JETCTFW7TKAPW`;

          window.open(paypal.startUrl, '_blank');
        }
      });
    },
    [purchaseRoomPackage, roomPackage],
  );

  const preSubmit = React.useCallback(() => {
    if (billingAddress) {
      return onSubmit(currentAccount ? currentAccount.id : '');
    }
    setIsOpenPaymentAddressForm(true);
    return Promise.resolve();
  }, [onSubmit, billingAddress, currentAccount]);

  useEffect(() => {
    const handleOnMessage = (event: MessageEvent) => {
      const { origin, data } = event;

      // Listen to messages from our domain
      if (('https://furnished.de' === origin || true) && currentAccount && data.token === token && data.payerId) {
        setPaymentAnimationOpened(true);

        Promise.all([
          completePaypalRoomPackagePurchase({
            accountId: currentAccount.id,
            paypalToken: token!,
            paypalPayerId: data.payerId,
          }),
          pause(2000),
        ])
          .then(result => {
            if (result) {
              history.push(`/accounts/${currentAccount.id}/projects`);
            }
            // TODO raise error
          })
          .catch(error => console.error({ error }))
          .finally(() => {
            setPaymentAnimationOpened(false);
          });
      }
    };

    window.addEventListener('message', handleOnMessage, false);
    return () => window.removeEventListener('message', handleOnMessage);
  }, [completePaypalRoomPackagePurchase, currentAccount, history, setPaymentAnimationOpened, token]);

  if (!roomPackage) {
    return <Redirect to={'/'} />;
  }

  return (
    <StyledPaymentPage>
      <Header />

      <Container>
        <PaymentHeader>
          <TitleWrapper>
            <Typography tag="h1" variant="h1">
              Zahlung für eine Mitgliedschaft
            </Typography>
          </TitleWrapper>
        </PaymentHeader>

        <Content>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography tag="p" variant="body">
                    Zahlungsart
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Radio isRound={true} checked={true} onChange={noop} value="paypal">
                    <PayPalLogoIconStyled />
                  </Radio>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography tag="p" variant="body">
                    Paket
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography tag="p" variant="body">
                    Kosten
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography tag="h2" variant="h2">
                    {roomPackage.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography tag="h3" variant="h3">
                    {formatCurrency(roomPackage.roomCount * 89)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Content>

        <PaymentAddressFormModal
          isOpened={isOpenPaymentAddressForm}
          postSubmit={onSubmit}
          onChancel={handleClosePaymentAddressForm}
        />

        <PaymentFooter>
          {roomPackage.roomCount > 1 && <PreviousPrice>{formatCurrency(roomPackage.roomCount * 89)}</PreviousPrice>}
          <FinalPrice>{formatCurrency(roomPackage.price)}</FinalPrice>
          <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={preSubmit}>
            <SubmitButtonWrapper>
              <FormCheckbox name="agreedPrivacyPolicy">
                Ich akzeptiere&nbsp;
                <Link to={'/privacy-policy'} target="_blank">
                  <LinkText>die AGB</LinkText>
                </Link>
              </FormCheckbox>

              <FormButton type="submit" variant="contained" color="bronze">
                {formatCurrency(roomPackage.price)} zahlen
              </FormButton>
            </SubmitButtonWrapper>
          </Form>
        </PaymentFooter>
      </Container>

      <PaymentAnimation total={roomPackage.price} isOpened={isPaymentAnimationOpened} />
    </StyledPaymentPage>
  );
});

const initialValues = {
  agreedPrivacyPolicy: false,
};

const validationSchema = Yup.object().shape<any>({
  agreedPrivacyPolicy: Yup.boolean()
    .oneOf([true])
    .required('Dieses Feld darf nicht leer sein.'),
});

import { styled } from '../../../../../../../lib/themes';

export const Root = styled.div`
  width: 1038px;
  margin: -50px -50px 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 860px;
  margin: 0 auto 57px;
  h1 {
    margin: 30px 0 16px;
  }
  p {
    margin: 0;
    margin-bottom: 30px;
    line-height: 30px;
  }
  p:last-child {
    margin-bottom: 0;
  }
`;

import { action, observable } from 'mobx';
import { RoomPackage } from '../api/generated';

export class RoomPackagesStore {
  // currentRoomPackage
  @observable
  public roomPackage: RoomPackage | undefined;

  @action
  setPackageCard = (currentRoomPackage: RoomPackage) => {
    this.roomPackage = currentRoomPackage;
  };
}

export const roomPackagesStore = new RoomPackagesStore();

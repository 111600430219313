import { IDesignStylesTabProps, TDesignStyles } from '../../types';

export const tabs: IDesignStylesTabProps[] = [
  { urn: 'startup', label: 'Startup' },
  { urn: 'co-working', label: 'Co-Working' },
  { urn: 'marketing', label: 'Marketing' },
];

export const roomDesignStyles: TDesignStyles = {
  startup: {
    title: 'URBAN COOL',
    description: `Material-Mix und cooles Zubehör | flexible Einrichtung für zukünftiges Wachstum | Lebendige Atmosphäre<br /><br />Der lockere und mutige Stil verzaubert viele Büroflächen von jungen Unternehmen. Hier ist alles erlaubt. Man kombiniert unterschiedliche Materialien und Farben und hebt das eigene Logo mit einer außergewöhnlichen Wandgestaltung in den Vordergrund.
    `,
    benefits: [
      {
        description:
          'Startups profitieren von einfachen, aber wirkungsvollen Gestaltungselementen sowie einer flexiblen Raumgestaltung',
        photo: 'img/mocks/design-styles/startup-1.jpg',
      },
      {
        description: 'Unterschiedliche Meeting- und Sitz Situationen fördern kreatives Arbeiten',
        photo: 'img/mocks/design-styles/startup-2.jpg',
      },
    ],
  },
  'co-working': {
    title: 'Homely chic',
    description: `Naturmaterialien | schlicht und doch vielseitig | Harmonische Atmosphäre<br /><br />
    Ein sicherer Stil für die breite Masse ist die Wohlfühlatmosphäre des modernen Skandi Stils. Eine Leichtigkeit verbunden mit der grünen Oase zieht einfach immer an.
    `,
    benefits: [
      {
        description: 'Optische Abtrennungen helfen, den Raum zu gliedern und Ruhezonen zu schaffen',
        photo: 'img/mocks/design-styles/coworking-1.jpg',
      },
      {
        description: 'Ein offener und freundlicher Community Bereich ist das Herz eines Co-Working Konzepts',
        photo: 'img/mocks/design-styles/coworking-2.jpg',
      },
    ],
  },
  marketing: {
    title: 'Modern glam',
    description: `Individuelles Design | High-Class Möbelstücke | Glamouröse Atmosphäre <br /><br />
    Der trendige Stil lebt durch die gekonnt eingesetzten Metall-Akzente sowie auserwählten Statement-Pieces. Hier sind kreative und stilbewusste Unternehmen Zuhause.
    `,
    benefits: [
      {
        description:
          'Auffällige Tapeten mit Dschungelmotiv in Kombination mit hochwertigem Holz und Messing bringen eine moderne Eleganz in Empfangs- oder Meetingräume',
        photo: 'img/mocks/design-styles/marketing-1.jpg',
      },
      {
        description: 'Pudertöne und mutige Farbgebung zeigen Individualität und Selbstbewusstsein im Design',
        photo: 'img/mocks/design-styles/marketing-2.jpg',
      },
    ],
  },
};

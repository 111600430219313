import { styled } from '../../themes';

export interface IStyledFeaturedBannerProps {
  backgroundImage: string;
}

export const Wrapper = styled.div<IStyledFeaturedBannerProps>`
  display: flex;
  align-items: center;

  width: 100%;
  height: calc(100vh - 80px);
  max-height: 640px;
  padding: 0 120px;

  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

export const Content = styled.div`
  width: 600px;
`;

import React from 'react';
import { useParams } from 'react-router';
import { Button } from '../../../../lib/components/Button';
import { Root, Content, Image, Title, Paragraph } from './SuccessOrderPage.styled';
import { Header } from '../../../../lib/components';

export const SuccessOrderPage: React.FC = () => {
  const { accountId, projectId } = useParams<{ accountId: string; projectId: string }>();

  return (
    <Root>
      <Header backButtonTitle={'Zurück zur Hauptseite'} backHref={'/'} />
      <Content>
        <Image src="/img/successOrderPage/room.svg" />
        <Title tag="h1" variant="h2">
          Vielen Dank für Ihre Bestellung!
        </Title>
        <Paragraph tag="p" variant="h4">
          Unser Projektmanager wird sich in Kürze mit Ihnen in Verbindung setzen, um die Einzelheiten zu klären.
        </Paragraph>
        <Button href={`/accounts/${accountId}/projects/${projectId}`} variant="contained" color="bronze">
          zur Hauptseite
        </Button>
      </Content>
    </Root>
  );
};

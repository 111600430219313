import React, { useRef } from 'react';
import { On } from '../../../../../lib/core/hooks/on';
import { CError } from '../../../../../lib/components/Error';
import { useRoomProducts, useRoomProductsBySolution, useSolutions } from './hooks/products-hooks';
import { formatCurrency } from '../../../../../lib/utils/format-number';
import { colors } from '../../../../../lib/themes';
import { AllRoomSteps } from '../../../typesUtils';
import { IConfiguratorItemsPagesProps } from '../ConfiguratorItemsPagesProps';
import { Typography } from '../../../../../lib/themes/typography/Typography';
import { Container } from '../../../../../lib/components/Container';
import { FlexContainer } from '../../../../../lib/components/FlexContainer';
import { Title } from '../../../../../lib/components/Title';
import { ConfiguratorPageFooter } from '../../../components';
import { PackageStatus } from './components/PackageStatus';
import { Solutions } from './components/Solutions';
import { LetMePlanProducts } from './components/FurnitureEditorProducts';
import { Jumbotron } from '../../../../../lib/components/Jumbotron/Jumbotron';
import { ContentItem, ProductOrCost } from '../../../components/ConfiguratorPageFooter/ConfiguratorPageFooter.styled';
import { Page } from './FurnitureEditorPage.styled';
import { MoodboardSlider } from '../components';

export const FurnitureEditorPage: React.FC<IConfiguratorItemsPagesProps> = ({ room, step, changeStep }) => {
  const useRoomProductsResult = useRoomProducts(room.id);
  const ref = useRef<HTMLDivElement>(null);
  const [roomProductsBySolution, currentSolution, setCurrentSolution] = useRoomProductsBySolution([
    useRoomProductsResult[0] ? useRoomProductsResult[0].products : [],
    useRoomProductsResult[1],
  ]);

  return On(
    ({ products, roomAmount, roomTotal, initialAmount, initialTotal }, allSolutions, loadState) => {
      return (
        <>
          <Page>
            <div
              ref={ref}
              css={`
                position: relative;
              `}
            >
              <MoodboardSlider moodboard={room.moodboard} />
              <PackageStatus
                amount={initialAmount}
                total={initialTotal}
                roomId={room.id}
                offsetParent={ref.current || undefined}
              />
            </div>
            <Solutions items={allSolutions} value={currentSolution} onChange={setCurrentSolution} products={products} />
            <div css={{ backgroundColor: colors.grayLight }}>
              <Container>
                <Jumbotron isClosable={true}>
                  <FlexContainer direction="column" css={{ justifyContent: 'center', minHeight: 155 }}>
                    <Title css={{ marginBottom: 22 }}>Ihr maßgeschneiderte Arbeitskonzepte</Title>
                    <Typography tag="p" variant="h5" css={{ maxWidth: 935 }}>
                      Entwerfen Sie Ihr Büro gemeinsam mit professionellen Designern. Die Produktauswahl ist im Design
                      abgestimmt auf ihre Parameter und garantiert ein optimales Ergebnis.
                    </Typography>
                  </FlexContainer>
                </Jumbotron>
              </Container>
            </div>
            <LetMePlanProducts roomId={room.id} roomProducts={roomProductsBySolution} />
          </Page>
          <ConfiguratorPageFooter
            step={step}
            changeStep={changeStep}
            prevStep={AllRoomSteps.RoomParams}
            nextStep={AllRoomSteps.RoomItemsViewer}
          >
            <ContentItem />
            <ContentItem isLoading={loadState.isPending()}>
              <ProductOrCost>{roomAmount} Produkte</ProductOrCost>
              <ProductOrCost>{formatCurrency(roomTotal)}</ProductOrCost>
            </ContentItem>
            <ContentItem />
          </ConfiguratorPageFooter>
        </>
      );
    },
    () => <div>Loading...</div>,
    error => <CError error={error} />,
    useRoomProductsResult,
    useSolutions(),
  );
};

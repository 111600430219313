import { colors, styled } from '../../themes';
import { Container } from '../Container';
import { LogoIcon } from '../../icons';
import { IconButton } from '../IconButton';
import { Link } from '../../router/link';

export const Root = styled.header`
  height: 80px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${colors.white};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
  z-index: 101;
`;

export const HeaderVariantWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex-grow: 1;
  align-self: flex-start;
`;

export const HeaderVariant = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  height: 80px;
  width: 100%;
  padding-left: 55px;
`;

export const LogoWrapper = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Logo = styled(Link)`
  position: absolute;
  left: calc(50vw - 85px);
  color: ${colors.bronze};
`;

export const LogoIconStyled = styled(LogoIcon)`
  color: ${colors.bronze};
`;

export const Navigation = styled.nav`
  height: 80px;
  display: flex;
  position: relative;
  @media (max-width: 800px) {
    margin-left: 50px;
  }
`;

export const ButtonsWrapper = styled.div`
  justify-self: flex-end;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 50px;
  margin-left: auto;
`;

interface IIconButtonProps {
  isShow: boolean;
}

export const IconButtonStyled = styled(IconButton)<IIconButtonProps>`
  opacity: ${props => (props.isShow ? 1 : 0)};
  transition: opacity 0.3s;
`;

export const PageTitle = styled.div`
  margin-right: 77px;
  max-width: 140px;

  & > * {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 1100px) {
    margin-right: 40px;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

import React from 'react';

import { Content, IStyledFeaturedBannerProps, Wrapper } from './Banner.styled';

export const Banner: React.FC<IStyledFeaturedBannerProps> = ({ children, ...props }) => {
  return (
    <Wrapper {...props}>
      <Content>{children}</Content>
    </Wrapper>
  );
};

import * as React from 'react';
import * as Yup from 'yup';

import { Form, FormButton, FormInput, FormFooter, FormLayout } from '../../../lib/components';
import { GqlErrors } from '../../../lib/core/errors/gql-error';
import { useResetPassword } from '../../../lib/core/repositories/register-repository';
import { MutationResetPasswordArgs } from '../../../lib/core/api/generated';
import { requiredString } from '../../../lib/utils/yup-reductions';

interface IResetPasswordFormProps {
  onClickRegister?: () => unknown;
  afterResetPassword?: () => unknown;
}

type IResetPasswordForm = MutationResetPasswordArgs;

export const ResetPasswordForm: React.FC<IResetPasswordFormProps> = ({
  afterResetPassword,
  onClickRegister,
}: IResetPasswordFormProps) => {
  const [submitResetPassword] = useResetPassword();

  const onSubmit = React.useCallback(
    (values: MutationResetPasswordArgs) => {
      return submitResetPassword(values);
    },
    [submitResetPassword],
  );

  return (
    <Form
      onSubmit={onSubmit}
      afterSubmit={afterResetPassword}
      initialValues={initialValues}
      validationSchema={validationSchema}
      errorsHR={[[GqlErrors.BAD_REQUEST, 'Diese E-Mail ist nicht registriert']]}
    >
      <FormLayout>
        <FormInput type="email" name="email" label="E-Mail" />
        <FormFooter>
          <FormButton
            type="button"
            variant="outlined"
            color="bronze"
            isIgnoreValidation={true}
            onClick={onClickRegister}
          >
            Anmelden
          </FormButton>
          <FormButton type="submit" variant="contained" color="bronze">
            Passwort zurücksetzen
          </FormButton>
        </FormFooter>
      </FormLayout>
    </Form>
  );
};

const initialValues = { email: '' };

const validationSchema = Yup.object().shape<IResetPasswordForm>({
  email: requiredString('Dieses Feld darf nicht leer sein.').email('E-Mail-Adresse falsch'),
});

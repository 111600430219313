import React from 'react';
import * as Yup from 'yup';

import { useCreateRoom } from '../../../../../../lib/core/repositories/room-repository';
import { Room } from '../../../../../../lib/core/api/generated';

import { Form, FormButton, FormInput, FormFooter, FormError } from '../../../../../../lib/components/Form';
import { FormLayout } from '../../../../../../lib/components/Form/FormLayout';

import { ButtonsWrapper, Root } from './NewRoomCard.styled';
import { GqlErrors } from '../../../../../../lib/core/errors/gql-error';
import { requiredString } from '../../../../../../lib/utils/yup-reductions';
import { AddCard } from '../../../../../../lib/components/AddCard';

interface IRoomCardProps {
  projectId: string;
  afterCreateRoom?: (room: Room) => unknown;
  onClose: () => unknown;
}

interface INewRoomForm {
  name: string;
}

export const NewRoomCard: React.FC<IRoomCardProps> = ({ projectId, afterCreateRoom, onClose }) => {
  const [submitCreateRoom] = useCreateRoom();

  const initialValues = React.useMemo(() => ({ projectId, name: '' }), [projectId]);

  return (
    <Root>
      <Form
        initialValues={initialValues}
        onSubmit={submitCreateRoom}
        afterSubmit={afterCreateRoom}
        validationSchema={validationSchema}
        errorsHR={[
          [
            GqlErrors.ACCOUNT_ROOM_LIMIT_REACHED,
            'Sie können keine weiteren Räume erstellen. Aktualisieren Sie den Tab und versuchen Sie es erneut.',
          ],
        ]}
      >
        <FormLayout>
          <FormInput
            label={'Raum Bezeichnung'}
            placeholder={'z.B. Arbeitsbereich'}
            name="name"
            autoFocusOnEmpty={true}
          />
          <FormError />
          <ButtonsWrapper>
            <FormButton type="submit" variant="outlined" color="bronze">
              Raum erstellen
            </FormButton>
            <FormButton variant="text" color="default" onClick={onClose} isIgnoreValidation={true}>
              Abbrechen
            </FormButton>
          </ButtonsWrapper>
        </FormLayout>
      </Form>
    </Root>
  );
};

const validationSchema = Yup.object().shape<INewRoomForm>({
  name: requiredString('Bitte geben Sie einen Raumnamen ein.')
    .min(2, 'Raumname ist zu kurz')
    .max(18, 'Raumname ist zu lang'),
});

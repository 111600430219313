import React, { useCallback, useEffect, useState } from 'react';
import { IConfiguratorItemsPagesProps } from '../ConfiguratorItemsPagesProps';
import { RoomStatus } from '../../../../../lib/core/api/generated';
import { useIsInvitedUser } from '../../../../../lib/core/hooks/useIsInvitedUser';
import { SubHeader } from '../../../components/SubHeader';
import { DeliveryScreen } from './LieferungScreen';
import { SummaryScreen } from './SummaryScreen/SummaryScreen';

export const SummaryPage: React.FC<IConfiguratorItemsPagesProps> = ({ room, step, changeStep }) => {
  const isRoomOrdered = room.roomStatus === RoomStatus.Ordered;
  const isInvitedUser = useIsInvitedUser();
  const [screen, setScreen] = useState(isRoomOrdered ? 2 : 1);
  const [isSaveForm, setSaveForm] = useState<boolean>(false);

  //set initial screen for the invited user on mount
  useEffect(() => {
    if (isInvitedUser && screen === 1) {
      setScreen(2);
    }
  }, [isInvitedUser]);

  const changeScreen = useCallback(
    screen => {
      setScreen(screen);
    },
    [setScreen],
  );

  const getSaveForm = saveForm => {
    setSaveForm(saveForm);
  };

  const items = [
    {
      text: 'Lieferung',
    },
    {
      text: 'Bestellung',
      disabled: !isSaveForm && !isRoomOrdered,
    },
  ];

  return (
    <>
      <SubHeader items={items} selectedId={screen} onChange={setScreen} />
      {screen === 1 ? (
        <DeliveryScreen room={room} changeStep={changeStep} changeScreen={changeScreen} getSaveForm={getSaveForm} />
      ) : (
        <SummaryScreen room={room} changeScreen={changeScreen} />
      )}
    </>
  );
};

import React, { useCallback, useEffect, useRef } from 'react';
import { observer, useObservable } from 'mobx-react-lite';

import { Link } from '../../../router/link';
import { TabsStore } from './TabsStore';
import { Item, ItemsWrapper, Icon, Line, Root } from './Tabs.styled';
import { MenuProvider } from '../../Menu';

export interface ITabItem<T = string> {
  name: string;
  value?: T;
  disabled?: boolean;
  to?: string;
  icon?: JSX.Element;
  counter?: number;
  [key: string]: any;
}

interface ITabsProps<T = string> {
  items: ITabItem<T>[];
  value?: T;
  onChange: (value: T) => void;
  counted?: boolean;
  itemsWidth?: number;
  stepMargin?: number;
  className?: string;
}

const hasIcon = (icon?: JSX.Element) => icon !== undefined;

const hasCounter = (counter?: number): boolean => Boolean(counter);

// TODO: remove store, observer and observable and make this function generic
// TODO: use material ui Tabs/Tab API
export const Tabs: React.FC<ITabsProps> = observer(props => {
  const { items, value, onChange, counted = false, className, itemsWidth, stepMargin } = props;

  const itemWrapperRef = useRef<HTMLDivElement>(null);

  const {
    activeItemId,
    setActiveItemId,
    setItemsWrapperElement,
    lineWidthCurrent,
    lineShift,
    computedStepMargin,
  } = useObservable(new TabsStore(items.findIndex(item => item.value === value) ?? 0));

  useEffect(() => {
    if (itemWrapperRef.current) {
      setItemsWrapperElement(itemWrapperRef.current as HTMLDivElement);
    }
  }, [items, setItemsWrapperElement]);

  const handleChange = useCallback(
    (value: any) => () => {
      onChange(value);
    },
    [onChange],
  );

  useEffect(() => {
    setActiveItemId(items.findIndex(item => item.value === value) ?? 0);
  }, [items, value, setActiveItemId]);

  return (
    <Root className={className} data-name={'tabs-root'}>
      <ItemsWrapper ref={itemWrapperRef} data-name={'tabs-items-wrapper'}>
        {items.map((item, index) => (
          <Item
            width={itemsWidth}
            key={index}
            as={item.to ? (item.disabled ? 'button' : Link) : 'button'}
            to={item.to ? (item.disabled ? '' : item.to) : ''}
            active={activeItemId === index}
            counted={counted}
            counter={item.counter}
            disabled={item.disabled || false}
            hasIcon={hasIcon(item.icon)}
            hasCounter={hasCounter(item.counter)}
            onClick={handleChange(item.value)}
            onFocus={handleChange(item.value)}
            data-name={'tabs-item'}
          >
            {item.icon && <Icon>{item.icon}</Icon>}
            {item.name}
          </Item>
        ))}
      </ItemsWrapper>
      <Line
        style={{
          left: `${lineShift + (stepMargin || computedStepMargin) * activeItemId}px`,
          width: `${lineWidthCurrent}px`,
        }}
        data-name={'tabs-line'}
      />
    </Root>
  );
});

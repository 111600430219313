import React from 'react';

import { ProductConfiguration } from '../../core/api/generated';
import { formatCurrency } from '../../utils/format-number';

import { getRandomProductImage } from '../../utils/getRandomProductImage';

import { TrashIcon, CartIcon } from '../../icons';
import { Button, CounterButton, SmartTipDialog } from '../../components';

import {
  Root,
  ButtonsWrapper,
  StyledImage,
  Descriptions,
  Name,
  Price,
  FabStyled,
  ButtonDetails,
  Background,
  ConfigurationName,
  Total,
} from './ProductCard.styled';

interface IProductCardProps {
  productConfiguration: ProductConfiguration;
  amount?: number;
  isInList: boolean;
  // if isEditable then the user can change the amount of product and add/remove product
  isEditable?: boolean;
  isAmountEditable?: boolean;
  onChangeAmount?: (amount: number) => unknown;
  onClickDetailsButton?: (event: React.MouseEvent<HTMLButtonElement>) => unknown;
  onClickToggleInListButton: (event: React.MouseEvent<HTMLButtonElement>) => Promise<unknown> | unknown;
}

export const ProductCard: React.FC<IProductCardProps> = props => {
  const {
    productConfiguration,
    isInList,
    amount,
    onChangeAmount,
    onClickDetailsButton,
    onClickToggleInListButton,
    isEditable = true,
    isAmountEditable = isEditable,
  } = props;

  const tipButtonRef = React.useRef<HTMLButtonElement | null>(null);
  const [isTipOpened, setIsTipOpened] = React.useState(false);

  const image = productConfiguration.imageUrls[0] || getRandomProductImage(productConfiguration.id || '0');
  const total = (productConfiguration.price || 0) * (amount || 1);

  const [loading, setLoading] = React.useState(false);

  const toggleInListCallback = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const result = onClickToggleInListButton(event);
      setLoading(true);
      if (result instanceof Promise) {
        return result.finally(() => setLoading(false));
      }
      return result;
    },
    [onClickToggleInListButton],
  );

  const onClickTipButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsTipOpened(true);
  };

  const handleTipClose = () => {
    setIsTipOpened(false);
  };

  return (
    <Background>
      <Root>
        {productConfiguration.hint && <FabStyled variant="plus" onClick={onClickTipButton} refEl={tipButtonRef} />}
        <StyledImage src={`${image}?size=480x480`} alt={productConfiguration.name} />
        <Descriptions>
          <Name>{productConfiguration.name}</Name>
          <ConfigurationName>{productConfiguration.configurationName}</ConfigurationName>
          <Price>je {formatCurrency(productConfiguration.price || 0)}</Price>
          <Total tag="p" variant="body">
            {formatCurrency(total)}
          </Total>
        </Descriptions>
        <ButtonsWrapper>
          <CounterButton value={amount || 0} onChange={onChangeAmount} notChangeable={!isAmountEditable} />
          {onClickDetailsButton && (
            <ButtonDetails variant="text" size="small" onClick={onClickDetailsButton}>
              Zum Produkt
            </ButtonDetails>
          )}
        </ButtonsWrapper>

        {isEditable && (
          <div
            css={`
              margin: 40px auto 24px;
            `}
          >
            {isInList ? (
              <Button
                variant="outlined"
                color="bronze"
                size="small"
                isLoading={loading}
                onClick={toggleInListCallback}
                iconStart={<TrashIcon />}
              >
                Entfernen
              </Button>
            ) : (
              <Button
                variant="contained"
                color="bronze"
                size="small"
                isLoading={loading}
                onClick={toggleInListCallback}
                disabled={loading}
                iconStart={<CartIcon />}
              >
                Hinzufügen
              </Button>
            )}
          </div>
        )}

        <SmartTipDialog anchorRef={tipButtonRef} isOpened={isTipOpened} onClose={handleTipClose}>
          {productConfiguration.hint}
        </SmartTipDialog>
      </Root>
    </Background>
  );
};

import { routeStore } from '../../router';

class InitialPathNameStore {
  initialPathNameKey = 'initialPathName';

  saveInitialPathName = () => {
    window.localStorage.setItem(this.initialPathNameKey, window.location.pathname);
  };

  resumeInitialPathName = (): boolean => {
    const pathname = window.localStorage.getItem(this.initialPathNameKey);
    if (pathname && pathname !== '/') {
      routeStore.history.push({
        pathname,
      });
      window.localStorage.removeItem(this.initialPathNameKey);
      return false;
    }
    return true;
  };
}

export const initialPathStore = new InitialPathNameStore();

import React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { RoomItemsStep, RoomParamsStep } from '../../typesUtils';
import { On } from '../../../../lib/core/hooks/on';
import { Root } from '../ConfiguratorPage/ConfiguratorPageStyled.styled';
import { CError } from '../../../../lib/components/Error';
import { useNonWatchGetRoom } from '../../../../lib/core/repositories/room-repository';
import { ConfiguratorPageHeader } from '../../components/ConfiguratorPageHeader';
import { RoomParamsPage } from '../ConfiguratorParamsRootStep/RoomParamsPage';

export interface IConfiguratorFinalParamsRootStepProps {
  accountId: string;
  projectId: string;
  roomId: string;
  page?: string;
}

/**
 * This is special case for room parameters which should not use watching query fora room
 * to avoid unneeded re-renders
 * This is special case with complicated update and special loader,
 * so we cannot handle it the same way we do other pages
 * @constructor
 */
export const ConfiguratorFinalParamsRootStep: React.FC = () => {
  const { accountId, projectId, roomId, page } = useParams<IConfiguratorFinalParamsRootStepProps>();

  const history = useHistory();

  const changeStep = React.useCallback(
    (newStep: RoomParamsStep | RoomItemsStep) => {
      history.push(`/accounts/${accountId}/projects/${projectId}/rooms/${roomId}/${newStep}`);
    },
    [history, accountId, projectId, roomId],
  );

  const step = RoomParamsStep.RoomParams;

  return On(
    room => (
      <Root>
        <ConfiguratorPageHeader step={step} room={room} changeStep={changeStep} />
        <RoomParamsPage changeStep={changeStep} room={room} step={step} />
      </Root>
    ),
    () => <div>Loading...</div>,
    errorState => <CError error={errorState} />,
    useNonWatchGetRoom({ id: roomId }),
  );
};

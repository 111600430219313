import React from 'react';

import { Root, ButtonsWrapper, Content } from './ConfiguratorPageFooter.styled';
import { RoomConfiguratorStep, RoomItemsStep, RoomParamsStep } from '../../typesUtils';
import { NavigationButton } from '../../../../lib/components/Navigation/NavigationButton';
import { FormNavigationButton } from '../../../../lib/components/Form/FormNavigationButton';
import { FormButton } from '../../../../lib/components/Form';

interface IConfiguratorPageFooterProps {
  prevStep?: RoomConfiguratorStep; // if not set, no prev button showed
  nextStep?: RoomConfiguratorStep; // if not set, next button will be submit button, otherwise link to the next step
  nextTitle?: string;
  submitTitle?: string;
  changeStep: (newStep: RoomParamsStep | RoomItemsStep) => unknown;
  step: RoomParamsStep | RoomItemsStep;
}

/**
 * The next button is submit button, so it will trigger submit for a Form
 * @param prevButton
 * @param nextButton
 * @param step
 * @param children
 * @constructor
 */
export const ConfiguratorPageFooter: React.FC<IConfiguratorPageFooterProps> = ({
  prevStep,
  nextStep,
  changeStep,
  step,
  nextTitle,
  submitTitle,
  children,
}) => {
  const goBack = React.useCallback(() => prevStep && changeStep(prevStep.step), [changeStep, prevStep]);
  const goNext = React.useCallback(() => nextStep && changeStep(nextStep.step), [changeStep, nextStep]);

  const prevButton = prevStep ? (
    <NavigationButton type="button" variant="contained" color="bronze" direction="back" onClick={goBack}>
      Zurück
    </NavigationButton>
  ) : null;

  // if nextStep is set, next button will link to the next step
  // if nextTitle is set, next button will behave like a submit button for a form
  // Otherwise if submitText it will behave like a submit button for a form
  const nextButton = nextStep ? (
    <NavigationButton type="button" variant="contained" color="bronze" onClick={goNext}>
      {nextTitle || 'Weiter'}
    </NavigationButton>
  ) : nextTitle ? (
    <FormNavigationButton type="submit" variant="contained" color="bronze">
      {nextTitle}
    </FormNavigationButton>
  ) : submitTitle ? (
    <FormButton type="submit" variant="contained" color="bronze">
      {submitTitle}
    </FormButton>
  ) : null;

  return (
    <Root>
      <ButtonsWrapper>
        {prevButton}
        <Content step={step}>{children}</Content>
        {nextButton}
      </ButtonsWrapper>
    </Root>
  );
};

import { action, computed, observable } from 'mobx';

export class TabsStore {
  constructor(activeItemId: number) {
    this.activeItemId = activeItemId;
  }

  @observable
  activeItemId = 0;

  @action
  setActiveItemId = (activeItemId: number) => {
    this.activeItemId = activeItemId;
  };

  @observable
  lineWidthList: number[] = [];

  @observable
  computedStepMargin = 60;

  @action
  setItemsWrapperElement = (itemsWrapperRef: HTMLDivElement) => {
    if (itemsWrapperRef.children) {
      this.lineWidthList = [...itemsWrapperRef.children].map((child, id) => {
        if (id === 0) {
          this.computedStepMargin = Number(window.getComputedStyle(child).marginRight.replace('px', ''));
        }
        return child.getBoundingClientRect().width;
      });
    }
  };

  @computed
  get lineWidthCurrent(): number {
    return this.lineWidthList[this.activeItemId] || 0;
  }

  @computed
  get lineShift(): number {
    return this.lineWidthList.reduce((shift, width, id) => {
      if (id < this.activeItemId) {
        return shift + width;
      }
      return shift;
    }, 0);
  }
}

import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';

import { Portal } from '../Portal';
import { Backdrop } from '../Backdrop';
import { Card } from '../Card';
import { Root, CardContainer } from './Modal.styled';
import { useEscape } from '../../hooks/useEscape';
import { useDelayToDetach } from '../../hooks/useDelayToDetach';
import { noop } from '../../utils/placeholders';
import { ButtonColor } from '../Button';

interface IModalProps {
  title?: string;
  isOpened: boolean;
  closable?: boolean;
  onClose?: () => unknown;
  className?: string;
  colorButton?: ButtonColor;
  width?: string;
  height?: string;
  noPadding?: boolean;
  onDetach?: () => unknown;
}

export const ModalWithoutPortal: React.FC<IModalProps> = ({
  title = '',
  isOpened = false,
  closable = true,
  onClose = noop,
  children,
  className = '',
  colorButton,
  width,
  height,
  noPadding,
}) => {
  const ref = useRef(null);

  const handleClose = React.useCallback(() => {
    if (closable) onClose();
  }, [closable, onClose]);

  return (
    <Root ref={ref}>
      <Backdrop isShow={isOpened} onClick={handleClose} />
      <CardContainer isShow={isOpened}>
        <Card
          title={title}
          hasCloseButton={closable}
          onClose={handleClose}
          className={className}
          colorButton={colorButton}
          width={width}
          height={height}
          noPadding={noPadding}
        >
          {children}
        </Card>
      </CardContainer>
    </Root>
  );
};

export const Modal: React.FC<IModalProps> = observer(
  ({
    title = '',
    isOpened = false,
    closable = true,
    onClose = noop,
    children,
    className = '',
    colorButton,
    width,
    height,
    noPadding,
    onDetach = noop,
  }) => {
    useEscape(onClose);

    const [opened, attached] = useDelayToDetach(isOpened, false, 400, onDetach);

    return attached ? (
      <Portal>
        <ModalWithoutPortal
          title={title}
          onClose={onClose}
          closable={closable}
          isOpened={opened}
          className={className}
          colorButton={colorButton}
          width={width}
          height={height}
          noPadding={noPadding}
        >
          {children}
        </ModalWithoutPortal>
      </Portal>
    ) : null;
  },
);

import { styled, css, colors, button, keyframes } from '../../themes';
import { SnackBarType } from './types';

export const Message = styled.div`
  flex: 1;
  align-self: center;
  margin-right: 70px;
`;

export const CloseButton = styled.div`
  align-self: center;
  width: 20px;
  flex-shrink: 0;
`;

export const IconWrapper = styled.div`
  height: 32px;
  width: 32px;
  margin-right: 19px;
`;

interface IRootProps {
  show: boolean;
  type: SnackBarType;
  autoDismissTime?: number;
  autoDismiss?: boolean;
}

const styleFromType = ({ type }: IRootProps) => {
  switch (type) {
    case SnackBarType.alert:
      return css`
        background: #eb5757;
      `;
    case SnackBarType.success:
      return css`
        background: #74ad77;
      `;
    case SnackBarType.warning:
      return css`
        background: #d88f6b;
      `;
    default:
      return css`
        background: ${colors.white};
        color: #737373;
      `;
  }
};

const statusLineAnimation = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

const dismissStatus = ({ autoDismissTime, autoDismiss }: IRootProps) =>
  autoDismiss
    ? css`
        &:before {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 2px;
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
          content: '';
          display: block;
          background: rgba(0, 0, 0, 0.2);
          animation: ${statusLineAnimation} ${autoDismissTime}ms linear;
        }
      `
    : '';

export const Root = styled.div<IRootProps>`
  margin: 10px;
  width: fit-content;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 19px;
  transition: opacity 400ms;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  ${button};
  line-height: 32px;
  color: ${colors.white};
  display: flex;
  position: relative;
  ${styleFromType}
  ${({ show }) =>
    show
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `};
  ${dismissStatus}
`;

export const SnackbarStack = styled.div`
  position: fixed;
  bottom: 20px;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

import React, { useEffect, useRef, useState } from 'react';

import { RoomItem } from '../../../../lib/core/api/generated';
import {
  useDropRoomItem,
  useRemoveRoomItem,
  useUpdateRoomItem,
} from '../../../../lib/core/repositories/room-repository';
import { getRandomProductImage } from '../../../../lib/utils/getRandomProductImage';
import { formatCurrency } from '../../../../lib/utils/format-number';
import { Container, CounterButton } from '../../../../lib/components';
import { Typography } from '../../../../lib/themes/typography/Typography';
import {
  TitleWrapper,
  ProductsTableStyled,
  TableBody,
  TableRow,
  TableCell,
  ProductImage,
  RemoveButton,
  TrashIconStyled,
  TableCellCurrency,
} from './ProductsTable.styled';

export interface IProductsTableProps {
  items: RoomItem[];
  isEditable: boolean;
  isAmountEditable?: boolean;
}

export const ProductsTable: React.FC<IProductsTableProps> = ({ items, isEditable, isAmountEditable = isEditable }) => {
  const [updateRoomItem] = useUpdateRoomItem();
  const [removeRoomItem] = useRemoveRoomItem();
  const [dropRoomItem] = useDropRoomItem();

  const [editedAmount, setEditedAmount] = useState<{ [roomItemId: string]: number }>({});
  const [isLoading, setIsLoading] = React.useState(false);

  const debounceAmountChangingRef = useRef<{ [id: string]: number | undefined }>({});

  useEffect(() => {
    return () => {
      Object.keys(debounceAmountChangingRef.current).forEach(roomItemId => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        window.clearTimeout(debounceAmountChangingRef.current[roomItemId]);
      });
    };
  }, []);

  const handleChangeAmount = ({ id: roomItemId, productConfiguration }: RoomItem) => (amount: number) => {
    setEditedAmount({ ...editedAmount, [roomItemId]: amount });

    const { current: debounceAmountChanging } = debounceAmountChangingRef;

    // We gonna debounce saving amount if user is a good clicker.
    window.clearTimeout(debounceAmountChanging[roomItemId]);

    debounceAmountChanging[roomItemId] = window.setTimeout(() => {
      debounceAmountChanging[roomItemId] = undefined;
      updateRoomItem({
        roomItemId,
        productConfigurationSku: productConfiguration.sku,
        amount,
      });
    }, 500);
  };

  const handleRemoveProduct = React.useCallback(
    (roomItem: RoomItem) => () => {
      setIsLoading(true);
      if (roomItem.productSetId) {
        // product from initial items
        return dropRoomItem({ roomItemId: roomItem.id }).finally(() => setIsLoading(false));
      }
      // product from catalog, permanent remove it
      return removeRoomItem({ roomItemId: roomItem.id }).finally(() => setIsLoading(false));
    },
    [dropRoomItem, removeRoomItem],
  );

  const renderTableRow = (item: RoomItem) => {
    const {
      id,
      productConfiguration: { imageUrls, name, price },
      amount,
    } = item;

    return (
      <TableRow key={id}>
        <TableCell>
          <ProductImage src={imageUrls[0] || getRandomProductImage(item.id)} alt={name} />
        </TableCell>
        <TableCell>{name}</TableCell>
        <TableCellCurrency>{formatCurrency(price || 0)}</TableCellCurrency>
        <TableCell>
          {isAmountEditable ? (
            <CounterButton value={editedAmount[id] || amount} mode="plain" onChange={handleChangeAmount(item)} />
          ) : (
            `${amount} Stück`
          )}
        </TableCell>
        <TableCellCurrency>{formatCurrency((price || 0) * (editedAmount[id] || amount))}</TableCellCurrency>
        {isEditable && (
          <TableCell>
            <RemoveButton onClick={handleRemoveProduct(item)} disabled={isLoading}>
              <TrashIconStyled />
            </RemoveButton>
          </TableCell>
        )}
      </TableRow>
    );
  };

  return (
    <Container>
      <TitleWrapper>
        <Typography tag="h2" variant="h1">
          Produkte
        </Typography>
      </TitleWrapper>
      <ProductsTableStyled>
        <TableBody>{items.filter(({ added }) => added).map(renderTableRow)}</TableBody>
      </ProductsTableStyled>
    </Container>
  );
};

import React from 'react';
import { observer } from 'mobx-react-lite';
import { useDelayToDetach } from '../../hooks/useDelayToDetach';
import { Root } from './Skeleton.styled';
import { default as ContentLoader, IContentLoaderProps } from 'react-content-loader';

interface ISkeletonProps extends IContentLoaderProps {
  isShowed?: boolean;
}

export const Skeleton: React.FC<ISkeletonProps> = observer(({ isShowed, children, ...other }) => {
  const [showed, attached] = useDelayToDetach(isShowed || false, true);
  return attached ? (
    <Root isShowed={showed}>
      <ContentLoader {...other}>{children}</ContentLoader>
    </Root>
  ) : null;
});

import React from 'react';

import { Page, PageSection, PageFooter, Container, Header } from '../../../lib/components';
import { ForMembersOnlySlider, RoomsType } from '../components';
import { SubscriptionBlock } from './SubscriptionBlock';
import { DesignStyles } from '../components/DesignStyles';
import { VideoPlayer } from '../../../lib/components/VideoPlayer';
import projectsMp4 from '../../account/containers/ProjectsPage/assets/projects.mp4';

export const AboutPage = () => (
  <Page>
    <Header backHref={'/'} backButtonTitle={'Zurück'} />

    <PageSection>
      <Container>
        <ForMembersOnlySlider />
      </Container>
    </PageSection>

    {/*<PageSection>*/}
    {/*  <Container>*/}
    {/*    <div style={{ overflow: 'hidden', height: 637 }}>*/}
    {/*      <VideoPlayer*/}
    {/*        srcList={[{ src: projectsMp4, type: 'video/mp4' }]}*/}
    {/*        poster="/img/accountPage/poster.png"*/}
    {/*        color="black"*/}
    {/*      />*/}
    {/*    </div>*/}
    {/*  </Container>*/}
    {/*</PageSection>*/}

    <PageSection>
      <Container>
        <RoomsType />
      </Container>
    </PageSection>

    <PageSection>
      <DesignStyles />
    </PageSection>

    <PageSection>
      <Container>
        <SubscriptionBlock />
      </Container>
    </PageSection>

    <PageFooter />
  </Page>
);

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ProductConfiguration, Room } from '../../../../lib/core/api/generated';
import {
  useAddRoomItem,
  useDropRoomItem,
  useRemoveRoomItem,
  useUpdateRoomItem,
} from '../../../../lib/core/repositories/room-repository';
import { ExpansionPanel, SideSheet } from '../../../../lib/components';
import { ProductCard } from '../../../../lib/components/ProductCard';

import { FurnitureExpansionPanelHead } from './FurnitureExpansionPanelHead';
import { getCategoryIcon } from '../../categoryIcons';
import { ProductDetails } from '../../containers/ProductDetails';
import { IRoomProduct } from '../../containers/ConfiguratorItemsRootStep/FurnitureEditorPage/hooks/products-hooks';

import { FurnitureProductsWrapper } from './FurnitureExpansionPanels.styled';
import { useIsInvitedUser } from '../../../../lib/core/hooks/useIsInvitedUser';

interface IFurnitureExpansionPanelsProps {
  room: Room;
  roomProducts: IRoomProduct[];
}

interface IProductsBySolutions {
  id: string;
  name: string;
  roomProducts: IRoomProduct[];
}

export const FurnitureExpansionPanels: React.FC<IFurnitureExpansionPanelsProps> = ({ room, roomProducts }) => {
  const [openedPanel, setOpenedPanel] = useState<string | null>(null);
  const [editedAmount, setEditedAmount] = useState<{ [roomItemId: string]: number }>({});
  const [productToDetail, setProductToDetail] = useState<IRoomProduct | null>(null);
  const [addRoomItem] = useAddRoomItem();
  const [dropRoomItem] = useDropRoomItem();
  const isInvitedUser = useIsInvitedUser();
  const [removeRoomItem] = useRemoveRoomItem();
  const [updateRoomItem] = useUpdateRoomItem();

  const debounceAmountChangingRef = useRef<{ [id: string]: number | undefined }>({});

  useEffect(() => {
    return () => {
      Object.keys(debounceAmountChangingRef.current).forEach(roomItemId => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        window.clearTimeout(debounceAmountChangingRef.current[roomItemId]);
      });
    };
  }, []);

  const handleOpenPanel = (name: string) => () => {
    setOpenedPanel(name);
  };

  const handleChangeAmount = useCallback(
    ({ id: roomItemId, productConfiguration }: IRoomProduct) => (amount: number) => {
      const { current: debounceAmountChanging } = debounceAmountChangingRef;
      setEditedAmount({
        ...editedAmount,
        [roomItemId]: amount,
      });

      window.clearTimeout(debounceAmountChanging[roomItemId]);

      debounceAmountChanging[roomItemId] = window.setTimeout(() => {
        debounceAmountChanging[roomItemId] = undefined;
        updateRoomItem({
          roomItemId,
          productConfigurationSku: productConfiguration.sku,
          amount,
        });
      }, 500);
    },
    [editedAmount, updateRoomItem],
  );

  const handleClickToggleInListButton = (product: IRoomProduct) => (e: any) => {
    if (product.added) {
      if (product.productSetId) {
        // product from initial items
        return dropRoomItem({ roomItemId: product.id });
      }
      // product from catalog, permanent remove it
      return removeRoomItem({ roomItemId: product.id });
    }

    return addRoomItem({ roomItemId: product.id });
  };

  const handleOpenDetails = (_roomItem: IRoomProduct) => () => {
    setProductToDetail(_roomItem);
  };

  const handleOnCloseMergedProductDetail = () => {
    setProductToDetail(null);
  };

  const handleOnProductDetailDone = (roomItem: IRoomProduct) => (
    productConfiguration: ProductConfiguration,
    amount: number,
  ): Promise<unknown> => {
    const { id: roomItemId } = roomItem;
    return updateRoomItem({
      roomItemId,
      productConfigurationSku: productConfiguration.sku,
      amount,
    }).then(() => {
      setEditedAmount({ ...editedAmount, [roomItemId]: amount });
      // Close the SideSheet with ProductToDetail
      setProductToDetail(null);
    });
  };

  const productsByCategories: IProductsBySolutions[] = useMemo<IProductsBySolutions[]>(
    () =>
      roomProducts
        .filter(({ added }) => added)
        .reduce((acc, roomProduct) => {
          roomProduct.solutions.forEach(solution => {
            const productsBySolution: IProductsBySolutions | undefined = acc.find(({ id }) => id === solution.id);
            if (!productsBySolution) {
              acc.push({
                id: solution.id,
                name: solution.name,
                roomProducts: [roomProduct],
              });
            } else {
              productsBySolution.roomProducts.push(roomProduct);
            }
          });

          return acc;
        }, [] as IProductsBySolutions[]),
    [roomProducts],
  );

  // const isEditable = room.planMode === RoomPlanMode.LetMePlan;
  return (
    <>
      {productsByCategories.map(({ id, name, roomProducts }) => (
        <ExpansionPanel
          key={id}
          onChange={handleOpenPanel(id)}
          show={openedPanel === id}
          summary={
            <FurnitureExpansionPanelHead title={name} icon={getCategoryIcon(id)} productsCount={roomProducts.length} />
          }
        >
          <FurnitureProductsWrapper>
            {roomProducts.map(roomItem => (
              <ProductCard
                productConfiguration={roomItem.productConfiguration}
                amount={editedAmount[roomItem.id] || roomItem.amount}
                isInList={roomItem.added}
                isEditable={!isInvitedUser}
                isAmountEditable={!isInvitedUser}
                onChangeAmount={handleChangeAmount(roomItem)}
                onClickDetailsButton={handleOpenDetails(roomItem)}
                onClickToggleInListButton={handleClickToggleInListButton(roomItem)}
                key={roomItem.id}
              />
            ))}
          </FurnitureProductsWrapper>
        </ExpansionPanel>
      ))}

      <SideSheet isOpened={Boolean(productToDetail)} onClose={handleOnCloseMergedProductDetail}>
        {productToDetail && (
          <ProductDetails
            productConfiguration={productToDetail.productConfiguration}
            productConfigurations={
              productToDetail.productConfiguration?.configurableProduct?.productConfigurations || []
            }
            productSetId={productToDetail.productSetId || null}
            isEditable={!isInvitedUser}
            isAmountEditable={!isInvitedUser}
            amount={editedAmount[productToDetail.id] || productToDetail.amount}
            onDone={handleOnProductDetailDone(productToDetail)}
          />
        )}
      </SideSheet>
    </>
  );
};

import React, { useEffect } from 'react';
import { useField, useFormikContext } from 'formik';

import { Button } from '../../../../lib/components/Button';
import { UploadIcon } from '../../../../lib/icons/UploadIcon';
import { TrashIcon } from '../../../../lib/icons';

import { Container, Buttons, FileInput, Img, Info, Text } from './AccountImage.styled';

interface IAccountImageProps {
  name: string;
  defaultImage: string;
}

export function isFile(file: any): file is File {
  return Boolean(file && file.lastModified);
}

export const AccountImage: React.FC<IAccountImageProps> = ({ name, defaultImage }) => {
  const [{ value: file }] = useField<string | File>(name);

  const [fileUpload, setFileUpload] = React.useState<string>(
    isFile(file) ? URL.createObjectURL(file) : file || defaultImage,
  );

  const [isTooLarge, setIsTooLarge] = React.useState<boolean>(false);
  const [isTooBig, setIsTooBig] = React.useState<boolean>(false);
  const [isInvalidFormat, setIsInvalidFormat] = React.useState<boolean>(false);

  const { setFieldValue, setFieldError } = useFormikContext();

  const handleChangeFile = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const _file = event.currentTarget.files![0];
      setFieldValue(name, _file);
    },
    [name, setFieldValue],
  );

  useEffect(() => {
    const img = new Image();
    function onload() {
      if (img.width > 800 || img.height > 800) {
        setIsTooBig(true);
      } else {
        setIsTooBig(false);
      }
    }
    img.addEventListener('load', onload);

    if (isFile(file)) {
      if (!['image/jpeg', 'image/png'].includes(file.type)) {
        setIsInvalidFormat(true);
        setIsTooLarge(false);
        setIsTooBig(false);
      } else {
        setIsInvalidFormat(false);
        setFileUpload(URL.createObjectURL(file));

        img.src = URL.createObjectURL(file);

        // 1mb
        if (file.size > 1 * 1024 * 1024) {
          setIsTooLarge(true);
        } else {
          setIsTooLarge(false);
        }
      }
    } else {
      setFileUpload(file || defaultImage);
    }
    return () => {
      img.removeEventListener('load', onload);
    };
  }, [defaultImage, file]);

  useEffect(() => {
    if (isTooBig || isInvalidFormat || isTooLarge) {
      setFieldError(name, 'Invalid image');
    }
  }, [isInvalidFormat, isTooLarge, isTooBig, setFieldError, name]);

  const handleOnDeleteImageButton = () => {
    setFieldValue(name, null);
  };

  return (
    <div
      css={`
        display: flex;
      `}
    >
      <Img src={fileUpload} />

      <Container>
        <Info>
          <Text isInvalid={isInvalidFormat}>Format: jpg, png.</Text>
          <Text isInvalid={isTooLarge}>Maximale Dateigröße: 1Mb.</Text>
          <Text isInvalid={isTooBig}>Empfohlene Größe: 800 x 800 px.</Text>
        </Info>
        <Buttons>
          <Button variant="contained" color="bronze" iconStart={<UploadIcon />}>
            Logo hochladen
            <FileInput type="file" name="file" onChange={handleChangeFile} />
          </Button>
          <Button variant="text" color="bronze" onClick={handleOnDeleteImageButton} iconStart={<TrashIcon />}>
            Logo löschen
          </Button>
        </Buttons>
      </Container>
    </div>
  );
};

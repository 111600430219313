import React from 'react';

import { IDesignStyleProps, TDesignStyleUrn } from '../../types';
import { Tabs, Container, FlexContainer } from '../../../../lib/components';
import { Typography } from '../../../../lib/themes/typography/Typography';
import {
  Root,
  BlockTitleStyled,
  Description,
  TabsWrapper,
  TabContent,
  TabTitle,
  TabDescription,
  Benefit,
  BenefitImage,
} from './DesignStyles.styled';
import { tabs, roomDesignStyles } from './fixtures';

const items = tabs.map(tab => ({ name: tab.label, value: tab.urn }));

export const DesignStyles: React.FC = () => {
  const [selectedItemUrn, setSelectedItemUrn] = React.useState<TDesignStyleUrn>('startup');
  const [selectedContent, setSelectedContent] = React.useState<IDesignStyleProps>(roomDesignStyles.startup);

  const handleChangeItem = React.useCallback((urn: string) => {
    setSelectedItemUrn(urn as TDesignStyleUrn);
    setSelectedContent(roomDesignStyles[urn]);
  }, []);

  const { title, description, benefits } = selectedContent;

  return (
    <Root>
      <Container>
        <BlockTitleStyled>Ausgewählte Design-Stile für Ihr Büro</BlockTitleStyled>
        <Description>
          <Typography tag="p" variant="h5">
            Jedes Team hat unterschiedliche Bedürfnisse. Wir helfen Ihnen mit einer Selektion, die für Ihre Branche /
            Ihr Unternehmen richtige Wahl zu treffen
          </Typography>
        </Description>
        <TabsWrapper>
          <Tabs items={items} onChange={handleChangeItem} value={selectedItemUrn} />
        </TabsWrapper>
        <TabContent>
          <TabTitle>
            <Typography tag="h3" variant="h3">
              {title}
            </Typography>
          </TabTitle>
          <TabDescription>
            <Typography tag="p" variant="h5" dangerouslySetInnerHTML={{ __html: description }} />
          </TabDescription>
          <FlexContainer>
            {benefits.map(({ photo, description }, index) => (
              <Benefit key={index + 1}>
                <BenefitImage src={photo} alt="" />
                <Typography tag="p" variant="h5">
                  {description}
                </Typography>
              </Benefit>
            ))}
          </FlexContainer>
        </TabContent>
      </Container>
    </Root>
  );
};

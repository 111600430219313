import { colors, styled } from '../../../../lib/themes';
import { StyledNavigationButton } from '../../../../lib/components/Navigation/NavigationButton/NavigationButton.styled';
import { BlockTitle } from '../../../../lib/components/Block/BlockTitle';

export const Root = styled.div`
  text-align: center;
`;

export const BlockTitleStyled = styled(BlockTitle)`
  margin-bottom: 44px;
`;

export const Description = styled.div`
  margin: 0 auto 56px;
  max-width: 780px;
`;

export const PackageInfo = styled.div`
  position: absolute;
  z-index: 2;
  top: 60px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  width: 445px;
  min-height: 480px;
  padding: 65px 45px 65px 65px;

  background-color: ${colors.white};

  ${StyledNavigationButton} {
    margin-top: auto;
    justify-self: flex-end;
  }
`;

export const TabsWrapper = styled.div`
  border-bottom: 1px solid ${colors.grayLighter};
`;

export const TabContent = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
`;

export const TabTitle = styled.div`
  margin-bottom: 40px;
  text-transform: uppercase;
`;

export const TabDescription = styled.div`
  margin-bottom: 60px;
`;

export const Benefit = styled.div`
  width: calc(50% - 15px);

  &:first-child {
    margin-right: 15px;
  }

  &:last-child {
    margin-left: 15px;
  }

  & > * {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
`;

export const BenefitImage = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
`;

export const PackageWrapper = styled.div`
  position: relative;
`;

import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';

import { StyledMenuItem } from './MenuItem.styled';
import { menuContext } from '../Menu';

type IMenuItemProps =
  | {
      /**
       * If passed onClick will be called on click,
       * and if returned true will not close the menu
       */
      onClick: () => true | unknown; // TRUE - don't close the menu
    }
  | {
      /**
       * If passed, will route to the URL on click
       */
      URL: string;
    };

/**
 * Auto-closes the menu on click on the item
 * @param props
 * @constructor
 */
export const MenuItem: React.FC<IMenuItemProps> = props => {
  const { children } = props;
  const { onClick, URL } = props as any;
  const context = useContext(menuContext);
  const history = useHistory();

  const onClickCallback = useCallback(() => {
    if (onClick) {
      const keepOpen = onClick();
      if (keepOpen !== true) {
        context.close();
      }
    }
    if (URL) {
      history.push(URL);
      context.close();
    }
  }, [history, onClick, URL, context]);

  return (
    <StyledMenuItem data-hook-menu="MenuItem" onClick={onClickCallback}>
      {children}
    </StyledMenuItem>
  );
};

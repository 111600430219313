export const slides = [
  {
    image: require('./assets/begin.jpg').default,
    title: 'Starten Sie ein Projekt.',
    description: `Erstellen Sie ein Projekt mit einem passenden Namen: zum Beispiel "Office Düsseldorf",<br />"Co-Working&nbsp;Berlin"&nbsp;o.&nbsp;ä.`,
  },
  {
    image: require('./assets/room.jpg').default,
    title: 'Erstellen Sie einen Raum.',
    description: `Bestimmen Sie den ersten Raum Ihres Projekts, zum Beispiel einen Arbeitsraum, Meetingraum oder Großraumbüro.`,
  },
  {
    image: require('./assets/configuration.jpg').default,
    title: 'Konfigurieren Sie den Raum.',
    description: `Gestalten Sie den Raum in wenigen Schritten. Der Assistent hilft Ihnen dabei.`,
  },
  {
    image: require('./assets/finito.jpg').default,
    title: 'Geschafft. Klasse!',
    description: `Ihr Raum ist fertig und wurde bereits in eine PDF-Präsentation mit einer Kostenübersicht übertragen.`,
  },
  {
    image: require('./assets/end.jpg').default,
    title: 'Stimmen Sie das Ergebnis ab.',
    description: `Teilen Sie das Projekt mit anderen Beteiligten zur gegenseitigen Freigabe - wir erledigen den Rest.`,
  },
];

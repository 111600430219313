import { NotSignedInError } from '../errors/not-signed-in';
import { action, computed, observable } from 'mobx';
import { Grant, Mutation, MutationLoginArgs, Query } from '../api/generated';
import { gqlDocsAuthRegister } from '../gql-documents/gql-auth-register';
import { ApiRepository } from './api-repository';
import { sha256 } from 'js-sha256';
import { useObserver } from 'mobx-react-lite';
import { RequireApolloStore, RequireAuthRepository, RequireCommonStorageStore, RequireLog } from '../available-stores';
import { useMutation, UseMutationResult, useStore } from '../hooks/use-store';
import { MainStore } from '../main-store';
import { routeStore } from '../../router';
import { initialPathStore } from '../other-stores/Initial-path-store';

function isGrant(a: any): a is Grant {
  return Object(a) === a && a.accessToken && a.refreshToken;
}

export class AuthRepository extends ApiRepository<
  Query,
  Mutation,
  RequireApolloStore & RequireLog & RequireCommonStorageStore
> {
  constructor(mainStore: MainStore<RequireApolloStore & RequireLog & RequireCommonStorageStore>) {
    super(mainStore, 'AuthRepository');
    const commonStorageStore = this.getStore('CommonStorageStore');
    const lastGrant = commonStorageStore.get('lastGrant');
    const lastUsername = commonStorageStore.get('lastUsername');
    if (isGrant(lastGrant) && lastUsername) {
      this.processLogin(lastGrant, lastUsername);
    }
  }

  private _uniq: string | undefined; // unique key for signed-in user to save his settings

  @observable
  private _grantData: Grant | undefined;

  /**
   * Whether we signed-in under any user or not
   * @return {boolean}
   */
  @computed
  get hasAuth(): boolean {
    return !!this._grantData && !!this._grantData.accessToken;
  }

  /**
   * Return current observable accessTOken
   */
  @computed
  get accessToken(): string | undefined {
    return this._grantData && (this._grantData.accessToken || undefined);
  }

  @computed
  get refreshToken(): string {
    return this._grantData ? this._grantData.refreshToken || '' : '';
  }
  /**
   * Do sign-in request to API, and remebers returned data
   * @param {MutationLoginArgs} variables
   * @return {Promise<Grant>}
   */
  signIn = (variables: MutationLoginArgs): Promise<Grant> => {
    return this.mutate(gqlDocsAuthRegister.login, 'login', variables).then((data: Grant) =>
      this.processLogin(data, variables.username),
    );
  };

  /**
   * Sign out of current session
   * Also clears all repositories on successful operation
   */
  signOut = (): Promise<boolean> => {
    return this.mutate(gqlDocsAuthRegister.logout, 'logout', { refreshToken: this.refreshToken })
      .catch(() => true) // in case of error do the same
      .then((result: boolean) => {
        this.clearAuth();
        return result;
      });
  };

  /**
   * Clear all auth data, logical logout for the client
   * For real logout, please use SignOut method
   */
  @action
  clearAuth() {
    initialPathStore.saveInitialPathName();
    this._mainStore.clearStores();
    // routeStore has not yet synchronised with window.location here
    if (window.location.pathname !== '/') {
      routeStore.history.push({
        pathname: '/',
        search: '?showLogin=true',
      });
    }
  }

  /**
   * Helper for apolloStore.customFetch
   * @param data
   */
  @action
  consumeRefreshData(data: any) {
    this.processGrant(data);
  }

  /**
   * Just clears refresh token, used by apollo-store to avoid neverending requesting refresh tokens
   */
  clearRefreshToken() {
    if (this._grantData) {
      this._grantData.refreshToken = '';
    }
  }

  /**
   * Returns some kind of unique key for current auth
   * @return {string}
   */
  getUniqKey() {
    if (!this._uniq) {
      throw new NotSignedInError();
    }

    return this._uniq;
  }

  clear() {
    this._uniq = undefined;
    this._grantData = undefined;
    this.getStore('CommonStorageStore').removeItem('lastGrant');
  }

  @action
  private processLogin(grant: Grant, username: string): Grant {
    const hash = sha256.create();
    hash.update(username);
    this._uniq = hash.hex();
    this.getStore('CommonStorageStore').set('lastUsername', username);
    this.processGrant(grant);
    return grant;
  }

  private processGrant(grant: Grant): Grant {
    this._grantData = grant;
    this.getStore('CommonStorageStore').set('lastGrant', grant);
    return grant;
  }
}

/**
 * Hook for re-rendering when user change stage to/from signed-in
 * usage in render:
 *   return useHasAuth((hasAuth) => <div>{`Has auth: ${hasAuth}`}</div>)
 */
export function useHasAuth<T>(fnComponent: (hasAuth: boolean) => T): T {
  const authRepository = useStore<RequireAuthRepository>('AuthRepository');
  return useObserver(() => fnComponent(authRepository.hasAuth));
}

/**
 * Use this hook in signIn form
 * @return {UseMutationResult<MutationLoginArgs, Grant>}
 */
export function useSignIn(): UseMutationResult<MutationLoginArgs, Grant> {
  const authRepository = useStore<RequireAuthRepository>('AuthRepository');
  return useMutation(authRepository.signIn);
}

export function userSignOut(): UseMutationResult<void, boolean> {
  const authRepository = useStore<RequireAuthRepository>('AuthRepository');
  return useMutation(authRepository.signOut);
}

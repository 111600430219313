import { ApiRepository } from './api-repository';
import {
  Account,
  Mutation,
  Query,
  User,
  MutationUpdateAddressArgs,
  MutationUpdateEmailArgs,
  MutationUpdateAccountArgs,
  Address,
  MutationUpdatePasswordArgs,
  MutationUpdateUserArgs,
} from '../api/generated';
import { RequireApolloStore, RequireLog, RequireUserRepository } from '../available-stores';
import { MainStore } from '../main-store';
import { gqlDocsUser } from '../gql-documents/gql-users';
import { useMutation, UseMutationResult, useQuery, UseQueryResult, useStore, useWatchQuery } from '../hooks/use-store';
import { routeStore } from '../../router';
import { CoreError } from '../errors/core-error';
import { ObservableQuery } from 'apollo-client';
import { UserRole } from '../hooks/useIsInvitedUser';
import { initialPathStore } from '../other-stores/Initial-path-store';

export class UserRepository extends ApiRepository<Query, Mutation> {
  constructor(mainStore: MainStore<RequireApolloStore & RequireLog>) {
    super(mainStore, 'UserRepository');
  }

  me = (): Promise<User> => {
    return this.query(gqlDocsUser.me, 'me');
  };

  watchMe = (): ObservableQuery<Query['me'], void> => {
    return this.watchQuery<Query['me'], void>(gqlDocsUser.me);
  };

  /**
   * This is weird, but the only way to obtain user's account for now
   */
  currentAccount = async (): Promise<Account | undefined> => {
    const me = await this.me();
    return getCurrentAccount(me) || undefined;
  };

  /**
   * Helper to decide where to re-route after successful sign-in
   * Why here? Because we rather keep AuthRepo less bound to other repos
   * And this repo already has all needed data
   */
  routeAfterSignIn = async () => {
    const currentAccount = await this.currentAccount();
    if (currentAccount) {
      if (initialPathStore.resumeInitialPathName()) {
        routeStore.history.push(`/accounts/${currentAccount.id}`);
      }
    } else {
      throw new CoreError(`No accounts found for current user`);
    }
  };

  updatePassword = (variables: MutationUpdatePasswordArgs): Promise<boolean> => {
    return this.mutate(gqlDocsUser.updatePassword, 'updatePassword', variables);
  };

  // Update Address
  updateAddress = (variables: MutationUpdateAddressArgs): Promise<User> => {
    return this.mutate(gqlDocsUser.updateAddress, 'updateAddress', variables);
  };

  // Update a user
  updateUser = (variables: MutationUpdateUserArgs): Promise<User> => {
    return this.mutate(gqlDocsUser.updateUser, 'updateUser', variables);
  };

  // Update email
  updateEmail = (variables: MutationUpdateEmailArgs): Promise<string> => {
    return this.mutate(gqlDocsUser.updateEmail, 'updateEmail', variables);
  };

  // Update account
  updateAccount = (variables: MutationUpdateAccountArgs): Promise<Account> => {
    return this.mutate(gqlDocsUser.updateAccount, 'updateAccount', variables, { refetchQueries: ['me'] });
  };
}

export function useMe(): UseQueryResult<void, User> {
  return useQuery(useStore<RequireUserRepository>('UserRepository').me, undefined);
}

export function useWatchMe(): UseQueryResult<void, User> {
  return useWatchQuery(useStore<RequireUserRepository>('UserRepository').watchMe, 'me', undefined);
}

/**
 * This is weird, but the only way to obtain user's account for now
 * DO NOT use runRequest return value
 * Also, keep in mind this function is static, its result is not auto-updated in case of account/me/atc updates
 * If you need your component to dynamically react on account/me changes, please consider useWatchCurrentAccount
 */
export function useCurrentAccount(): UseQueryResult<void, Account> {
  const [me, loadState, runRequest] = useMe();
  // Don't ask me why
  const currentAccount = getCurrentAccount(me);
  return [currentAccount, loadState, runRequest as any]; // Don't use runRequest return value
}

/**
 * This is watching version of useCurrentAccount hook
 * Use this in cases, when you don't care about extra re-rendering cycles, but need
 * account data to be reactive on it's changes
 */
export function useWatchCurrentAccount(): UseQueryResult<void, Account> {
  const [me, loadState, runRequest] = useWatchMe();
  // Don't ask me why
  const currentAccount = getCurrentAccount(me);
  return [currentAccount, loadState, runRequest as any]; // Don't use runRequest return value
}

/**
 * This is weird, but the only way to obtain user's account for now
 * Don't use runRequest return value, please
 */
export function useBillingAddress(): UseQueryResult<void, Address> {
  const [me, loadState, runRequest] = useMe();
  // Don't ask me why
  const billingAddress: Address | null = me && me.addresses.length ? me.addresses[me.addresses.length - 1] : null;
  return [billingAddress ? billingAddress : null, loadState, runRequest as any]; // Don't use runRequest return value
}

/**
 * Update a password
 */
export function useUpdatePassword(): UseMutationResult<MutationUpdatePasswordArgs, boolean> {
  const userRepository = useStore<RequireUserRepository>('UserRepository');
  return useMutation(userRepository.updatePassword);
}

// Update Address User
export function useUpdateAddress(): UseMutationResult<MutationUpdateAddressArgs, User> {
  const userRepository = useStore<RequireUserRepository>('UserRepository');
  return useMutation(userRepository.updateAddress);
}

// Update a full user
export function useUpdateUser(): UseMutationResult<MutationUpdateUserArgs, User> {
  const userRepository = useStore<RequireUserRepository>('UserRepository');
  return useMutation(userRepository.updateUser);
}

// Update email
export function useUpdateEmail(): UseMutationResult<MutationUpdateEmailArgs, string> {
  const userRepository = useStore<RequireUserRepository>('UserRepository');
  return useMutation(userRepository.updateEmail);
}

// Update account
export function useUpdateAccount(): UseMutationResult<MutationUpdateAccountArgs, Account> {
  const userRepository = useStore<RequireUserRepository>('UserRepository');
  return useMutation(userRepository.updateAccount);
}

export function getCurrentAccount(me?: User | null): Account | null {
  // todo this may not be the right way to get a current account
  return (
    me?.memberships.find(membership => membership.role !== UserRole.Executive)?.account ||
    me?.memberships[0].account ||
    null
  );
}

import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { Redirect } from '../../../../lib/router/redirect';
import { routePrefix } from '../../../../lib/router/route-prefix';
import { CError, Container } from '../../../../lib/components';
import { ProjectPage } from '../ProjectsPage';
import { SettingsPage } from '../SettingsPage';
import { Footer } from '../../components';
import { useMe, useWatchCurrentAccount } from '../../../../lib/core/repositories/user-repository';
import { On } from '../../../../lib/core/hooks/on';
import { ProfilePage } from '../ProfilePage';
import { Head, Page, UserName, UserWrapper, CompanyImg, UserInfo, CompanyName } from './AccountPage.styled';
import { AccountHeader } from './components/AccountHeader';
import { Loader } from '../../../../lib/components/Loader';

export const AccountPage: React.FC<RouteComponentProps> = ({ children }) => {
  return On(
    (user, account) => (
      <Page>
        <AccountHeader />
        <Container>
          <Head>
            <UserWrapper>
              <CompanyImg src={account.companyImageUrl || '/img/accountPage/default-img.svg'} />
              <UserInfo>
                <CompanyName data-hook-name="CompanyName">{account.name}</CompanyName>
                <UserName data-hook-name="FullName">
                  {user.firstName}&nbsp;{user.lastName}
                </UserName>
              </UserInfo>
            </UserWrapper>
          </Head>
          <React.Suspense fallback={<div />}>
            <Switch>
              <Route path={`${routePrefix}/accounts/:accountId/projects`} component={ProjectPage} />
              <Route path={`${routePrefix}/accounts/:accountId/profile`} component={ProfilePage} />
              <Route path={`${routePrefix}/accounts/:accountId/settings`} component={SettingsPage} />
              <Redirect to={`${routePrefix}/accounts/:accountId/projects`} />
            </Switch>
          </React.Suspense>
        </Container>
        <Footer expiresAt={account.expiresAt} />
      </Page>
    ),
    () => <Loader />,
    errorState => <CError error={errorState} />,
    useMe(),
    useWatchCurrentAccount(),
  );
};

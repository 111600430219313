import * as React from 'react';

import { CoreError } from '../../../core/errors/core-error';
import { StyledFormError } from './FormError.styled';
import { ReactNode } from 'react';

export type ErrorContextValue = { hr: string | ReactNode; error: CoreError } | {};

export function hasError(a: ErrorContextValue): a is { hr: string; error: CoreError } {
  return a && (a as any).hr !== undefined && (a as any).error;
}

export const formErrorContext = React.createContext<ErrorContextValue>({});

export const FormErrorProvider = formErrorContext.Provider;
export const FormErrorConsumer = formErrorContext.Consumer;

/**
 * TODO: Add styles when design will be available
 * Renders a form submit error if any happened
 */
export const FormError: React.FC = () => {
  const errorContextValue = React.useContext<ErrorContextValue>(formErrorContext);
  return hasError(errorContextValue) ? <StyledFormError>{errorContextValue.hr}</StyledFormError> : null;
};

import config from 'config';

const {
  gtag: { targetId },
} = config;

interface IEvent {
  category: string;
  action: string;
  label?: string;
  value?: string;
}

export const analytics = {
  event({ category, action, label, value }: IEvent) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    window.gtag('event', action, { event_category: category, event_label: label, value });
  },

  view(pagePath: string) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    window.gtag('config', targetId, { page_path: pagePath });
  },
};

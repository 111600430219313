import React from 'react';
import { Root, Content } from './ConfirmModalContent.styled';
import { Typography } from '../../../../../../../lib/themes/typography/Typography';

export const ConfirmModalContent: React.FC = () => {
  return (
    <Root>
      <img src="http://cms-dev.furnished.de/site/assets/files/2308/crowd.svg" alt="Mitarbeiter im Büro" />
      <Content>
        <Typography tag="h1" variant="h2">
          Sind Sie sicher?
        </Typography>
        <Typography
          tag="p"
          variant="button"
          css={`
            text-align: center;
          `}
        >
          Um effizient, sicher und gesund arbeiten zu können, wird ein ausreichend großer Arbeitsplatz und Büroraum
          benötigt. Ungehinderte Bewegung auf den Wegen zu Schränken, Türen und Fenstern ist dabei wichtig, ebenso viel
          Bewegungsfreiheit am Arbeitstisch und die Möglichkeit zum Wechsel der Körperhaltungen.
        </Typography>
        <Typography
          tag="p"
          variant="button"
          css={`
            text-align: center;
          `}
        >
          So sollte nach Vorgabe der DIN-Norm zur Ergonomie am Arbeitsplatz jeder Beschäftigte mindestens 1,5 m²
          unverstellte Bodenfläche zur Verfügung haben. Die von Ihnen ausgewählten Parameter unterschreiten diesen Wert.
          Möchten Sie trotzdem fortfahren?
        </Typography>
      </Content>
    </Root>
  );
};

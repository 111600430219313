/**
 * Put here all you need to store for all users in local Storage
 */
import { useCommonStorageValueTemplate } from './other-stores/common-storage-store';
import { Grant } from './api/generated';

export interface CommonStoredData {
  lastUsername: string;
  lastGrant: Grant;
  isNotFirstVisit: boolean;
}

/**
 * Use this hook to obtain real value from CommonStoredData
 */
export const useCommonStorageValue: <
  T extends CommonStoredData,
  P extends keyof CommonStoredData = keyof CommonStoredData
>(
  key: P,
) => [CommonStoredData[P] | undefined, (value: CommonStoredData[P]) => void] = useCommonStorageValueTemplate;

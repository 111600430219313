import { Room, RoomPlanMode, RoomStatus } from '../../lib/core/api/generated';
import { AllRoomSteps, RoomConfiguratorStep, RoomItemsStep, RoomParamsStep } from './typesUtils';
import { ITabItem } from '../../lib/components/Navigation/Tabs';

const basicSteps = [AllRoomSteps.RoomStyle, AllRoomSteps.RoomParams];

/**
 * Possible steps for let me plan
 */
const letMePlanSteps = [
  ...basicSteps,
  AllRoomSteps.RoomItemsEditor,
  AllRoomSteps.RoomItemsViewer,
  AllRoomSteps.RoomSummary,
];

/**
 * Possible steps for plan for me
 */
const planForMeSteps = [...basicSteps, AllRoomSteps.RoomItemsViewer, AllRoomSteps.RoomSummary];

const stepNames = {
  [RoomParamsStep.PlanType]: '',
  [RoomParamsStep.RoomStyle]: 'Raumtyp',
  [RoomParamsStep.RoomParams]: 'Raumkonfiguration',
  [RoomItemsStep.RoomItemsEditor]: 'Planung',
  [RoomItemsStep.RoomItemsViewer]: 'Möbel',
  [RoomItemsStep.RoomSummary]: 'Zusammenfassung',
};

export interface AvailableSteps {
  [step: string]: RoomConfiguratorStep;
}

/**
 * This class is basically a static storage for room configurator
 * rules, like, which steps and user-actions are available for the room
 */
export class RoomConfigRules {
  /**
   * Analyze current room status and returns possible(thou possibly disabled) steps
   * @param planMode
   */
  public static possibleRoomSteps(planMode?: RoomPlanMode | null): RoomConfiguratorStep[] {
    return planMode === RoomPlanMode.LetMePlan
      ? letMePlanSteps
      : planMode === RoomPlanMode.PlanForMe
      ? planForMeSteps
      : basicSteps; // Only planType selection is possible here
  }

  /**
   * Analyze room current status and returns available(not disabled) steps
   * These are common rules, they could be modified by particular component
   * @param room
   * @param isInvitedUser
   */
  public static availableRoomSteps(room: Room, isInvitedUser: boolean): AvailableSteps {
    const { roomStatus, items } = room;
    const result: { [step: string]: RoomConfiguratorStep } = {};

    switch (roomStatus) {
      case RoomStatus.New:
        if (!isInvitedUser) {
          // For not configured room, we allow only setting room params
          result[AllRoomSteps.RoomStyle.step] = AllRoomSteps.RoomStyle;
          result[AllRoomSteps.RoomParams.step] = AllRoomSteps.RoomParams;
        }
        break;
      case RoomStatus.Planned:
        if (!isInvitedUser) {
          // We allow this for not ordered rooms
          result[AllRoomSteps.RoomStyle.step] = AllRoomSteps.RoomStyle;
          result[AllRoomSteps.RoomParams.step] = AllRoomSteps.RoomParams;

          // For planed room we allow editor (if it possible, see possibleRoomSteps)
          result[AllRoomSteps.RoomItemsEditor.step] = AllRoomSteps.RoomItemsEditor;
        }

        if (items.length) {
          // If room has any items, we allow Editor2(Viewer) and summary
          result[AllRoomSteps.RoomItemsViewer.step] = AllRoomSteps.RoomItemsViewer;
          result[AllRoomSteps.RoomSummary.step] = AllRoomSteps.RoomSummary;
        }
        break;
      case RoomStatus.Ordered:
        // If room is ordered we allow only room summary
        result[AllRoomSteps.RoomItemsViewer.step] = AllRoomSteps.RoomItemsViewer;
        result[AllRoomSteps.RoomSummary.step] = AllRoomSteps.RoomSummary;
        break;
    }

    // Now, we gonna correct available room steps
    /* if (!room.planMode) {
      delete result[RoomParamsStep.RoomStyle];
      delete result[RoomParamsStep.RoomParams];
    } else if (!RoomConfigRules.isRoomStyled(room))  {
      delete result[RoomParamsStep.RoomParams];
    }*/

    return result;
  }

  /**
   * Convert steps to Header tabs
   * @param steps
   * @param availableSteps
   */
  public static stepsToTabs(
    steps: RoomConfiguratorStep[],
    availableSteps: AvailableSteps,
  ): ITabItem<RoomConfiguratorStep>[] {
    return steps.map(step => ({
      value: step,
      name: stepNames[step.step],
      disabled: !availableSteps[step.step],
    }));
  }

  protected static isRoomStyled(room: Room): boolean {
    const styleFields = ['roomDesignId', 'roomTypeId'];
    // Room is not styles if any of style fields is empty
    return !styleFields.find(styleField => !room[styleField]);
  }
}

import React, { forwardRef } from 'react';
import { Root } from './IconButton.styled';
import * as Icon from '../../icons';

interface IIconButtonProps {
  icon: 'Settings' | 'Help' | 'User' | 'Menu' | 'Back' | string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  disabled?: boolean;
}

export const IconButton = forwardRef<HTMLButtonElement, IIconButtonProps>(
  ({ icon, onClick, className, disabled }, ref) => {
    const IconComponent = Icon[`${icon}Icon`];
    return (
      <Root onClick={onClick} className={`${icon} ${className}`} data-hook-button="Menu" disabled={disabled} ref={ref}>
        <IconComponent />
      </Root>
    );
  },
);

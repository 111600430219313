import React from 'react';

import { useOutsideClickHandler } from '../../hooks/useClickOutside';

import { ChevronDownIcon } from '../../icons';
import { StyledSelect, SelectButton, Icon, Items, Item } from './Select.styled';
import { Error, Field } from '../Input/Input.styled';

export interface ISelectOption {
  value: string;
  label: string;
}

export interface ISelectProps {
  options: ISelectOption[];
  value: string | null;
  onChange: (value: string | null) => unknown;
  error?: string;
  className?: string;
  defaultLabel?: string;
}

export const Select: React.FC<ISelectProps> = ({ options, value, onChange, error, className = '', defaultLabel }) => {
  const ref = React.createRef<HTMLDivElement>();

  const [opened, setOpened] = React.useState<boolean>(false);

  const toggle = React.useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  const close = React.useCallback(() => {
    setOpened(false);
  }, []);

  useOutsideClickHandler(ref, () => close());

  const renderItems = () =>
    options.map(option => {
      const isSelected = option.value === value;

      const handleSelectItem = () => {
        onChange(option.value);
        close();
      };

      return (
        <Item key={option.value} selected={isSelected} onClick={handleSelectItem}>
          {option.label}
        </Item>
      );
    });

  const currentOption: ISelectOption | undefined = options.find(option => option.value === value);

  const isValid = !Boolean(error);

  return (
    <StyledSelect ref={ref} className={className}>
      <SelectButton isValid={isValid} onClick={toggle}>
        {(currentOption && currentOption.label) || defaultLabel}
        <Icon opened={opened}>
          <ChevronDownIcon />
        </Icon>
      </SelectButton>
      {!isValid && <Error>{error}</Error>}

      {opened && <Items>{renderItems()}</Items>}
    </StyledSelect>
  );
};

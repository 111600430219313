import React, { useEffect, useState } from 'react';

import { ProductConfiguration } from '../../../../../../../lib/core/api/generated';

import { ProductCard } from '../../../../../../../lib/components/ProductCard';
import { SideSheet } from '../../../../../../../lib/components/SideSheet';
import { ProductDetails } from '../../../../ProductDetails';

import { useGetProductSet } from '../../../../../../../lib/core/repositories/room-repository';

import { Typography } from '../../../../../../../lib/themes/typography/Typography';
import { ProductsWrapper } from './ProductSet.styled';

interface IProductSetProps {
  title: string;
  productSetId: string;
  amount: number;
  onSelect: (productConfiguration: ProductConfiguration, amount: number) => unknown;
}

interface IProduct {
  slotId: number;
  productConfiguration: ProductConfiguration;
  productConfigurations: ProductConfiguration[];
  amount: number;
}
export const ProductSet: React.FC<IProductSetProps> = ({ title, amount, productSetId, onSelect }) => {
  const [productToDetail, setProductToDetail] = useState<IProduct | null>(null);

  const [productSet, loadState] = useGetProductSet({ id: productSetId });

  // map of slots
  const [products, setProducts] = useState<{ [slotId: number]: IProduct }>({});

  useEffect(() => {
    if (productSet) {
      setProducts(
        productSet.productConfigurations.reduce((acc, productConfiguration, index) => {
          const slotId = index;
          acc[slotId] = {
            slotId,
            productConfiguration,
            productConfigurations: productConfiguration.configurableProduct.productConfigurations,
            amount,
          };
          return acc;
        }, {}),
      );
    }
  }, [amount, productSet]);

  const handleOpenDetails = (product: IProduct) => () => {
    setProductToDetail(product);
  };

  const handleOnCloseProductDetail = () => {
    setProductToDetail(null);
  };

  const handleClickToggleInListButton = ({ productConfiguration, productConfigurations, amount }: IProduct) => () => {
    onSelect(
      {
        ...productConfiguration,
        configurableProduct: {
          ...productConfiguration.configurableProduct,
          productConfigurations,
        },
      },
      amount,
    );
  };

  const handleOnProductDetailDone = (product: IProduct) => (
    productConfiguration: ProductConfiguration,
    amount: number,
  ): Promise<unknown> => {
    setProducts({
      ...products,
      [product.slotId]: {
        ...products[product.slotId],
        productConfiguration,
        amount,
      },
    });
    // Close the SideSheet with ProductToDetail
    setProductToDetail(null);
    return Promise.resolve();
  };

  const handleChangeAmount = (product: IProduct) => (amount: number) => {
    setProducts({
      ...products,
      [product.slotId]: {
        ...products[product.slotId],
        amount,
      },
    });
  };

  if (loadState.isPending()) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Typography
        tag="h1"
        variant="h1"
        css={`
          padding-left: 45px;
        `}
      >
        {title}
      </Typography>

      <ProductsWrapper>
        {Object.keys(products).map(key => {
          const product = products[key];
          return (
            <ProductCard
              productConfiguration={product.productConfiguration}
              amount={product.amount}
              isInList={false}
              isEditable={true}
              onChangeAmount={handleChangeAmount(product)}
              onClickDetailsButton={handleOpenDetails(product)}
              onClickToggleInListButton={handleClickToggleInListButton(product)}
              key={product.productConfiguration.sku}
            />
          );
        })}
      </ProductsWrapper>

      <SideSheet isOpened={!!productToDetail} onClose={handleOnCloseProductDetail}>
        {productToDetail && (
          <ProductDetails
            productConfiguration={productToDetail.productConfiguration}
            productConfigurations={productToDetail.productConfigurations}
            productSetId={null}
            isEditable={true}
            amount={productToDetail.amount}
            onDone={handleOnProductDetailDone(productToDetail)}
          />
        )}
      </SideSheet>
    </>
  );
};

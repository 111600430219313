import React from 'react';

import { formatCurrency } from '../../../../lib/utils/format-number';

import { Modal } from '../../../../lib/components/Modal';

import { StyledPaymentAnimation, Animation, TotalPrice } from './PaymentAnimation.styled';

interface IPaymentAnimationProps {
  total: number;
  isOpened: boolean;
}

export const PaymentAnimation: React.FC<IPaymentAnimationProps> = ({ total, isOpened }) => {
  return (
    <Modal title="Zahlung Akzeptiert" isOpened={isOpened} closable={false}>
      <StyledPaymentAnimation>
        <Animation>
          <img src="/img/mocks/payment/atm.gif" alt="" />
        </Animation>
        <TotalPrice>
          Summe
          <br />
          {formatCurrency(total)}
        </TotalPrice>
      </StyledPaymentAnimation>
    </Modal>
  );
};

import * as React from 'react';
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';

import {
  Form,
  FormFieldRow,
  FormButton,
  FormInput,
  FormCheckbox,
  FormFooter,
  FormLayout,
} from '../../../lib/components/Form';
import { useSignIn } from '../../../lib/core/repositories/auth-repository';
import { MutationLoginArgs } from '../../../lib/core/api/generated';
import { useCommonStorageValue } from '../../../lib/core/common-store-settings';
import { GqlBadCredentialsError } from '../../../lib/core/errors/gql-error';
import { Typography } from '../../../lib/themes/typography/Typography';
import { requiredString } from '../../../lib/utils/yup-reductions';

interface ISignInFormProps {
  onClickResetPassword?: () => unknown;
  onClickRegister?: () => unknown;
  afterSubmit?: () => unknown;
}

export const SignInForm: React.FC<ISignInFormProps> = ({
  onClickResetPassword,
  onClickRegister,
  afterSubmit,
}: ISignInFormProps) => {
  const [submitSignIn] = useSignIn();
  const [lastUsername, setLastUsername] = useCommonStorageValue('lastUsername'); // Set by AuthRepository
  const initialValues = React.useMemo(() => ({ username: lastUsername, password: '' }), [lastUsername]);

  // On successful sign-in we remember lastUsername to use the next time
  // On error from backend we clear-up password field without validation
  const onSubmit = React.useCallback(
    (values: MutationLoginArgs, formHelpers: FormikHelpers<MutationLoginArgs>) => {
      const submitResult = submitSignIn(values);
      return submitResult.catch(error => {
        formHelpers.setFieldValue('password', '', false);
        throw error;
      });
    },
    [submitSignIn],
  );

  return (
    <Form
      onSubmit={onSubmit}
      afterSubmit={afterSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      errorsHR={[
        [
          GqlBadCredentialsError,
          'Falscher Benutzername oder falsches Passwort eingegeben. Bitte versuchen Sie es erneut.',
        ],
      ]}
    >
      <FormLayout>
        <FormInput type="text" name="username" autoFocusOnEmpty={true} label="E-Mail" />
        <FormInput type="password" name="password" autoFocusOnEmpty={true} label="Passwort" />
        <FormFieldRow>
          <FormCheckbox name="remember">
            <Typography tag="p" variant="h5" color="bronze">
              Login speichern
            </Typography>
          </FormCheckbox>

          <FormButton
            type="button"
            size="small"
            variant="text"
            color="bronze"
            isIgnoreValidation={true}
            onClick={onClickResetPassword}
          >
            Passwort vergessen
          </FormButton>
        </FormFieldRow>
        <FormFooter>
          <FormButton
            type="button"
            variant="outlined"
            color="bronze"
            isIgnoreValidation={true}
            onClick={onClickRegister}
          >
            Anmelden
          </FormButton>
          <FormButton type="submit" variant="contained" color="bronze">
            Einloggen
          </FormButton>
        </FormFooter>
      </FormLayout>
    </Form>
  );
};

const validationSchema = Yup.object().shape<MutationLoginArgs>({
  username: requiredString('Bitte geben Sie Ihren Benutzernamen ein'),
  password: requiredString('Bitte geben Sie Ihr Passwort ein'),
});

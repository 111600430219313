import graphqlTag from 'graphql-tag';

export const gqlDocsAuthRegister = {
  login: graphqlTag`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      idToken
      accessToken
      refreshToken
    }
  }
`,
  /* This is special case, check ApolloStore.customFetch */
  refresh: `
  mutation refresh($refreshToken: String!) {
    refresh(refreshToken: $refreshToken) {
      idToken
      accessToken
      refreshToken
    }
  }
  `,

  logout: graphqlTag`
  mutation logout($refreshToken: String!) {
    logout(refreshToken: $refreshToken)
  }
  `,

  register: graphqlTag`
  mutation register($username: String, $email: String!, $password: String!, $firstName: String!, $lastName: String!, $accountName: String, $locale: String) {
    register(username: $username, email: $email, password: $password, firstName: $firstName, lastName: $lastName, accountName: $accountName, locale: $locale ) {
      username
      email
      firstName
      lastName
      id
      locale
      emailConfirmStatus
    }
  }
  `,

  registerInvitedUser: graphqlTag`
  mutation register($firstName: String!, $lastName: String!, $password: String!, $inviteToken: String) {
    register( password: $password, firstName: $firstName, lastName: $lastName, inviteToken: $inviteToken ) {
      id
      username
      email
      memberships {
        account {
          id
          name
        }
        role
      }
    }
  }
  `,

  confirmEmail: graphqlTag`
  mutation confirmMail($token: String!) {
    confirmMail(token: $token) {
      status
    }
  }
  `,

  resetPassword: graphqlTag`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email)
  }
  `,

  completePasswordReset: graphqlTag`
  mutation completePasswordReset($token: String!, $password: String!) {
    completePasswordReset(token: $token, password: $password)
  }
  `,
};

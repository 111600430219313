export const drop = <List>(count: number, list: List[]): List[] => {
  return [...list.slice(count, list.length)];
};

export const dropLast = <List>(count: number, list: List[]): List[] => {
  return drop(count, list.reverse()).reverse();
};

export const range = (from: number, to: number): number[] => {
  const result: number[] = [];
  let n: number = from;
  while (n < to) {
    result.push(n);
    n += 1;
  }
  return result;
};

export const getJsonFromLocalStorage = key => {
  try {
    if (typeof window !== 'undefined') {
      if (window.localStorage) {
        // @ts-ignore
        return JSON.parse(window.localStorage.getItem(key));
      }
    }
    return {};
  } catch (e) {
    return {};
  }
};

export const saveJsonToLocalStorage = (key, json) => {
  try {
    if (typeof window !== 'undefined') {
      if (window.localStorage) {
        window.localStorage.setItem(key, JSON.stringify(json));
      }
    }
  } catch (e) {}
};

export const keys = (object: object) => Object.keys(object);

export const leadingZeros = (size: number) => (str: string | number): string => {
  let tempString = String(str);

  while (tempString.length < size) {
    tempString = `0${tempString}`;
  }

  return tempString;
};

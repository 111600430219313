import { colors, styled } from '../../../../lib/themes';
import { Scrollable } from '../../../../lib/components';

export const Content = styled(Scrollable)`
  position: absolute;
  z-index: 2;
  bottom: 60px;
  left: 60px;
  padding: 27px 30px 30px 38px;
  width: 580px;
  min-height: 255px;
  max-height: calc(100% - 60px);
  background-color: ${colors.grayLight};
  border-radius: 10px;
`;

export const ContentInner = styled.div`
  display: flex;
`;

export const Number = styled.h3`
  margin-top: 6px;
  margin-right: 36px;
  font-size: 44px;
  font-weight: bold;
  color: ${colors.grayDark};
`;

export const Info = styled.div``;

export const InfoTitle = styled.h4`
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 400;
  color: ${colors.black};
`;

export const InfoDescription = styled.p`
  font-size: 20px;
  line-height: 1.5;
  color: ${colors.black};
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  right: 60px;
  width: 580px;
  height: 295px;
`;

import React, { useCallback, useMemo, useState } from 'react';

import { ITotalSumItem } from '../ProductTotal/ProductTotal.types';
import { formatCurrency } from '../../../../lib/utils/format-number';
import { Room } from '../../../../lib/core/api/generated';
import { Form, FormButton, FormInput, FormLayout } from '../../../../lib/components/Form';
import { useSnackBar } from '../../../../lib/components/SnackBar/useSnackbar';
import { useIsInvitedUser } from '../../../../lib/core/hooks/useIsInvitedUser';
import { useSaveRoom } from '../../../../lib/core/repositories/room-repository';
import { GqlErrors } from '../../../../lib/core/errors/gql-error';

import { Root, Table, TableBody, TableCell, TableRow, TotalSum, PromoCodeView } from './TotalPanel.styled';

interface TotalSumViewProps {
  room: Room;
  isOrdered?: boolean;
}

export const TotalPanel: React.FC<TotalSumViewProps> = ({ room, isOrdered }) => {
  const total = room.total ?? {
    originalTotalPrice: 0,
    originalTotalPriceInclTax: 0,
    discountAmount: 0,
    taxAmount: 0,
    totalPrice: 0,
    totalPriceInclTax: 0,
  };

  const [isShowButton, setIsShowButton] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [isCouponCodeApplied, setIsCouponCodeApplied] = useState<boolean>(false);
  const [saveRoom] = useSaveRoom();
  const isInvitedUser = useIsInvitedUser();
  const { showAlert, showSuccess } = useSnackBar();

  const totalSumItems: ({ show?: boolean } & ITotalSumItem)[] = useMemo(
    () => [
      {
        title: 'Warenkosten',
        sum: formatCurrency(total.originalTotalPrice),
      },
      {
        title: 'Rabatt',
        sum: formatCurrency(total.discountAmount),
        show: Boolean(total.discountAmount),
      },
      {
        title: '19% MwSt.',
        sum: formatCurrency(total.taxAmount),
      },
      {
        title: 'Gesamt',
        sum: formatCurrency(total.totalPriceInclTax),
      },
    ],
    [total],
  );

  const handleOnChange = useCallback(({ couponCode }) => {
    setIsShowButton(couponCode.trim().length > 0);
  }, []);

  const handleSubmit = useCallback(
    ({ couponCode }) => {
      setCouponCode(couponCode);
      return saveRoom({
        roomId: room.id,
        couponCode,
      }).then(() => {
        showSuccess('Anzeigencode angewendet');
        setIsCouponCodeApplied(true);
      });
    },
    [room.id, saveRoom, showSuccess],
  );

  const handleOnError = useCallback(
    hr => {
      showAlert(hr);
    },
    [showAlert],
  );

  const renderTotalSumItems = (item: { show?: boolean } & ITotalSumItem, key: number) => {
    const { title: totalSumItemTitle, sum, show = true } = item;
    if (show) {
      return (
        <TableRow key={key}>
          <TableCell>{totalSumItemTitle}</TableCell>
          <TableCell>{sum}</TableCell>
        </TableRow>
      );
    }
    return null;
  };

  return (
    <Root>
      {total.originalTotalPriceInclTax > 0 && (
        <TotalSum>
          <Table>
            <TableBody>{totalSumItems.map(renderTotalSumItems)}</TableBody>
          </Table>
        </TotalSum>
      )}

      {!isInvitedUser && !isOrdered && (
        <>
          {isCouponCodeApplied ? (
            <PromoCodeView>Gutschein - {couponCode}</PromoCodeView>
          ) : (
            <Form
              onSubmit={handleSubmit}
              initialValues={{ couponCode }}
              onError={handleOnError}
              onChange={handleOnChange}
              errorsHR={[[GqlErrors.VALIDATION_ERROR, 'Aktionscode ist ungültig']]}
            >
              <FormLayout>
                <FormInput name="couponCode" placeholder="Actionscode" />
                {isShowButton && (
                  <FormButton type="submit" variant="outlined" color="bronze">
                    Anwenden
                  </FormButton>
                )}
              </FormLayout>
            </Form>
          )}
        </>
      )}
    </Root>
  );
};

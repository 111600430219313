import React from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import I18n from 'i18n-js';

import { IGAOptions } from './iga-options';

export class Link extends React.Component<LinkProps & IGAOptions, any> {
  render() {
    const currentLang = I18n.currentLocale();
    const defaultLang = I18n.defaultLocale;
    const to = this.props.to as string;
    const prefix = currentLang === defaultLang ? '' : `/${currentLang}`;

    const gaOptions = {
      'data-ga-category': this.props['data-ga-category'],
      'data-ga-action': this.props['data-ga-action'],
      'data-ga-label': this.props['data-ga-label'],
      'data-ga-value': this.props['data-ga-value'],
    };

    return /^https?:\/\//.test(to) ? (
      <a
        href={to}
        className={this.props.className}
        style={this.props.style}
        onClick={this.props.onClick}
        target="_blank"
        rel="nofollow noopener noreferrer"
        {...gaOptions}
      >
        {this.props.children}
      </a>
    ) : (
      <RouterLink
        className={this.props.className}
        to={`${prefix}${to}`}
        style={this.props.style}
        onClick={this.props.onClick}
        data-hook-tab="ItemsMenu"
        {...gaOptions}
      >
        {this.props.children}
      </RouterLink>
    );
  }
}

import React, { useCallback } from 'react';

import { ImageCardsScreen } from '../containers/ImageCardScreen';
import { ImageCardDefault } from '../containers/ImageCardScreen/components/ImageCardDefault';
import { floorTypes } from '../../../../fixtures';

interface FloorTypesScreenProps {
  onChange: () => void;
  onBack: () => void;
}

export const FloorTypesScreen: React.FC<FloorTypesScreenProps> = ({ onChange, onBack }) => {
  const imageCardsGenerator = useCallback(
    () =>
      floorTypes.map((card, index) => {
        const { id, cover, name } = card;

        return {
          value: id,
          // eslint-disable-next-line react/display-name
          component: ({ onClick, isActive }) => (
            <ImageCardDefault name={name} cover={cover} onClick={onClick} isActive={isActive} />
          ),
        };
      }),
    [],
  );

  return (
    <ImageCardsScreen
      title="2.1 Für welchen Boden richten Sie ein?"
      fieldName="floorTypeId"
      imageCardsGenerator={imageCardsGenerator}
      onChange={onChange}
      onBack={onBack}
    />
  );
};

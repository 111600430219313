import { useState, useCallback, useEffect } from 'react';

interface Sizes {
  left: number;
  top: number;
  width: number;
  height: number;
  wasCalculated: boolean;
}

function getSize(el?: HTMLElement): Sizes {
  if (!el) {
    return {
      width: 0,
      height: 0,
      left: 0,
      top: 0,
      wasCalculated: false,
    };
  }

  const metrics = el.getBoundingClientRect();

  return {
    left: metrics.left,
    top: metrics.top,
    width: metrics.width,
    height: metrics.height,
    wasCalculated: true,
  };
}

export function useBoxSize<T>(): [Sizes, (ref: HTMLElement) => unknown] {
  const [ComponentSize, setComponentSize] = useState(getSize());
  const [ref, setRef] = useState<HTMLElement>();

  const updateRef = useCallback(node => {
    setRef(node);
  }, []);

  const updatePosition = useCallback(() => {
    setComponentSize(getSize(ref));
  }, [ref]);

  useEffect(() => {
    if (!ref) {
      return;
    }

    updatePosition();

    let resizeObserver;

    // @ts-ignore
    if (typeof ResizeObserver === 'function') {
      // @ts-ignore
      resizeObserver = new ResizeObserver(updatePosition);

      resizeObserver.observe(ref);
    }

    ref.addEventListener('click', updatePosition);
    window.addEventListener('resize', updatePosition);
    window.addEventListener('scroll', updatePosition);
    return () => {
      ref.removeEventListener('click', updatePosition);
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', updatePosition);
      // @ts-ignore
      if (typeof ResizeObserver === 'function') {
        resizeObserver.disconnect(ref);
        resizeObserver = null;
      }
    };
  }, [ref, updatePosition]);

  return [ComponentSize, updateRef];
}

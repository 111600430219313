import { useCallback, useContext } from 'react';
import { SnackbarContext } from './SnackbarProvider';
import { SnackBarState, SnackBarType } from './types';

export const useSnackBar = () => {
  const SnackBar = useContext<SnackBarState>(SnackbarContext);

  const showAlert = useCallback(
    (message: string) => {
      SnackBar.add(message, { autoDismissTime: 4000, autoDismiss: true, closeButton: true, type: SnackBarType.alert });
    },
    [SnackBar],
  );

  const showMessage = useCallback(
    (message: string) => {
      SnackBar.add(message, {
        autoDismissTime: 4000,
        autoDismiss: true,
        closeButton: true,
        type: SnackBarType.message,
      });
    },
    [SnackBar],
  );

  const showWarning = useCallback(
    (message: string) => {
      SnackBar.add(message, {
        autoDismissTime: 4000,
        autoDismiss: true,
        closeButton: true,
        type: SnackBarType.warning,
      });
    },
    [SnackBar],
  );

  const showSuccess = useCallback(
    (message: string) => {
      SnackBar.add(message, {
        autoDismissTime: 4000,
        autoDismiss: true,
        closeButton: true,
        type: SnackBarType.success,
      });
    },
    [SnackBar],
  );

  return {
    toasts: SnackBar.toasts,
    addToast: SnackBar.add,
    removeToast: SnackBar.remove,
    removeAllToast: SnackBar.removeAll,
    id: SnackBar.idCounter,
    showAlert,
    showSuccess,
    showWarning,
  };
};

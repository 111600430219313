import React from 'react';

import { Button, Modal } from '../../components';
import { Content, Buttons, Message } from './ConfirmationModal.styled';
import { noop } from '../../utils/placeholders';

export interface IConfirmationModalProps {
  isOpened: boolean;
  message?: string;
  component?: React.FC;
  title?: string;
  onAgree?: () => unknown;
  onCancel?: () => unknown;
  onDetach?: () => unknown;
  className?: string;
}

export const ConfirmationModal: React.FC<IConfirmationModalProps> = props => {
  const {
    isOpened,
    message,
    title = 'Sind Sie sicher?',
    onAgree = noop,
    onCancel = noop,
    onDetach = noop,
    component: Component,
    className,
  } = props;

  return (
    <Modal title={title} isOpened={isOpened} closable={false} onDetach={onDetach} className={className}>
      <Content>
        {Component ? <Component /> : <Message>{message}</Message>}
        <Buttons>
          <Button variant="outlined" size="medium" onClick={onCancel}>
            Nein
          </Button>
          <Button variant="contained" size="medium" color="bronze" onClick={onAgree}>
            Ja
          </Button>
        </Buttons>
      </Content>
    </Modal>
  );
};

import * as styledComponents from 'styled-components';

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<Theme>;

export const theme = {
  primaryColor: '#e9e9eb',
};

export type Theme = typeof theme;

/**
 * Returns css styles if all predicates return `true`,
 * otherwise returns an empty string;
 *
 * Example:
 *   ${({ color }) => applyCSS(isRed(color))(css`
 *     color: red;
 *   `)};
 *
 * @param predicates — predicates array
 */
export const applyCssIf = (...predicates: boolean[]) => (styles: styledComponents.FlattenSimpleInterpolation) =>
  predicates.reduce((p, c) => p && c, true) ? styles : '';

export { styled, css, createGlobalStyle, keyframes, ThemeProvider };
export { colors } from './colors';
export * from './typography';

import React, { useCallback } from 'react';

import { ImageCardsScreen } from '../containers/ImageCardScreen';
import { ImageCardDefault } from '../containers/ImageCardScreen/components/ImageCardDefault';
import { roomTypes } from '../../../../fixtures';

// добаивть интерфейс

export const RoomTypeScreen: React.FC<any> = ({ onChange }) => {
  const imageCardsGenerator = useCallback(
    () =>
      roomTypes.map(({ id, cover, name, isComingSoon }) => {
        return {
          value: id,
          // eslint-disable-next-line react/display-name
          component: ({ onClick, isActive }) => (
            <ImageCardDefault
              name={name}
              cover={cover}
              onClick={onClick}
              isComingSoon={isComingSoon}
              isActive={isActive}
            />
          ),
        };
      }),
    [],
  );

  return (
    <ImageCardsScreen
      title="1.1 Was für einen Raum möchten Sie einrichten?"
      fieldName={'roomTypeId'}
      imageCardsGenerator={imageCardsGenerator}
      onChange={onChange}
    />
  );
};

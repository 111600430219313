import React from 'react';

import {
  StyledTipVonMaria,
  Header,
  HeaderAvatar,
  HeaderTitle,
  Content,
  ContentTitle,
  ContentText,
  ContentImg01,
  ContentImg02,
  ContentImg03,
  BottomBlock,
  TextBlock1,
  TextBlock2,
} from './TipVonMaria.styled';

import mariaPng from '../../../../static/img/mocks/plan-for-me/maria.png';
import tipVonMaria01Png from '../../../../static/img/mocks/plan-for-me/tip-von-maria-01.png';
import tipVonMaria02Png from '../../../../static/img/mocks/plan-for-me/tip-von-maria-02.png';
import tipVonMaria03Png from '../../../../static/img/mocks/plan-for-me/tip-von-maria-03.png';

export const TipVonMaria: React.FC = () => (
  <StyledTipVonMaria>
    <Header>
      <HeaderAvatar>
        <img src={mariaPng} alt="" />
      </HeaderAvatar>
      <HeaderTitle>Tipp von Maria</HeaderTitle>
    </Header>
    <Content>
      <ContentImg01 src={tipVonMaria01Png} />

      <TextBlock1>
        <ContentTitle>Sparen Sie Platz</ContentTitle>
        <ContentText>
          Der Memoryschalter (inklusive Display) kann bis zu 4 Tischhöhen abspeichern. Danach reicht ein Antippen auf
          die jeweilige Speicherposition und der Tisch fährt in die zuvor hinterlegte Arbeitshöhe.
        </ContentText>
      </TextBlock1>

      <ContentImg02 src={tipVonMaria02Png} />

      <BottomBlock>
        <TextBlock2>
          <ContentTitle>Sparen Sie Platz</ContentTitle>
          <ContentText>
            Der Memoryschalter (inklusive Display) kann bis zu 4 Tischhöhen abspeichern. Danach reicht ein Antippen auf
            die jeweilige Speicherposition und der Tisch fährt in die zuvor hinterlegte Arbeitshöhe.
          </ContentText>
        </TextBlock2>
        <ContentImg03 src={tipVonMaria03Png} />
      </BottomBlock>
    </Content>
    {/* <Header>
      <HeaderText>
        Der Memoryschalter (inklusive Display) kann bis zu 4 Tischhöhen abspeichern. Danach reicht ein Antippen auf die
        jeweilige Speicherposition und der Tisch fährt in die zuvor hinterlegte Arbeitshöhe.{' '}
      </HeaderText>
      <HeaderImages>
        <img src={image1} alt="" />
        <img src={image2} alt="" />
      </HeaderImages>
    </Header>
    <img src={image3} alt="" />
    <img src={image4} alt="" /> */}
  </StyledTipVonMaria>
);

import React, { RefObject } from 'react';

import { StyledButton } from './Button.styled';
import { ICommonButtonProps } from './Button.types';
import { Link } from '../../router/link';

// replace 'href' with 'to'
export interface IButtonProps extends ICommonButtonProps {
  /**
   * The URL to link to when the button is clicked.
   * If defined, `a` tag will be used instead of 'button'.
   */
  ref?: RefObject<any>;
  href?: string;
  target?: string;
  rel?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  className?: string;
  isLoading?: boolean;
  iconStart?: any;
  iconEnd?: any;
}

export const Button: React.FC<IButtonProps> = React.forwardRef((props, ref) => {
  const {
    type = 'button',
    variant = 'outlined',
    size = 'large',
    color = 'default',
    disabled = false,
    onClick,
    href,
    children,
    className,
    isLoading = false,
    iconStart,
    iconEnd,
  } = props;

  return href ? (
    <StyledButton
      ref={ref}
      type={type}
      as={Link}
      to={href}
      variant={variant}
      size={size}
      color={color}
      // target={target}
      // rel={rel}
      disabled={disabled || isLoading}
      isLoading={isLoading}
      onClick={onClick}
      className={className}
      data-hook-button={children}
    >
      {iconStart}
      <span>{children}</span>
      {iconEnd}
    </StyledButton>
  ) : (
    <StyledButton
      ref={ref}
      type={type}
      variant={variant}
      size={size}
      color={color}
      disabled={disabled || isLoading}
      data-hook-button={children}
      isLoading={isLoading}
      onClick={onClick}
      className={className}
    >
      {iconStart}
      <span>{children}</span>
      {iconEnd}
    </StyledButton>
  );
});

import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { getMainContext } from './main-context';
import { AvailableStores } from '../main-store';
import { DoneState, ErrorState, LoadState, NoneState, PendingState } from '../load-state';
import { ApiError } from '../errors/api-error';
import { ObservableQuery } from 'apollo-client/core/ObservableQuery';
import { ApolloQueryResult } from 'apollo-boost';
import { ApiRepository } from '../repositories/api-repository';
import { Query } from '../api/generated';
import { useDeepMemo } from './deepMemo';

export type BaseQueryResult<TResult> = [TResult, DoneState] | [TResult | null, NoneState | ErrorState | PendingState];

export type UseMutationResult<TVariables, TResult> =
  | [(variables: TVariables) => Promise<TResult>, TResult, DoneState]
  | [(variables: TVariables) => Promise<TResult>, TResult | null, NoneState | ErrorState | PendingState];

export type UseQueryResult<TVariables, TResult> =
  | [TResult, DoneState, (variables: TVariables) => Promise<TResult>]
  | [TResult | null, NoneState | ErrorState | PendingState, (variables: TVariables) => Promise<TResult>];

/**
 * Internal function to obtain particular store
 * @inner
 */
export function useStore<T extends AvailableStores, P extends keyof T = keyof T>(storeName: P): T[P] {
  const mainContext = useContext(getMainContext<T>());
  return mainContext.mainStore.get(storeName);
}

/**
 * Internal helper
 * @return {UseQueryResult<TVariables, TResult>}
 * @param mutationFn
 */
export function useMutation<TVariables, TResult>(
  mutationFn: (variables: TVariables) => Promise<TResult>,
): UseMutationResult<TVariables, TResult> {
  const [state, setState] = useState<{ result: TResult | null; loadState: LoadState }>({
    result: null,
    loadState: LoadState.none(),
  });

  const cancellableSetState = useRef(setState);

  useEffect(() => {
    return () => {
      // basically we cancel request here
      cancellableSetState.current = function NOP() {};
    };
  }, []);

  const runRequest = useCallback(
    (variables: TVariables): Promise<TResult> => {
      setState(state => ({ ...state, loadState: LoadState.pending() }));
      return mutationFn(variables)
        .then((result: TResult) => {
          cancellableSetState.current({ result, loadState: LoadState.done() });
          return result;
        })
        .catch((error: ApiError) => {
          cancellableSetState.current(state => ({
            ...state,
            loadState: new ErrorState(error),
          }));
          throw error;
        });
    },
    [mutationFn, cancellableSetState.current],
  );

  return [runRequest, state.result, state.loadState];
}

/**
 * internal helper
 * @param {(variables: TVariables) => Promise<TResult>} queryFn
 * @param variables
 * @return {UseMutationResult<TVariables, TResult>}
 */
export function useQuery<TVariables, TResult>(
  queryFn: (variables: TVariables) => Promise<TResult>,
  variables: TVariables,
): UseQueryResult<TVariables, TResult> {
  const [runRequest, result, loadState] = useMutation(queryFn);
  useDeepMemo(() => runRequest(variables), [queryFn, variables]);
  return [result, loadState, runRequest];
}

/**
 * Internal layer for watching queries
 * mimics regular useQuery, with only difference - it initiates re-render
 * if data changes in cache (apollo-cache)
 * @param watchQueryFn
 * @param resultPropertyName - name of property, contained main result of the query
 * @param variables
 */
export function useWatchQuery<TVariables, TResult>(
  watchQueryFn: (variables: TVariables) => ObservableQuery<TResult, TVariables>,
  resultPropertyName: keyof Query,
  variables: TVariables,
): UseQueryResult<TVariables, TResult> {
  let [state, setState] = useState<{ result: TResult | null; loadState: LoadState }>({
    result: null,
    loadState: LoadState.pending(),
  });

  let cancellableSetState = useRef(setState);

  // Create observable query once
  const observableQuery = useRef<ObservableQuery<TResult, TVariables>>();
  const subscription = useRef<any>();
  const refetch = useRef<(variables: TVariables) => Promise<TResult>>();

  // Little hack, cuz we need more complex logic with memo
  const stateProxy = useRef(state);
  stateProxy.current = state;

  // Prepare subscription cleanup
  useEffect(
    () =>
      function unsubscribe() {
        subscription.current && subscription.current.unsubscribe();
        cancellableSetState.current = function NOP() {};
      },
    [],
  );

  // if variables has changes, refetch the query
  useDeepMemo(() => {
    if (!observableQuery.current) {
      observableQuery.current = watchQueryFn(variables);
      subscription.current = observableQuery.current.subscribe({
        next: (result: ApolloQueryResult<TResult>) => {
          try {
            if (result.loading) {
              cancellableSetState.current({ ...stateProxy.current, loadState: LoadState.pending() });
            } else {
              const requestResult = ApiRepository.processQueryResultS(result, resultPropertyName);
              cancellableSetState.current({ result: requestResult, loadState: LoadState.done() });
            }
          } catch (error) {
            cancellableSetState.current({ ...stateProxy.current, loadState: LoadState.error(error) });
          }
        },
        error: error => {
          try {
            ApiRepository.processFetchError(error);
          } catch (e) {
            cancellableSetState.current({ ...stateProxy.current, loadState: LoadState.error(e) });
          }
        },
      });
    } else {
      observableQuery.current.refetch(variables);
    }

    // Manual re-fetch the query, we should not worry about errors and result here,
    // because observable.next will be called anyway and it will update the state of this hook
    refetch.current = (newVariables: TVariables) =>
      observableQuery
        .current!.refetch(newVariables)
        .then(result => ApiRepository.processQueryResultS(result, resultPropertyName));
  }, [variables]);

  return [state.result, state.loadState, refetch.current!];
}

import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { On } from '../../../../lib/core/hooks/on';
import { useMe, useWatchMe } from '../../../../lib/core/repositories/user-repository';
import { useDeleteProject } from '../../../../lib/core/repositories/project-repository';
import { AddProjectButton, CError, Modal, ProjectCard } from '../../../../lib/components';
import { NewProjectForm } from '../../components';
import { Loader } from '../../../../lib/components/Loader';
import { Project, RoomStatus } from '../../../../lib/core/api/generated';
import { stringHash } from '../../../../lib/utils/string-hash';
import { pluralizeRooms } from '../../../../lib/utils/pluralizeRooms';
import { formatCurrency } from '../../../../lib/utils/format-number';
import project1Jpg from './assets/project1.jpg';
import project2Jpg from './assets/project2.jpg';
import project3Jpg from './assets/project3.jpg';
import { UserRole } from '../../../../lib/core/hooks/useIsInvitedUser';
import { Typography } from '../../../../lib/themes/typography/Typography';
import { noop } from '../../../../lib/utils/placeholders';
import { IntroductionWindow } from '../../components/IntroductionWindow';

import { AddProjectButtonWrapper, Page, WelcomeBlock } from './ProjectPage.styled';

const projectCovers = [project1Jpg, project2Jpg, project3Jpg];

export const ProjectPage: React.FC = () => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [deleteProject] = useDeleteProject();
  const history = useHistory();

  const handleToggleDialog = useCallback(() => {
    setIsOpenDialog(!isOpenDialog);
  }, [isOpenDialog]);

  const handleAddProject = useCallback(
    (project: Project) => {
      handleToggleDialog();
      history.push(`/accounts/${project.accountId}/projects/${project.id}`);
    },
    [history, handleToggleDialog],
  );

  const handleDeleteProject = useCallback(
    (projectId: string) => () => {
      deleteProject({ projectId });
    },
    [deleteProject],
  );

  return On(
    user => {
      const projectCount = user.memberships.reduce((acc, membership) => acc + membership.account.projects.length, 0);
      return (
        <Page>
          {projectCount === 0 && (
            <WelcomeBlock>
              <span>
                Willkommen, {user.firstName}&nbsp;{user.lastName}.
                <br />
                <span>Lassen Sie uns Ihr erstes Projekt erstellen.</span>
              </span>
            </WelcomeBlock>
          )}
          {user.memberships
            .map(membership => (
              <div key={membership.account.id}>
                {membership.role === UserRole.Executive && (
                  <Typography
                    variant="h3"
                    tag="h1"
                    css={`
                      margin-bottom: 40px;
                    `}
                  >
                    {membership.account.name} // Projekt-Freigaben
                  </Typography>
                )}

                {membership.account.projects.map(project => {
                  const roomsCount = project.rooms.length;
                  const totalPrice = project.rooms.reduce((acc, room) => acc + (room.total?.totalPriceInclTax || 0), 0);
                  const hasOrderedRooms = project.rooms.some(room => room.roomStatus === RoomStatus.Ordered);

                  return (
                    <ProjectCard
                      image={projectCovers[stringHash(project.name) % 3]}
                      key={project.id}
                      subtitle={
                        roomsCount > 0
                          ? `${pluralizeRooms(roomsCount)}${
                              totalPrice > 0 ? ` / Budget ${formatCurrency(totalPrice)}` : ''
                            }`
                          : ''
                      }
                      to={`/accounts/${membership.account.id}/projects/${project.id}`}
                      title={project.name}
                      hasOrderedRooms={hasOrderedRooms}
                      onRemove={handleDeleteProject(project.id)}
                      onOrder={noop}
                    />
                  );
                })}

                {membership.role !== UserRole.Executive && (
                  <>
                    <AddProjectButtonWrapper>
                      <AddProjectButton onClick={handleToggleDialog} />
                    </AddProjectButtonWrapper>
                    <Modal title="Wie soll Ihr Projekt heißen?" onClose={handleToggleDialog} isOpened={isOpenDialog}>
                      <NewProjectForm afterCreateProject={handleAddProject} accountId={membership.account.id} />
                    </Modal>
                  </>
                )}
              </div>
            ))
            .reverse()}
          {projectCount === 0 && <IntroductionWindow />}
        </Page>
      );
    },
    () => <Loader />,
    error => <CError error={error} />,
    useWatchMe(),
  );
};

import gql from 'graphql-tag';

export const gqlDocsRoomPackages = {
  getRoomPackages: gql`
    query roomPackages {
      roomPackages {
        sku
        name
        price
        roomCount
      }
    }
  `,

  purchaseRoomPackage: gql`
    mutation purchaseRoomPackage($accountId: String!, $sku: String!, $method: PaymentMethod!) {
      purchaseRoomPackage(accountId: $accountId, sku: $sku, method: $method) {
        paypal {
          token
          editUrl
          startUrl
        }
      }
    }
  `,

  completePaypalRoomPackagePurchase: gql`
    mutation completePaypalRoomPackagePurchase($accountId: String!, $paypalToken: String!, $paypalPayerId: String!) {
      completePaypalRoomPackagePurchase(accountId: $accountId, paypalToken: $paypalToken, paypalPayerId: $paypalPayerId)
    }
  `,
};
